import { DATE_TIME_FORMAT, DEFAULT, WEEK_DAYS } from '@/constants/constant'
import moment from 'moment'

export class DateUtils {
  /**
   * Check datetime(YYYY-MM-DD HH:MM:SS) is whether between 2 time (22:00:00 and 06:00:00)
   *  from <= checktime < to
   *
   * @DateTime check
   * @DateTime from
   * @DateTime to
   */
  static isBetWeen(check, from, to) {
    const format = DATE_TIME_FORMAT.HHMMSS
    const time = moment(moment(check).format(format), format)
    const beforeTime = moment(moment(from).format(format), format)
    const afterTime = moment(moment(to).format(format), format)

    // before time and after time in same day (ex:  before: 22:00:00, after: 23:00:00)
    if (beforeTime.isBefore(afterTime)) {
      return time >= beforeTime && time < afterTime
    } else {
      // after time is tomorrow (ex before: 22:00:00, after: 05:00:00)
      return time >= beforeTime || time < afterTime
    }
  }

  /**
   * Current time divided by 15 minutes format
   */
  static formatHHMM15Min(){
    return 'HH:' + ('00' + Math.floor(moment().add(15, 'm').minutes() / 15) * 15).slice(-2)
  }

  /**
   * Round next 15 min
   *
   * @moment dateTime
   */
  static roundNext15Min(dateTime) {
    let intervals = Math.floor(dateTime.minutes() / 15)
    if (dateTime.minutes() % 15 !== 0) {
      intervals++
    }
    if (intervals === 4) {
      dateTime.add(1, 'hours')
      intervals = 0
    }
    dateTime.minutes(intervals * 15)
    dateTime.seconds(0)
    dateTime.millisecond(0)
    return dateTime
  }

  static localDateTimeToDayJP(timeValue) {
    const time = moment(timeValue).toDate()
    const year = time.getFullYear()
    const month = (time.getMonth() + 1).toString().padStart(2, '0')
    const day = time.getDate().toString().padStart(2, '0')
    const hour = time.getHours().toString().padStart(2, '0')
    const minute = time.getMinutes().toString().padStart(2, '0')
    const dayName = time.toLocaleString('ja', { weekday: 'long' }).substring(0, 1)
    return `${year}/${month}/${day} (${dayName}) ${hour}:${minute}`
  }

  static localDateTimeToDayJPForTimePicker(timeValue) {
    const time = moment(timeValue).toDate()
    const month = (time.getMonth() + 1).toString().padStart(2, '0')
    const day = time.getDate().toString().padStart(2, '0')
    const dayName = time.toLocaleString('ja', { weekday: 'long' }).substring(0, 1)
    return `${month}/${day} (${dayName})`
  }

  static localDateTimeToDayJPForReserveDisplay(timeValue) {
    const time = moment(timeValue).toDate()
    const year = time.getFullYear()
    const month = (time.getMonth() + 1).toString().padStart(2, '0')
    const day = time.getDate().toString().padStart(2, '0')
    const hour = time.getHours().toString().padStart(2, '0')
    const minute = time.getMinutes().toString().padStart(2, '0')
    const dayName = WEEK_DAYS[time.getDay()]
    return `${year}/${month}/${day} ${dayName} ${hour}:${minute}`
  }

  static getDurationBetweenTime(timeValueStart, timeValueEnd) {
    const timeStart = moment(timeValueStart)
    const timeEnd = moment(timeValueEnd)
    const hour = timeEnd.diff(timeStart, 'hour')
    const minute = timeEnd.diff(timeStart, 'minute') % 60
    return { hour: hour.toString().padStart(2, '0'), minute: minute.toString().padStart(2, '0') }
  }

  static getSelectYearList() {
    const listObYears = []
    let currentYear = new Date().getFullYear()
    const years = [currentYear.toString().substring(2, 4)]
    const endYear = currentYear + 9
    while (currentYear <= endYear) {
      currentYear++
      years.push(currentYear.toString().substring(2, 4))
    }
    years.unshift(DEFAULT.picker)

    for (let i = 0; i < years.length; i++) {
      if (i === 0) {
        listObYears.push({
          key: years[0],
          value: 0,
        })
      } else {
        listObYears.push({
          key: years[i],
          value: parseInt(years[i], 10),
        })
      }
    }
    return listObYears
  }

  static calculateMinMaxTimeStartTwoWeek() {
    const now = moment()
    const minStartTime = DateUtils.roundNext15Min(moment())
    const maxStartDate = now.add(14, 'days')
    maxStartDate.hours(23)
    maxStartDate.minutes(30)
    return {
      minStartTime: minStartTime.format('YYYY-MM-DD HH:mm').toString(),
      maxStartTime: maxStartDate.format('YYYY-MM-DD HH:mm').toString(),
    }
  }

  static calculateMinMaxTimeEnd(minStartDate, maxStartDate) {
    const startTime = moment(minStartDate)
    const minEndTime = startTime.add(15, 'minutes')
    maxStartDate = moment(maxStartDate).minutes(45)
    const startTimeAddDay = moment(minStartDate).add(3, 'days')
    const maxEndTime = startTimeAddDay <= maxStartDate ? startTimeAddDay : maxStartDate
    return {
      minEndTime: minEndTime.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss).toString(),
      maxEndTime: maxEndTime.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss).toString(),
    }
  }

  static calculateMinMaxTime() {
    const now = moment()
    const sMinStartTime = DateUtils.roundNext15Min(moment())
    const eMinEndTime = DateUtils.roundNext15Min(moment()).add(15, 'minutes')
    const sMaxStartDate = now.add(14, 'days')
    sMaxStartDate.hours(23)
    sMaxStartDate.minutes(30)

    const minStartTime = sMinStartTime
    const maxStartTime = sMaxStartDate

    const eMaxEndDate = moment(maxStartTime).minutes(45)
    const eStartTimeAddDay = moment(minStartTime).add(3, 'days')
    const eMaxEndTime = eStartTimeAddDay <= eMaxEndDate ? eStartTimeAddDay : eMaxEndDate
    return {
      minStartTime: sMinStartTime.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss).toString(),
      maxStartDate: sMaxStartDate.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss).toString(),
      minEndTime: eMinEndTime.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss).toString(),
      maxEndTime: eMaxEndTime.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss).toString(),
    }
  }
}
