<template>
  <div>
    <simple-layouts>
      <template v-slot:content>
        <div class="password errorpage">
          <form>
            <main class="cf password1">
              <section class="common_section reminder">
                <h2>セッションタイムアウト</h2>
                <div class="nobox">
                  <p class="body_text">
                    セッションがタイムアウトしました。もう一度ログインしてください。
                  </p>
                  <div class="btn_area">
                    <button type="button" class="btn_l btn_blue" @click.stop.prevent="$router.push('/login')">
                      ログイン画面へ戻る
                    </button>
                  </div>
                </div>
              </section>
            </main>
          </form>
        </div>
      </template>
    </simple-layouts>
  </div>
</template>

<script></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
