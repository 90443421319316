<template>
  <div>
    <loading :active="processing">
      <loading
        :active="processing"
        :loader="'dots'"
        :color="'#007ecb'"
        :can-cancel="true"
        :is-full-page="true"
      >
      </loading>
      <p class="loading-msg">
        <strong>返却手続き中…</strong>
      </p>
    </loading>
    <form>
      <main class="cf enquete_main">
        <section class="common_section enquete enquetearea">
          <h2>返却手続き</h2>
          <div class="box">
            <div class="box_wrap">
              <h3 v-if="!(ecocarType === 1 && usagePattern === 1)">
                運行日報への記録の為、以下の項目に入力をお願いします。
              </h3>

              <div class="nippou_wrap" v-if="!(ecocarType === 1 && usagePattern === 1)">
                <div
                  class="nippou_box"
                  v-if="onboardUnitInstalled === 0 && manufacturerApiAvailable === 0"
                  id="alightingTotalMileage"
                >
                  <div class="enquete_a_input">
                    <div class="enquete_input">
                      <span class="enquete_input_head">
                        <span class="hissu">必須</span>
                        積算走行距離（ODOメーター）を教えてください。
                      </span>
                      <span class="enquete_input_input"
                        ><span class="enquete_input_input_ttl"> 積算走行距離： </span
                        ><input
                          type="text"
                          id=""
                          name=""
                          v-model="questionnaire.alightingTotalMileage"
                          :maxlength="7"
                          @input="
                            (event) =>
                              (questionnaire.alightingTotalMileage = event.target.value.replace(
                                /[^0-9]/g,
                                ''
                              ))
                          "
                        />
                        <span class="enquete_input_unit">km</span></span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="nippou_box"
                  v-if="
                    onboardUnitInstalled === 0 && ecocarType === 1 && manufacturerApiAvailable === 0
                  "
                  id="alightingInputBatteryLevel"
                >
                  <div class="enquete_a_input">
                    <div class="enquete_input">
                      <span class="enquete_input_head"
                        ><span class="hissu">必須</span>バッテリー残量を教えてください。</span
                      >
                      <span class="enquete_input_input">
                        <span class="enquete_input_input_ttl">バッテリー残量：</span
                        ><input
                          type="text"
                          name=""
                          v-model="questionnaire.alightingInputBatteryLevel"
                          :maxlength="3"
                          @input="
                            (event) =>
                              (questionnaire.alightingInputBatteryLevel =
                                event.target.value.replace(/[^0-9]/g, ''))
                          "
                        />
                        <span class="enquete_input_unit">%</span></span
                      >
                    </div>
                  </div>
                </div>

                <div class="nippou_box" v-if="ecocarType === 2" id="alightingSelectedBatteryLevel">
                  <div class="enquete_a_input">
                    <div class="enquete_input">
                      <span class="enquete_input_head"
                        ><span class="hissu">必須</span>バッテリー残量を教えてください。</span
                      >
                      <div class="range">
                        <input
                          id="alighting_selected_battery_level"
                          type="range"
                          list="d1"
                          min="0"
                          max="100"
                          value="0"
                          step="25"
                          name="alightingSelectedBatteryLevel"
                          @change="changeAlightingSelectedBatteryLevel"
                        />
                        <datalist id="d1">
                          <option value="0" />
                          <option value="25" />
                          <option value="50" />
                          <option value="75" />
                          <option value="100" />
                        </datalist>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="nippou_box"
                  v-if="ecocarType === 2 || ecocarType === 3"
                  id="alightingFuelLevel"
                >
                  <div class="enquete_a_input">
                    <div class="enquete_input">
                      <span class="enquete_input_head"
                        ><span class="hissu">必須</span>燃料残量を教えてください。</span
                      >
                      <div class="range">
                        <input
                          id="alighting_fuelLevel"
                          type="range"
                          list="d2"
                          min="0"
                          max="100"
                          step="25"
                          value="0"
                          name="alightingFuelLevel"
                          @change="changeAlightingFuelLevel"
                        />
                        <datalist id="d2">
                          <option value="0" />
                          <option value="25" />
                          <option value="50" />
                          <option value="75" />
                          <option value="100" />
                        </datalist>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="nippou_box"
                  v-if="ecocarType === 1 && usagePattern === 2"
                  id="alightingCharged"
                >
                  <div class="enquete_a_input">
                    <div class="enquete_input">
                      <span class="enquete_input_head"
                        ><span class="hissu">必須</span>ご利用中に充電をしましたか？</span
                      >
                      <span class="enquete_input_input">
                        <div class="switch">
                          <input
                            type="radio"
                            name="s6"
                            id="on06"
                            :value="true"
                            v-model="questionnaire.alightingCharged"
                          />
                          <label for="on06" class="switch-on02">はい</label>
                          <input
                            type="radio"
                            name="s6"
                            id="off06"
                            :value="false"
                            checked
                            v-model="questionnaire.alightingCharged"
                          />
                          <label for="off06" class="switch-off02">いいえ</label>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="nippou_box" v-if="ecocarType === 3" id="alightingRefueled">
                  <div class="enquete_a_input">
                    <div class="enquete_input flex-start">
                      <span class="enquete_input_head"
                        ><span class="hissu">必須</span>ご利用中に給油をしましたか？</span
                      >
                      <div class="switchbox">
                        <div class="switch">
                          <input
                            type="radio"
                            name="s7"
                            id="on07"
                            :value="true"
                            v-model="questionnaire.alightingRefueled"
                          />
                          <label for="on07" class="switch-on02">はい</label>
                          <input
                            type="radio"
                            name="s7"
                            id="off07"
                            :value="false"
                            checked
                            v-model="questionnaire.alightingRefueled"
                          />
                          <label for="off07" class="switch-off02">いいえ</label>
                        </div>
                        <span class="enquete_input_input2">
                          <span class="enquete_input_input_ttl">給油量：</span
                          ><input
                            type="text"
                            name=""
                            v-model="questionnaire.alightingRefueledAmount"
                            :maxlength="3"
                            @input="
                              (event) =>
                                (questionnaire.alightingRefueledAmount = event.target.value.replace(
                                  /[^0-9]/g,
                                  ''
                                ))
                            "
                          />
                          <span class="enquete_input_unit">リットル</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="nippou_box noborder" v-if="usagePattern === 2" id="alightingDiaryNote">
                  <div class="enquete_a_input">
                    <div class="enquete_input bikouran">
                      <span class="enquete_input_head">備考欄</span>
                      <div class="enquete_a remark">
                        <textarea
                          name=""
                          rows="3"
                          cols="40"
                          class="maxw"
                          v-model="questionnaire.alightingDiaryNote"
                          :maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="next_step" v-if="!(ecocarType === 1 && usagePattern === 1)">
                すべて回答したら次へ
              </div>

              <h3 class="checkttl" id="target-check-list">
                利用終了前に確認してチェックをしてください
              </h3>
              <span class="enquete_memo"
                >詳細が不明な場合は<a id="returnUrlLink" class="link_text" @click="goToReturnPage"
                  >使い方・ユーザーガイド</a
                >を参照ください。</span
              >

              <ul class="confirmation">
                <!--車内にゴミは残っていませんか？-->
                <label class="maxw">
                  <div class="enq_icon"><img src="img/enq_icon_1.svg" /></div>
                  <span>車内に<strong>ゴミ</strong>は残っていませんか？</span>
                </label>
                <!--忘れ物はありませんか？-->
                <label class="maxw">
                  <div class="enq_icon"><img src="img/enq_icon_2.svg" /></div>
                  <span><strong>忘れ物</strong>はありませんか？</span>
                </label>
                <!--ETCカードの抜き忘れはありませんか？-->
                <label class="maxw">
                  <div class="enq_icon"><img src="img/enq_icon_3.svg" /></div>
                  <span><strong>ETCカード</strong>の抜き忘れはありませんか？</span>
                </label>
                <!--窓の閉め忘れ、ライトの消し忘れはありませんか？-->
                <label class="maxw">
                  <div class="enq_icon"><img src="img/enq_icon_4.svg" /></div>
                  <span
                    ><strong>窓</strong
                    >の閉め忘れ、<strong>ライト</strong>の消し忘れはありませんか？</span
                  >
                </label>
                <!--キーボックスに鍵を返却しましたか？-->
                <label class="maxw" v-if="onboardUnitInstalled === 1" id="returnKey">
                  <div class="enq_icon"><img src="img/enq_icon_5.svg" /></div>
                  <span>キーボックスに<strong>鍵</strong>を返却しましたか？</span>
                </label>
                <!--駐車位置（N番）は正しいですか？-->
                <label class="maxw" id="installedSection">
                  <div class="enq_icon"><img src="img/enq_icon_7.svg" /></div>
                  <span
                    ><strong>駐車位置{{ pointPacking }}</strong
                    >は正しいですか？</span
                  >
                </label>
                <!--充電プラグを接続しましたか？-->
                <label
                  class="maxw"
                  v-if="
                    chargerTypeId !== 3 &&
                    chargerTypeId !== 4 &&
                    chargerTypeId !== 5 &&
                    chargerTypeId !== 6 &&
                    chargerTypeId !== 7 &&
                    chargerTypeId !== 8 &&
                    ecocarType !== 3
                  "
                  id="chargerQuestion1"
                >
                  <div class="enq_icon"><img src="img/enq_icon_6.svg" /></div>
                  <span><strong>充電プラグ</strong>を接続しましたか？</span>
                </label>
                <!--充電ケーブルを接続してから15秒程度経過しましたか？-->
                <label
                  class="maxw"
                  v-if="(chargerTypeId === 3 || chargerTypeId === 6) && ecocarType !== 3"
                  id="chargerQuestion2"
                >
                  <div class="enq_icon"><img src="img/enq_icon_6.svg" /></div>
                  <span><strong>充電ケーブル</strong>を接続してから15秒程度経過しましたか？</span>
                </label>
                <!--充電器パネルの「コネクタロック」スイッチを押下しましたか？-->
                <label
                  class="maxw"
                  v-if="
                    (chargerTypeId === 4 || chargerTypeId === 5 || chargerTypeId === 8) &&
                    ecocarType !== 3
                  "
                  id="chargerQuestion3"
                >
                  <div class="enq_icon"><img src="img/enq_icon_6.svg" /></div>
                  <span
                    ><strong>充電器パネル</strong
                    >の「コネクタロック」スイッチを押下しましたか？</span
                  >
                </label>
                <!--「コネクタロック」スイッチのLEDが点灯していますか？-->
                <label
                  class="maxw"
                  v-if="
                    (chargerTypeId === 4 || chargerTypeId === 5 || chargerTypeId === 8) &&
                    ecocarType !== 3
                  "
                  id="chargerQuestion4"
                >
                  <div class="enq_icon"><img src="img/enq_icon_6.svg" /></div>
                  <span><strong> 「コネクタロック」</strong>スイッチのLEDが点灯していますか？</span>
                </label>
                <!--充電プラグはしっかり接続されていますか？-->
                <label
                  class="maxw"
                  v-if="
                    (chargerTypeId === 4 ||
                      chargerTypeId === 5 ||
                      chargerTypeId === 7 ||
                      chargerTypeId === 8) &&
                    ecocarType !== 3
                  "
                  id="chargerQuestion5"
                >
                  <div class="enq_icon"><img src="img/enq_icon_6.svg" /></div>
                  <span><strong>充電プラグ</strong>はしっかり接続されていますか？</span>
                </label>
                <!--その他の項目の文言-->
                <label
                  class="maxw alightingChecklistCustom"
                  v-for="(item, index) in questionnaire.alightingChecklistCustom"
                  :key="index"
                >
                  <div class="enq_icon"><img src="img/enq_icon_8.svg" /></div>
                  <span>{{ item.questionName }}</span>
                </label>

                <div class="all_check">
                  <label class="maxw">
                    <input type="checkbox" v-model="hasCheckList" :value="true" />
                    <span>確認できたらチェック</span>
                  </label>
                </div>
              </ul>

              <div class="next_step">すべてチェックできたら次へ</div>

              <h3 class="checkttl-strong">次の予約の方のため、ご利用状況を教えてください</h3>

              <div class="enquete_q_smid">ご利用前</div>

              <!--ご利用開始時に車内にゴミ等が残っていませんでしたか？-->
              <div class="enqute_flex nopadingnoborder">
                <div class="enquete_q">
                  <span class="hissu">必須</span>ご利用開始時に車内にゴミ等が残っていましたか？
                </div>
                <div class="enquete_b">
                  <div class="switch">
                    <input
                      type="radio"
                      name="s1"
                      id="on01"
                      :value="true"
                      v-model="questionnaire.trashRemained"
                    />
                    <label for="on01" class="switch-on02">はい</label>
                    <input
                      type="radio"
                      name="s1"
                      id="off01"
                      :value="false"
                      checked
                      v-model="questionnaire.trashRemained"
                    />
                    <label for="off01" class="switch-off02">いいえ</label>
                  </div>
                </div>
              </div>
              <!--//ご利用開始時に車内にゴミ等が残っていませんでしたか？-->

              <div id="target-vehicle-cleaness" class="enquete_q_smid2">現在の状況</div>

              <!--現在のキレイさを星の数でお知らせください-->
              <div class="enqute_flex">
                <div class="enquete_q">
                  <span class="hissu">必須</span>現在のキレイさを星の数でお知らせください
                </div>
                <div class="enquete_b">
                  <div class="cf2">
                    <div class="dt">車外：</div>
                    <div class="evaluation">
                      <input
                        id="star1"
                        type="radio"
                        name="star1"
                        value="5"
                        v-model="questionnaire.cleanlinessWhenReturningVehicleBody"
                      />
                      <label for="star1">★</label>
                      <input
                        id="star2"
                        type="radio"
                        name="star1"
                        value="4"
                        v-model="questionnaire.cleanlinessWhenReturningVehicleBody"
                      />
                      <label for="star2">★</label>
                      <input
                        id="star3"
                        type="radio"
                        name="star1"
                        value="3"
                        v-model="questionnaire.cleanlinessWhenReturningVehicleBody"
                      />
                      <label for="star3">★</label>
                      <input
                        id="star4"
                        type="radio"
                        name="star1"
                        value="2"
                        v-model="questionnaire.cleanlinessWhenReturningVehicleBody"
                      />
                      <label for="star4">★</label>
                      <input
                        id="star5"
                        type="radio"
                        name="star1"
                        value="1"
                        v-model="questionnaire.cleanlinessWhenReturningVehicleBody"
                      />
                      <label for="star5">★</label>
                    </div>
                  </div>
                  <div class="cf2">
                    <div class="dt">車内：</div>
                    <div class="evaluation">
                      <input
                        id="star21"
                        type="radio"
                        name="star2"
                        value="5"
                        v-model="questionnaire.cleanlinessWhenReturningInVehicle"
                      />
                      <label for="star21">★</label>
                      <input
                        id="star22"
                        type="radio"
                        name="star2"
                        value="4"
                        v-model="questionnaire.cleanlinessWhenReturningInVehicle"
                      />
                      <label for="star22">★</label>
                      <input
                        id="star23"
                        type="radio"
                        name="star2"
                        value="3"
                        v-model="questionnaire.cleanlinessWhenReturningInVehicle"
                      />
                      <label for="star23">★</label>
                      <input
                        id="star24"
                        type="radio"
                        name="star2"
                        value="2"
                        v-model="questionnaire.cleanlinessWhenReturningInVehicle"
                      />
                      <label for="star24">★</label>
                      <input
                        id="star25"
                        type="radio"
                        name="star2"
                        value="1"
                        v-model="questionnaire.cleanlinessWhenReturningInVehicle"
                      />
                      <label for="star25">★</label>
                    </div>
                  </div>
                </div>
              </div>
              <!--//現在のキレイさを星の数でお知らせください-->
              <!--大きなキズ、凹みはありますか？-->
              <div id="target-scratchDent" class="enqute_flex">
                <div class="enquete_q">
                  <span class="hissu">必須</span>大きなキズ、凹みはありますか？
                </div>
                <div class="enquete_b">
                  <div class="switch">
                    <input
                      type="radio"
                      name="s4"
                      id="on04"
                      :value="true"
                      v-model="questionnaire.scratchDent"
                    />
                    <label for="on04" class="switch-on02">はい</label>
                    <input
                      type="radio"
                      name="s4"
                      id="off04"
                      :value="false"
                      checked
                      v-model="questionnaire.scratchDent"
                    />
                    <label for="off04" class="switch-off02">いいえ</label>
                  </div>
                </div>
                <div class="enquete_comment show">
                  <textarea
                    name=""
                    rows="6"
                    cols="40"
                    class="maxw2"
                    placeholder="凹みの詳細を記入してください"
                    v-model="questionnaire.scratchDentDetail"
                    :maxlength="1000"
                  ></textarea>
                </div>
              </div>
              <!--//大きなキズ、凹みはありますか？-->

              <!--車内装備に故障はありますか？-->
              <div id="target-vehicleBreakdownDetail" class="enqute_flex">
                <div class="enquete_q">
                  <span class="hissu">必須</span>車内装備に故障はありますか？
                </div>
                <div class="enquete_b">
                  <div class="switch">
                    <input
                      type="radio"
                      name="s5"
                      id="on05"
                      :value="true"
                      v-model="questionnaire.vehicleBreakdown"
                    />
                    <label for="on05" class="switch-on02">はい</label>
                    <input
                      type="radio"
                      name="s5"
                      id="off05"
                      :value="false"
                      checked
                      v-model="questionnaire.vehicleBreakdown"
                    />
                    <label for="off05" class="switch-off02">いいえ</label>
                  </div>
                </div>
                <div class="enquete_comment show">
                  <textarea
                    name=""
                    rows="6"
                    cols="40"
                    class="maxw2"
                    placeholder="故障の詳細を記入してください"
                    v-model="questionnaire.vehicleBreakdownDetail"
                    :maxlength="1000"
                  ></textarea>
                </div>
              </div>
              <!--//車内装備に故障はありますか？-->

              <!--ご利用中に洗車をしましたか？-->
              <div class="enqute_flex pd50">
                <div class="enquete_q">
                  <span class="hissu">必須</span>ご利用中に洗車をしましたか？
                </div>
                <div class="enquete_b">
                  <div class="switch">
                    <input
                      type="radio"
                      name="s2"
                      id="on02"
                      :value="true"
                      v-model="questionnaire.washConfirmation"
                    />
                    <label for="on02" class="switch-on02">はい</label>
                    <input
                      type="radio"
                      name="s2"
                      id="off02"
                      :value="false"
                      checked
                      v-model="questionnaire.washConfirmation"
                    />
                    <label for="off02" class="switch-off02">いいえ</label>
                  </div>
                </div>
              </div>
              <!--//ご利用中に洗車をしましたか？-->

              <!--        alightingConditionCustom-->
              <div
                class="enqute_flex pd50"
                v-for="(question, index) in questionnaire.alightingConditionCustom"
                :key="index"
                :id="`alightingConditionCustom-${question.questionId}`"
              >
                <div class="enquete_q">
                  <span class="hissu" v-if="question.required === 1">必須</span
                  >{{ question.questionName }}
                </div>
                <div class="enquete_b">
                  <div class="switch">
                    <input
                      type="radio"
                      :name="question.questionId + '-name'"
                      :id="question.questionId + '-' + question.optionList[0].key"
                      :value="question.optionList[0].key"
                      v-model="question.answer[0]"
                    />
                    <label
                      :for="question.questionId + '-' + question.optionList[0].key"
                      class="switch-on02"
                      >{{ question.optionList[0].value }}</label
                    >
                    <input
                      type="radio"
                      :name="question.questionId + '-name'"
                      :id="question.questionId + '-' + question.optionList[1].key"
                      :value="question.optionList[1].key"
                      v-model="question.answer[0]"
                    />
                    <label
                      :for="question.questionId + '-' + question.optionList[1].key"
                      class="switch-off02"
                      >{{ question.optionList[1].value }}</label
                    >
                  </div>
                </div>
              </div>
              <!--        END alightingConditionCustom-->

              <div v-if="usagePattern !== 2" id="alightingQuestionnaire">
                <div class="next_step">すべて回答したら次へ</div>

                <h3 class="checkttl">
                  今後のサービスをより良くするために<br />よろしければアンケートにご協力ください
                </h3>

                <!--アンケート-->
                <div
                  class="enqute_flex pd50 last_enqutte"
                  v-for="(question, index) in questionnaire.alightingQuestionnaire"
                  :key="index"
                  :id="`alightingQuestionnaire-${question.questionId}`"
                >
                  <div class="enquete_q">
                    <span class="hissu" v-if="question.required === 1">必須</span
                    >{{ question.questionName }}
                  </div>
                  <div class="enquete_comment show">
                    <!--            INPUT TYPE: textarea-->
                    <textarea
                      v-if="question.inputType === 'textarea'"
                      name=""
                      rows="6"
                      cols="40"
                      class="maxw2"
                      v-model="question.answer[0]"
                      :maxlength="1000"
                    >
                    </textarea>
                    <!--            END INPUT TYPE: textarea-->

                    <!--            INPUT TYPE: text-->
                    <input
                      v-if="question.inputType === 'text'"
                      class="drive-setting-input2 max-width-unset w100"
                      type="text"
                      v-model="question.answer[0]"
                      :maxlength="100"
                    />
                    <!--            END INPUT TYPE: text-->

                    <!--            INPUT TYPE: radio-->
                    <div class="switch" v-if="question.inputType === 'radio'">
                      <input
                        type="radio"
                        :name="question.questionId + '-name'"
                        :id="question.questionId + '-op1-other'"
                        :value="question.optionList[0].key"
                        v-model="question.answer[0]"
                      />
                      <label :for="question.questionId + '-op1-other'" class="switch-on02">{{
                        question.optionList[0].value
                      }}</label>
                      <input
                        type="radio"
                        :name="question.questionId + '-name'"
                        :id="question.questionId + '-op2-other'"
                        :value="question.optionList[1].key"
                        checked
                        v-model="question.answer[0]"
                      />
                      <label :for="question.questionId + '-op2-other'" class="switch-off02">{{
                        question.optionList[1].value
                      }}</label>
                    </div>
                    <!--            END INPUT TYPE: radio-->

                    <!--            INPUT TYPE: checkbox-->
                    <div class="station_body" v-if="question.inputType === 'checkbox'">
                      <div
                        class="side-minicont"
                        v-for="(option, index) in question.optionList"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          :id="`alightingQuestionnaire-${question.questionId}-${option.key}`"
                          :name="option.key"
                          :value="false"
                          v-model="option.checked"
                          @change="updateAnswerCheckboxType(question)"
                        />
                        <label :for="`alightingQuestionnaire-${question.questionId}-${option.key}`">{{ option.value }}</label>
                      </div>
                    </div>
                    <!--           END INPUT TYPE: checkbox-->

                    <!--            INPUT TYPE: pulldown-->
                    <select
                      name="cartype"
                      id="cartype"
                      v-model="question.answer[0]"
                      v-if="question.inputType === 'pulldown'"
                    >
                      <option
                        v-for="t in optionList[question.questionId]"
                        :value="t.value"
                        :key="t.value"
                      >
                        {{ t.text }}
                      </option>
                    </select>
                    <!--            END INPUT TYPE: pulldown-->
                  </div>
                </div>
                <!--//アンケート-->
              </div>
            </div>
            <div class="btn_area pos_fixed">
              <button type="button" class="btn_l btn_blue submit" @click="submitQuestionnaire">
                {{ submitButtonText }}
              </button>
              <button
                type="button"
                class="btn_l btn_neblue"
                @click="cancelQuestionnaire"
                id="cancelButton"
              >
                キャンセル
              </button>
            </div>
          </div>
        </section>
      </main>
    </form>
    <app-footer></app-footer>
  </div>
</template>
<script>
  import { questionnaireApi, reservationStopApi } from '@/module/Api'
  import { GETTER_TYPES, MUTATION_TYPES } from '@/store'
  import _ from 'lodash'
  import {
    EcocarType,
    OnboardUnitInstalled,
    OTHER_QUESTION_KEY,
    QUESTION_INPUT_TYPE,
    UsagePattern,
    ManufacturerApiAvailable,
  } from '@/constants/constant'
  import { Utils } from '@/helpers/Utils'
  import Config from '@/conf/Config'
  import Loading from 'vue-loading-overlay'
  import {
    MENU002_LOCK_FAIL,
    MENU002_LOCK_SUCCESS,
    MENU002_QUESTIONNAIRE_MILEAGE_CONFIRM,
  } from '@/constants/constant.dialog'
  import { API_ERROR_CODE } from '@/constants/constant.api'
  import { API_ERROR_MESSAGE } from '@/conf/Message'

  const TEXT_MAX_LENGTH = 100
  const ALIGHTING_TOTAL_MILEAGE_MAX_LENGTH = 7
  const ALIGHTING_INPUT_BATTERY_LEVEL_MAX_LENGTH = 3
  const ALIGHTING_REFUELED_AMOUNT_MAX_LENGTH = 3
  const TEXTAREA_MAX_LENGTH = 1000

  export default {
    components: { Loading },
    props: {},
    computed: {
      submitButtonText() {
        if (this.onboardUnitInstalled === OnboardUnitInstalled.NO) {
          return '返却手続きを完了する'
        }
        return '返却手続きをして施錠する'
      },
      screenId: () => 'QUESTIONNAIRE', // <--- 個別に定義
      chargerTypeId() {
        return this.reservationInfo?.chargerTypeId
      },
      pointPacking() {
        return this.reservationInfo?.installedSection
          ? `（${this.reservationInfo.installedSection}）`
          : ''
      },
      usagePattern() {
        return this.reservationInfo?.usagePattern
      },
      ecocarType() {
        return this.reservationInfo?.ecocarType
      },
      onboardUnitInstalled() {
        return this.reservationInfo?.onboardUnitInstalled
      },
      manufacturerApiAvailable() {
        return this.reservationInfo?.manufacturerApiAvailable
      },
    },
    data() {
      return {
        optionList: [],
        hasCheckList: false,
        questionnaire: {
          previousTotalMileage: null,
          alightingTotalMileage: null,
          alightingInputBatteryLevel: null,
          alightingSelectedBatteryLevel: null,
          alightingCharged: null,
          alightingFuelLevel: null,
          alightingRefueled: null,
          alightingRefueledAmount: null,
          alightingDiaryNote: null,
          alightingChecklistCustom: [],
          cleanlinessWhenReturningVehicleBody: null,
          cleanlinessWhenReturningInVehicle: null,
          trashRemained: null,
          washConfirmation: null,
          scratchDent: null,
          scratchDentDetail: null,
          vehicleBreakdown: null,
          vehicleBreakdownDetail: null,
          alightingConditionCustom: [],
          alightingQuestionnaire: [],
        },
        reservationInfo: {},
      }
    },
    methods: {
      goToReturnPage() {
        window.open(this.corpParam.returnUrl, '_blank')
      },
      async getInitialData() {
        this.initQuestionnaire()
        this.createOptionList()
      },
      /**
       * Init questionnaire from
       */
      initQuestionnaire() {
        const survey = this.questionnaire
        if (this.ecocarType === EcocarType.PHEV) {
          survey.alightingSelectedBatteryLevel = 0
        }
        if (this.ecocarType !== EcocarType.EV) {
          survey.alightingFuelLevel = 0
        }
        if (this.ecocarType === EcocarType.GAS_VEHICLE) {
          survey.alightingRefueled = survey.alightingRefueled ? survey.alightingRefueled : false
        }
        if (this.ecocarType === EcocarType.EV && this.usagePattern === UsagePattern.JOINT_USE) {
          survey.alightingCharged = survey.alightingCharged ? survey.alightingCharged : false
        }
        survey.trashRemained = survey.trashRemained ? survey.trashRemained : false
        survey.washConfirmation = survey.washConfirmation ? survey.washConfirmation : false
        survey.cleanlinessWhenReturningVehicleBody = survey.cleanlinessWhenReturningVehicleBody
          ? survey.cleanlinessWhenReturningVehicleBody
          : 0
        survey.cleanlinessWhenReturningInVehicle = survey.cleanlinessWhenReturningInVehicle
          ? survey.cleanlinessWhenReturningInVehicle
          : 0
        survey.scratchDent = survey.scratchDent ? survey.scratchDent : false
        survey.scratchDentDetail = survey.scratchDentDetail ? survey.scratchDentDetail : null
        survey.vehicleBreakdown = survey.vehicleBreakdown ? survey.vehicleBreakdown : false
        survey.vehicleBreakdownDetail = survey.vehicleBreakdownDetail
          ? survey.vehicleBreakdownDetail
          : null
      },
      createOptionList() {
        this.questionnaire.alightingQuestionnaire?.forEach((el) => {
          if (el.inputType === QUESTION_INPUT_TYPE.PULL_DOWN) {
            if (el.required) {
              // Check arr answer
              // If answer empty, add option default to optionList
              if (Utils.isEmpty(el.answer)) {
                const options = [{ text: '', value: '' }]
                el.optionList.forEach((item) => {
                  options.push({
                    text: item.value,
                    value: item.key,
                  })
                })
                this.optionList[el.questionId] = options
              } else {
                const options = []
                el.optionList.forEach((item) => {
                  options.push({
                    text: item.value,
                    value: item.key,
                  })
                })
                this.optionList[el.questionId] = options
              }
            } else {
              const options = [{ text: '選択してください', value: '' }]
              el.optionList.forEach((item) => {
                options.push({
                  text: item.value,
                  value: item.key,
                })
              })
              this.optionList[el.questionId] = options
            }
          }
          if (el.inputType === QUESTION_INPUT_TYPE.CHECK_BOX) {
            // Check arr answer
            // If answer empty, add checked = false >< add checked = true for answer mapping
            if (Utils.isEmpty(el.answer)) {
              el.optionList.forEach((item) => {
                item.checked = false
              })
            } else {
              el.optionList.forEach((item) => {
                el.answer.forEach((result) => {
                  if (item.key === result) {
                    item.checked = true
                  }
                })
              })
            }
          }
        })
      },
      changeAlightingSelectedBatteryLevel() {
        this.questionnaire.alightingSelectedBatteryLevel = parseInt(
          document.getElementById('alighting_selected_battery_level').value
        )
      },
      changeAlightingFuelLevel() {
        this.questionnaire.alightingFuelLevel = parseInt(
          document.getElementById('alighting_fuelLevel').value
        )
      },
      updateAnswerCheckboxType(question) {
        const optionList = question.optionList
        const ans = []
        optionList.forEach((e) => {
          if (e.checked) {
            ans.push(e.key)
          }
        })
        question.answer = ans
      },
      async scrollErrorTarget(targetId) {
        console.log(targetId)
        document.getElementById(targetId).scrollIntoView(true)
      },
      isDisableButtonSubmit() {
        let errMsg

        if (
          // Total mileage (km) is required when OnboardUnitInstalled == 0(Onboard Unit not be Installed on vehicle)
          !(this.ecocarType === EcocarType.EV && this.usagePattern === UsagePattern.GENERAL) &&
          this.onboardUnitInstalled === OnboardUnitInstalled.NO &&
          this.manufacturerApiAvailable === ManufacturerApiAvailable.OFF && 
          ( !Utils.isNumber(this.questionnaire.alightingTotalMileage) ||
            (this.questionnaire.alightingTotalMileage &&
              this.questionnaire.alightingTotalMileage.length > ALIGHTING_TOTAL_MILEAGE_MAX_LENGTH))
        ) {
            this.scrollErrorTarget('alightingTotalMileage')
            errMsg = '積算走行距離を入力してください。'
        } else if (
          // Refueling record, refueling amount (L) is required when OnboardUnitInstalled == 0 && ecoCarType == 1 (EV)
          this.usagePattern !== UsagePattern.GENERAL &&
          this.onboardUnitInstalled === OnboardUnitInstalled.NO &&
          this.ecocarType === EcocarType.EV &&
          this.manufacturerApiAvailable === ManufacturerApiAvailable.OFF && 
          (!Utils.isNumber(this.questionnaire.alightingInputBatteryLevel) ||
            (this.questionnaire.alightingInputBatteryLevel &&
              this.questionnaire.alightingInputBatteryLevel.length > ALIGHTING_INPUT_BATTERY_LEVEL_MAX_LENGTH))
        ) {
            this.scrollErrorTarget('alightingInputBatteryLevel')
            errMsg = 'バッテリー残量を入力してください。'
        } else if (this.ecocarType === EcocarType.GAS_VEHICLE && 
          ((this.questionnaire.alightingRefueled &&
              !Utils.isNumber(this.questionnaire.alightingRefueledAmount)) ||
            (this.questionnaire.alightingRefueledAmount &&
              this.questionnaire.alightingRefueledAmount.length >
                ALIGHTING_REFUELED_AMOUNT_MAX_LENGTH))
        ) {
            this.scrollErrorTarget('alightingRefueled')
            errMsg = '給油量を入力してください。'
        } else if (!this.hasCheckList) {
          this.scrollErrorTarget('target-check-list')
          errMsg = '確認項目をチェックしてください。'
        } else if (
          !this.questionnaire.cleanlinessWhenReturningVehicleBody ||
          !this.questionnaire.cleanlinessWhenReturningInVehicle
        ) {
          this.scrollErrorTarget('target-vehicle-cleaness')
          errMsg = '現在のキレイさを入力してください。'
        } else if (
          (this.questionnaire.scratchDent &&
            Utils.isEmptyString(this.questionnaire.scratchDentDetail)) ||
          (!Utils.isEmptyString(this.questionnaire.scratchDentDetail) &&
            this.questionnaire.scratchDentDetail.length > TEXTAREA_MAX_LENGTH)
        ) {
          this.scrollErrorTarget('target-scratchDent')
          errMsg = 'キズ、凹みの詳細を入力してください。'
        } else if (
          (this.questionnaire.vehicleBreakdown &&
            Utils.isEmptyString(this.questionnaire.vehicleBreakdownDetail)) ||
          (!Utils.isEmptyString(this.questionnaire.vehicleBreakdownDetail) &&
            this.questionnaire.vehicleBreakdownDetail.length > TEXTAREA_MAX_LENGTH)
        ) {
          this.scrollErrorTarget('target-vehicleBreakdownDetail')
          errMsg = '故障の詳細を入力してください。'
        } else if (!this.isValidFormSubmit()) {
          // スクロールはisValidFormSubmitで実施
          errMsg = 'アンケートの必須項目を入力してください。'
        }

        return errMsg
      },
      /**
       * Check required alightingConditionCustom and alightingQuestionnaire and scratchDent and vehicleBreakdown
       * return false: if have any error
       * return true: if no error
       */
      isValidFormSubmit() {
        let result = true

        if (
          this.questionnaire.alightingDiaryNote &&
          this.questionnaire.alightingDiaryNote.length > TEXTAREA_MAX_LENGTH
        ) {
          this.scrollErrorTarget('alightingDiaryNote')
          result = false
        }

        this.questionnaire.alightingConditionCustom.forEach((e) => {
          const isEmpty = Utils.isEmpty(e.answer)
          if (result && e.required === 1 && isEmpty) {
            this.scrollErrorTarget(`alightingConditionCustom-${e.questionId}`)
            result = false
          }
        })

        if (result && this.usagePattern !== UsagePattern.JOINT_USE) {
          const otherQuestionList = []
          this.questionnaire.alightingQuestionnaire.forEach((e) => {
            const isEmpty = Utils.isEmpty(e.answer)
            if (result && e.required === 1 && isEmpty) {
              this.scrollErrorTarget(`alightingQuestionnaire-${e.questionId}`)
              result = false
            } else if (
              result &&
              e.inputType === QUESTION_INPUT_TYPE.TEXT &&
              e.answer[0] &&
              e.answer[0].length > TEXT_MAX_LENGTH
            ) {
              this.scrollErrorTarget(`alightingQuestionnaire-${e.questionId}`)
              result = false
            } else if (
              result &&
              e.inputType === QUESTION_INPUT_TYPE.TEXT_AREA &&
              e.answer[0] &&
              e.answer[0].length > TEXTAREA_MAX_LENGTH
            ) {
              this.scrollErrorTarget(`alightingQuestionnaire-${e.questionId}`)
              result = false
            } else if (
              result &&
              e.inputType === QUESTION_INPUT_TYPE.CHECK_BOX &&
              e.answer.includes(OTHER_QUESTION_KEY)
            ) {
              otherQuestionList.push(e.questionId)
            }
          })

          otherQuestionList.forEach((questionId) => {
            const childQuestion = this.questionnaire.alightingQuestionnaire.find((question) => {
              return question.linkageQuestionId === questionId
            })
            if (result && Utils.isEmpty(childQuestion.answer)) {
              this.scrollErrorTarget(`alightingQuestionnaire-${childQuestion.questionId}`)
              result = false
            }
          })
        }

        return result
      },
      async submitQuestionnaire() {
        // ボタンクリックは可能にし、エラーがあればメッセージを表示して対象箇所へスクロールする
        const msg = this.isDisableButtonSubmit()
        if (msg) {
          this.$_confirm.dialogOpen(msg)
          return
        }

        if (
          !(this.ecocarType === EcocarType.EV && this.usagePattern === UsagePattern.GENERAL) &&
          this.onboardUnitInstalled === OnboardUnitInstalled.NO &&
          this.manufacturerApiAvailable === ManufacturerApiAvailable.OFF &&
          this.questionnaire.alightingTotalMileage < this.questionnaire.previousTotalMileage
        ) {
          await this.$_confirm.confirmOpen(
            Utils.sprintf(MENU002_QUESTIONNAIRE_MILEAGE_CONFIRM.message, [
              this.corpParam.userGuideUrl,
            ]),
            null,
            MENU002_QUESTIONNAIRE_MILEAGE_CONFIRM.okBtn,
            MENU002_QUESTIONNAIRE_MILEAGE_CONFIRM.cancelBtn
          )
        }
        const requestData = {
          reservationId: null,
          alightingTotalMileage: null,
          alightingInputBatteryLevel: null,
          alightingSelectedBatteryLevel: null,
          alightingCharged: null,
          alightingFuelLevel: null,
          alightingRefueled: null,
          alightingRefueledAmount: null,
          alightingDiaryNote: null,
          cleanlinessWhenReturningVehicleBody: null,
          cleanlinessWhenReturningInVehicle: null,
          trashRemained: null,
          washConfirmation: null,
          scratchDent: null,
          scratchDentDetail: null,
          vehicleBreakdown: null,
          vehicleBreakdownDetail: null,
          alightingConditionCustom: null,
          alightingQuestionnaire: null,
        }
        requestData.reservationId = this.reservationInfo.reservationId
        Utils.isEmptyString(this.questionnaire.alightingTotalMileage)
          ? (requestData.alightingTotalMileage = null)
          : (requestData.alightingTotalMileage = parseInt(this.questionnaire.alightingTotalMileage))
        Utils.isEmptyString(this.questionnaire.alightingInputBatteryLevel)
          ? (requestData.alightingInputBatteryLevel = null)
          : (requestData.alightingInputBatteryLevel = parseInt(
              this.questionnaire.alightingInputBatteryLevel
            ))
        requestData.alightingSelectedBatteryLevel = this.questionnaire.alightingSelectedBatteryLevel
        requestData.alightingCharged = this.questionnaire.alightingCharged
        requestData.alightingFuelLevel = this.questionnaire.alightingFuelLevel
        requestData.alightingRefueled = this.questionnaire.alightingRefueled
        Utils.isEmptyString(this.questionnaire.alightingRefueledAmount)
          ? (requestData.alightingRefueledAmount = null)
          : (requestData.alightingRefueledAmount = parseInt(
              this.questionnaire.alightingRefueledAmount
            ))
        requestData.alightingDiaryNote = this.questionnaire.alightingDiaryNote
        requestData.cleanlinessWhenReturningVehicleBody =
          this.questionnaire.cleanlinessWhenReturningVehicleBody
        requestData.cleanlinessWhenReturningInVehicle =
          this.questionnaire.cleanlinessWhenReturningInVehicle
        requestData.trashRemained = this.questionnaire.trashRemained
        requestData.washConfirmation = this.questionnaire.washConfirmation
        requestData.scratchDent = this.questionnaire.scratchDent
        requestData.scratchDentDetail = this.questionnaire.scratchDentDetail
        requestData.vehicleBreakdown = this.questionnaire.vehicleBreakdown
        requestData.vehicleBreakdownDetail = this.questionnaire.vehicleBreakdownDetail
        const alightingConditionCustomRequest = []
        this.questionnaire.alightingConditionCustom.forEach((e) => {
          alightingConditionCustomRequest.push({
            questionKey: e.questionKey,
            answer: e.answer,
          })
        })
        requestData.alightingConditionCustom = JSON.stringify(alightingConditionCustomRequest)
        const alightingQuestionnaireRequest = []
        this.questionnaire.alightingQuestionnaire.forEach((e) => {
          if (e.inputType === QUESTION_INPUT_TYPE.PULL_DOWN) {
            if (!e.answer[0]) {
              alightingQuestionnaireRequest.push({ questionKey: e.questionKey, answer: [] })
            } else {
              alightingQuestionnaireRequest.push({ questionKey: e.questionKey, answer: e.answer })
            }
          } else {
            alightingQuestionnaireRequest.push({
              questionKey: e.questionKey,
              answer: e.answer,
            })
          }
        })
        requestData.alightingQuestionnaire = JSON.stringify(alightingQuestionnaireRequest)

        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
        try {
          await questionnaireApi.putQuestionnaire(this.reservationInfo.reservationId, requestData)

          try {
            await reservationStopApi.usingStop(this.reservationInfo.reservationId)
            this.$_confirm
              .dialogOpen(MENU002_LOCK_SUCCESS.message, MENU002_LOCK_SUCCESS.header)
              .then(async () => {
                // マイページへ
                this.go('mypage')
              })
          } catch (e) {
            if (e.status === API_ERROR_CODE.SERVICE_UNAVAILABLE) {
              // メンテナンスモード
              this.go('maintenance')
            } else if (e.status === API_ERROR_CODE.UNKNOWN_ERROR) {
              await this.$_confirm.dialogOpen(API_ERROR_MESSAGE.TIMEOUT_ERROR, null)
            } else {
              await this.$_confirm.dialogOpen(
                await this.getErrorMessage(e),
                MENU002_LOCK_FAIL.header
              )
            }
          }
        } catch (e) {
          await this.validationErrorHook(e)
        } finally {
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
        }
      },
      cancelQuestionnaire() {
        this.$router.push(Config.URL.MYPAGE)
      },
    },
    created() {
      document.cookie = Config.FUNCTION_ID + this.screenId
      this.reservationInfo = this.$store.getters[GETTER_TYPES.GET_RESERVATION_DATA]
        ? _.cloneDeep(this.$store.getters[GETTER_TYPES.GET_RESERVATION_DATA])
        : {}
      this.questionnaire = this.$store.getters[GETTER_TYPES.GET_QUESTIONNAIRE_DATA]
        ? _.cloneDeep(this.$store.getters[GETTER_TYPES.GET_QUESTIONNAIRE_DATA])
        : {}
      this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
      this.$store.commit(MUTATION_TYPES.SET_RESERVATION_DATA, null)
      this.$store.commit(MUTATION_TYPES.SET_QUESTIONNAIRE_DATA, null)
      this.getInitialData()
    },
  }
</script>

<style scoped>
  .loading-msg {
    margin-top: 60px;
  }
</style>
