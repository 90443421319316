<template>
  <app-header></app-header>
  <main class="station_body">
    <div class="widthset">
      <!--widthを1366pxに設定-->
      <div class="cont">
        <section id="sidebar" style="z-index: 5">
          <div class="side-mystation">
            <div class="side-cont">
              <h3 class="sidettl">マイステーション</h3>
              <div class="">
                <loading
                  :active="my_station_processing || map_processing"
                  :loader="'dots'"
                  :color="'#007ecb'"
                  :can-cancel="true"
                  :is-full-page="false"
                  :style="'margin-top: 40px;'"
                />
                <ul class="mystation-list mystation-cont">
                  <div
                    class="side-cont noreserve"
                    v-if="!my_station_processing && favoriteStationView.length === 0"
                  >
                    <div class="side-minicont dateprice">
                      <div class="terms2">
                        <p>マイステーションが登録されていません</p>
                      </div>
                    </div>
                  </div>

                  <li
                    v-for="fs in favoriteStationView"
                    :key="fs.evstationId"
                    :class="{ 'un-publish': fs.publishStatus == 0 }"
                  >
                    <a href="#" @click.stop.prevent="moveMap(fs.latitude, fs.longitude)">
                      {{ fs.evstationName }}
                      <div class="af" v-if="fs.publishStatus != 0">
                        <span></span>
                      </div>
                      <!-- 非公開ステーションはAPIで弾く設計のため削除ボタンは登場しない -->
                      <button
                        v-else
                        class="dl"
                        @click.stop.prevent="toggleMyStation(fs.evstationId, true)"
                        title="削除"
                      ></button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="sidebox">
            <!--切り替えタブ-->
            <ul class="tabs">
              <li
                id="srcfilter-tub"
                :class="{ select: form.tab === tabName.SEARCH }"
                @click.stop.prevent="selectTab(tabName.SEARCH)"
              >
                検索条件
              </li>
              <li
                id="bookinglist-tub"
                :class="{ select: form.tab === tabName.RESERVATION_LIST }"
                @click.stop.prevent="selectTab(tabName.RESERVATION_LIST)"
              >
                予約一覧
              </li>
              <li
                id="backnumber-tub"
                :class="{ select: form.tab === tabName.HISTORY_LIST }"
                @click.stop.prevent="selectTab(tabName.HISTORY_LIST)"
              >
                利用履歴
              </li>
            </ul>
            <!--/切り替えタブ-->

            <!--検索条件の内容-->
            <div class="side-section" id="srcfilter" v-show="form.tab === tabName.SEARCH">
              <div class="side-cont mapsrc">
                <h3 class="sidettl"><img src="img/pin.svg" alt="マップ検索" />マップ検索</h3>

                <div class="side-minicont">
                  <h4>利用日時</h4>
                  <div class="terms">
                    <date-time-picker-range
                      :value="time"
                      @change="changeDateTime"
                      ref="dateTimePickerRange"
                    />
                  </div>
                </div>

                <div class="side-minicont">
                  <h4>場所検索</h4>
                  <div class="srcform">
                    <input
                      id="pac-input"
                      type="text"
                      autocomplete="off"
                      value
                      placeholder="住所、施設名"
                      class="maxw srcform-txt"
                    />
                    <input
                      type="submit"
                      value="移動"
                      class="srcform-submit"
                      @click="placesChanged"
                    />
                  </div>
                </div>
              </div>

              <div class="side-cont carfilter">
                <h3 class="sidettl"><img src="img/car.svg" alt="車両絞り込み" />車両絞り込み</h3>
                <div class="side-minicont">
                  <input
                    type="checkbox"
                    id="check1"
                    name="check1"
                    :value="false"
                    v-model="vehicleFilter.canReserveFlg"
                  />
                  <label for="check1">予約可能車両のみ表示</label>
                </div>

                <div class="side-minicont">
                  <h4>エコカー種別</h4>
                  <input
                    type="checkbox"
                    id="check2"
                    name="check2"
                    :value="1"
                    v-model="vehicleFilter.ecocarType"
                  />
                  <label for="check2">EV</label>
                  <input
                    type="checkbox"
                    id="check3"
                    name="check3"
                    :value="2"
                    v-model="vehicleFilter.ecocarType"
                  />
                  <label for="check3">PHEV</label>
                  <input
                    type="checkbox"
                    id="check4"
                    name="check4"
                    :value="3"
                    v-model="vehicleFilter.ecocarType"
                  />
                  <label for="check4">ガソリン車</label>
                </div>

                <div class="side-minicont">
                  <h4>車両タイプ</h4>
                  <select name="cartype" id="cartype" v-model="vehicleFilter.vehicleBodyType">
                    <option value>指定なし</option>
                    <option v-for="t in vehicleBodyTypeList" :value="t.codeAlias" :key="t.codeKey">
                      {{ t.codeAlias }}
                    </option>
                  </select>
                </div>

                <div class="side-minicont">
                  <h4>車種</h4>
                  <select name="cartype" id="cartype" v-model="vehicleFilter.vehicleTypeGroupId">
                    <option value>指定なし</option>
                    <optgroup v-for="(g, index) in vehicleTypeList" :label="g.label" :key="index">
                      <option v-for="o in g.options" :value="o.value" :key="o.value">
                        {{ o.text }}
                      </option>
                    </optgroup>
                  </select>
                </div>

                <div class="side-minicont">
                  <h4>利用時の走行可能距離</h4>
                  <select
                    name="energie"
                    id="energie"
                    v-model="vehicleFilter.estimatedTravelDistance"
                  >
                    <option v-for="t in estimatedTravelDistanceList" :value="t.key" :key="t.key">
                      {{ t.value }}
                    </option>
                  </select>
                </div>
                <div class="side-minicont side-eq">
                  <h4>装備品</h4>
                  <template v-for="e in equipmentList" :key="e.key">
                    <input
                      type="checkbox"
                      :id="`checke${e.key}`"
                      :value="e.key"
                      v-model="vehicleFilter.equipment"
                    /><label :for="`checke${e.key}`">{{ e.value }}</label>
                  </template>
                  <input type="submit" value="車両絞り込み" @click="setFilter()" />
                  <div class="search-reset">
                    <a href="#" @click.prevent.stop="clearFilter()">車両絞り込み条件をリセット</a>
                  </div>
                </div>
              </div>
            </div>
            <!--/検索条件の内容-->

            <!--予約一覧の内容-->
            <div class="side-section" id="bookinglist" v-if="form.tab === tabName.RESERVATION_LIST">
              <loading
                :active="processing"
                :loader="'dots'"
                :color="'#007ecb'"
                :can-cancel="true"
                :is-full-page="false"
              />

              <div class="side-cont noreserve" v-if="reservationList.length === 0">
                <div class="side-minicont dateprice">
                  <div class="terms2">
                    <p>予約はありません</p>
                  </div>
                </div>
              </div>
              <div
                class="side-cont"
                v-for="reserve in reservationList"
                :key="reserve.reservationId"
              >
                <div class="side-minicont dateprice">
                  <div class="terms2">
                    <div class="term_start">
                      <input
                        type="datetime"
                        :value="reserve.reservationStartDatetime"
                        class="date_picker"
                      />
                    </div>
                    <div class="term_dash"></div>
                    <div class="term_end">
                      <input
                        type="datetime"
                        :value="reserve.reservationEndDatetime"
                        class="date_picker"
                      />
                    </div>
                  </div>
                  <div class="price" v-if="reserve.usagePattern === 1">
                    {{
                      reserve.estimatedTimeCharge +
                        reserve.estimatedNightpackCharge +
                        reserve.estimatedInsuranceCharge -
                        reserve.estimatedDiscount >
                      0
                        ? (
                            reserve.estimatedTimeCharge +
                            reserve.estimatedNightpackCharge +
                            reserve.estimatedInsuranceCharge -
                            reserve.estimatedDiscount
                          ).toLocaleString()
                        : 0
                    }}
                    <span>円</span>
                  </div>
                </div>
                <div class="side-minicont option">
                  <ul>
                    <li>{{ reserve.vehicleName }}（{{ reserve.vehicleColor }}）</li>
                    <li>{{ reserve.vehicleTypeClassName }}</li>
                    <li>{{ reserve.evstationName }}</li>
                    <li v-if="reserve.usagePattern === 1 && reserve.insuranceSubscribed === 1">
                      {{ reserve.insurancePlanName }}：加入あり
                    </li>
                    <li v-if="reserve.usagePattern === 1 && reserve.insuranceSubscribed === 0">
                      {{ reserve.insurancePlanName }}：加入なし
                    </li>
                    <li v-if="reserve.usagePattern === 1 && reserve.nightpackApplied === 1">
                      {{ reserve.nightPackName }}：適用あり
                    </li>
                    <li v-if="reserve.usagePattern === 1 && reserve.nightpackApplied === 0">
                      {{ reserve.nightPackName }}：適用なし
                    </li>
                    <li v-if="reserve.usagePattern === 1 && reserve.promotionCode">
                      プロモーション：適用済み
                    </li>
                  </ul>
                  <input
                    type="submit"
                    value="変更する"
                    class="srcform-submit"
                    @click.stop.prevent="editReservation(reserve.reservationId)"
                  />
                  <input
                    type="submit"
                    value="取り消す"
                    class="srcform-submit"
                    @click.stop.prevent="cancelReservation(reserve.reservationId)"
                  />
                </div>
              </div>
              <button class="moreopner2" @click="reservationMore()" v-if="reservationHasNext">
                さらに読み込む
                <img src="img/more2.svg" />
              </button>
            </div>
            <!--/予約一覧の内容-->

            <!--利用履歴の内容-->
            <div class="side-section" id="backnumber" v-if="form.tab === tabName.HISTORY_LIST">
              <loading
                :active="processing"
                :loader="'dots'"
                :color="'#007ecb'"
                :can-cancel="true"
                :is-full-page="false"
              />

              <div class="side-cont noreserve" v-if="historyList.length === 0">
                <div class="side-minicont dateprice">
                  <div class="terms2">
                    <p>利用履歴はありません</p>
                  </div>
                </div>
              </div>
              <div class="side-cont" v-for="reserve in historyList" :key="reserve.reservationId">
                <div class="side-minicont dateprice">
                  <div class="cancellabel">
                    <p
                      v-if="
                        reserve.reservationStatus !== 2 &&
                        ![4, 6, 7].includes(reserve.reservationStatus)
                      "
                      class="cancel"
                    >
                      キャンセル
                    </p>
                    <p
                      v-if="
                        reserve.reservationStatus !== 2 &&
                        [4, 6, 7].includes(reserve.reservationStatus)
                      "
                      class="cancel"
                    >
                      管理者キャンセル
                    </p>
                    <p v-if="reserve.settlementStatus === 3" class="ng">
                      {{ reserve.settlementStatusAlias }}
                    </p>
                    <!-- 決済NG -->
                  </div>
                  <div class="terms2">
                    <div class="term_start">
                      <input
                        type="datetime"
                        :value="reserve.reservationStartDatetime"
                        class="date_picker"
                        readonly
                      />
                    </div>
                    <div class="term_dash"></div>
                    <div class="term_end">
                      <input
                        type="datetime"
                        :value="reserve.reservationEndDatetime"
                        class="date_picker"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="price" v-if="reserve.usagePattern === 1">
                    {{ reserve.totalCharge > 0 ? reserve.totalCharge.toLocaleString() : 0 }}
                    <span>円</span>
                  </div>
                </div>
                <div class="side-minicont option">
                  <ul>
                    <li>{{ reserve.vehicleName }}</li>
                    <li>{{ reserve.vehicleTypeClassName }}</li>
                    <li>{{ reserve.evstationName }}</li>
                    <li v-if="reserve.usagePattern === 1 && reserve.insuranceSubscribed === 1">
                      {{ reserve.insurancePlanName }}：加入あり
                    </li>
                    <li v-if="reserve.usagePattern === 1 && reserve.insuranceSubscribed === 0">
                      {{ reserve.insurancePlanName }}：加入なし
                    </li>
                    <li v-if="reserve.usagePattern === 1 && reserve.nightpackApplied === 1">
                      {{ reserve.nightPackName }}：適用あり
                    </li>
                    <li v-if="reserve.usagePattern === 1 && reserve.nightpackApplied === 0">
                      {{ reserve.nightPackName }}：適用なし
                    </li>
                    <li v-if="reserve.usagePattern === 1 && reserve.promotionCode">
                      プロモーション：適用済み
                    </li>
                  </ul>
                  <input
                    type="submit"
                    value="再予約"
                    @click.stop.prevent="moveMap(reserve.latitude, reserve.longitude)"
                    class="srcform-submit on"
                  />
                  <input
                    type="submit"
                    value="詳細をみる"
                    class="srcform-submit"
                    @click.stop.prevent="showModalHistoryDetail(reserve.reservationId)"
                  />
                  <div class="comment" v-if="!reserve.trashRemained">
                    <p>次に利用した方から「キレイでしたよ！」と、感謝の声が届いてます</p>
                  </div>
                </div>
              </div>

              <button class="moreopner2" @click="historyMore()" v-if="historyHasNext">
                さらに読み込む
                <img src="img/more2.svg" />
              </button>
            </div>
            <!--/利用履歴の内容-->
          </div>
        </section>

        <section id="main-contents">
          <div class="station-cont">
            <div class="station-map" v-if="unionStartDateTime != null && unionEndDateTime != null">
              <div class="mapcontroll"></div>

              <Map
                :lat="form.lat"
                :lng="form.lng"
                :zoom="form.zoom"
                :startDateTime="unionStartDateTime.format('YYYY-MM-DD HH:mm:ss')"
                :endDateTime="unionEndDateTime.format('YYYY-MM-DD HH:mm:ss')"
                :vehicleFilter="vehicleFilterConfirm"
                @setMapResult="setMapResult"
                @setSelectEvstation="setSelectEvstation"
                ref="map"
              />
            </div>
            <!-- mapが読み込まれるまでLoading表示 -->
            <div class="station-map" v-else>
              <loading
                :active="true"
                :loader="'dots'"
                :color="'#007ecb'"
                :can-cancel="true"
                :is-full-page="false"
              />
            </div>
            <EvstationInfoMap
              :evstationId="form.selectEvstationId"
              :isCluster="clusterMode"
              @setVehicleId="setVehicleId"
              @toggleMyStation="toggleMyStation"
            />
          </div>
          <VehicleList
            :evstationRedis="evInfo"
            :vehicleIdList="vehicleList"
            :startDateTime="unionStartDateTime.format('YYYY-MM-DD HH:mm:ss')"
            :vehicleFilter="vehicleFilterConfirm"
            :isCluster="clusterMode"
            @goReservation="goReservation"
            @toggleMyCar="toggleMyCar"
          />
        </section>
        <a href="#" class="anchor-btn">
          <img src="img/anchor-btn.svg" />
        </a>
      </div>
    </div>
    <!--/widthを1366pxに設定-->
  </main>
  <app-footer></app-footer>

  <!-- modal -->
  <teleport to="body">
    <transition name="slide-up">
      <div v-if="isModal" class="modal">
        <ReservationEdit
          :reservationId="form.reservationId"
          :vehicleId="form.selectVehicleId"
          :evstationId="form.selectEvstationId"
          :startDateTime="unionStartDateTime.format('YYYY-MM-DD HH:mm:ss')"
          :endDateTime="unionEndDateTime.format('YYYY-MM-DD HH:mm:ss')"
          @closeModal="modalClose"
        />
      </div>
    </transition>
  </teleport>
  <teleport to="body">
    <transition name="slide-up">
      <div v-if="isShowModalHistoryDetail" class="modal">
        <UsageHistoryDetail
          :reservationId="form.historyReservationId"
          @closeModal="closeModalHistoryDetail"
        />
      </div>
    </transition>
  </teleport>
</template>

<script>
  // @ is an alias to /src

  import EvstationInfoMap from '@/components/EvstationInfoMap.vue'
  import ReservationEdit from '@/components/ReservationEdit.vue'
  import Map from '@/components/Map.vue'
  import VehicleList from '@/components/VehicleList.vue'
  import { isEmptyObject } from '@/helpers/validators'
  import Config from '@/conf/Config'
  import { MUTATION_TYPES } from '@/store'
  import { SESSION_MUTATION_TYPES } from '@/store/modules/session'
  import { FORM_MUTATION_TYPES } from '@/store/modules/form'
  import { FAVORITE_MESSAGE } from '@/conf/Message'

  import moment from 'moment'
  import {
    usageHistoryApi,
    reservationApi,
    reservationCancelApi,
    commonApi,
    sessionApi,
    codeApi,
    favoriteApi,
  } from '@/module/Api'
  import Auth from '@/module/Auth'
  import UsageHistoryDetail from '@/components/UsageHistoryDetail'
  import Loading from 'vue-loading-overlay'
  import { DATE_TIME_FORMAT } from '@/constants/constant'
  import { DateUtils } from '@/helpers/DateUtils'
  import DateTimePickerRange from '../components/DateTimePickerRange.vue'

  // const currentDate = moment().format(DATE_TIME_FORMAT.YYYY_MM_DDHHmm)
  const TAB_NAME = {
    SEARCH: 'search',
    RESERVATION_LIST: 'reservationList',
    HISTORY_LIST: 'historyList',
  }
  const ROW = 5
  // 検索条件の初期状態
  const defaultVehicleFilter = {
    canReserveFlg: false,
    ecocarType: [1, 2, 3], // エコカー種別全選択状態
    vehicleTypeGroupId: '',
    estimatedTravelDistance: '0',
    equipment: [],
    vehicleBodyType: '',
  }
  /* global google */
  export default {
    name: 'Home',
    components: {
      UsageHistoryDetail,
      Map,
      EvstationInfoMap,
      ReservationEdit,
      VehicleList,
      Loading,
      DateTimePickerRange,
    },
    created() {
      document.cookie = Config.FUNCTION_ID + this.screenId
      this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
      this.$store.commit(MUTATION_TYPES.SET_MY_STATION_PROCESSING, true)

      this.form = {
        ...this.currentQuery,
      }

      // 検索条件がURLに含まれていれば検索処理を行う
      if (this.$is.not.empty(this.currentQuery)) {
        this.submitSearch()
      }
    },
    async mounted() {
      await this.init()
    },
    data() {
      return {
        tabName: TAB_NAME,
        isModal: false,
        mapResults: {},
        selectEvstation: {},
        vehicleList: [],
        searchBox: {},
        inputElement: {},
        currentLocation: {},
        reservationList: [],
        reservationPage: 1,
        historyList: [],
        historyPage: 1,
        isShowModalHistoryDetail: false,
        historyHasNext: null,
        reservationHasNext: null,
        vehicleFilter: this.$_.cloneDeep(defaultVehicleFilter),
        vehicleFilterConfirm: this.$_.cloneDeep(defaultVehicleFilter),
        vehicleTypeList: [],
        vehicleBodyTypeList: [],
        equipmentList: [
          { value: 'ナビ', key: '1' },
          { value: 'スマホ充電', key: '2' },
          { value: '駐車アシスト', key: '3' },
          { value: 'ジュニアシート', key: '4' },
          { value: 'チャイルドシート', key: '5' },
          { value: '自動ブレーキ', key: '6' },
          { value: '4WD', key: '7' },
          { value: 'スタッドレス', key: '8' },
          { value: 'ドラレコ', key: '9' },
          { value: 'レーンキープ', key: '10' },
        ],
        estimatedTravelDistanceList: [
          { value: '指定なし', key: 0 },
          { value: '100km以上', key: 100 },
          { value: '200km以上', key: 200 },
          { value: '300km以上', key: 300 },
          { value: '400km以上', key: 400 },
        ],
        clusterMode: false,
        place: '',
        isOpenDatePicker: false,

        // 利用時間
        time: {
          startDate: null,
          endDate: null,
          startTime: null,
          endTime: null,
        },
        form: {
          // URL パラメータ用
          // ------------------------------------
          // ステーション検索条件
          lat: null,
          lng: null,
          zoom: null,
          location: '',

          // 車両絞り込み条件
          vehicleType: '',
          ecoType: '',

          // 画面制御
          tab: '', // search / reservationlist / historyList
          modalType: '', // reservation / history

          // 予約モーダルに使用するparam
          selectEvstationId: null,
          reservationId: null,
          selectVehicleId: null,

          // 利用履歴詳細
          historyReservationId: '',
          // ------------------------------------
        },

        favoriteStationList: [],
      }
    },
    methods: {
      changeDateTime(value) {
        this.time = value
        this.submitSearch(true)
      },
      disabledMinutes: (hour) => {
        if (hour === 17) {
          return this.makeRange(0, 29)
        }
        if (hour === 18) {
          return this.makeRange(31, 59)
        }
      },
      makeRange: (start, end) => {
        const result = []
        for (let i = start; i <= end; i++) {
          result.push(i)
        }
        return result
      },
      async init() {
        if (Auth.isLogin()) {
          const session = Auth.getCurrentUser()
          await sessionApi
            .start(session.username)
            .then(async (response) => {
              const user = this.$_.head(response.data)
              this.$store.commit(SESSION_MUTATION_TYPES.SET_VALUES, user)
            })
            .catch(async (e) => {
              await this.validationErrorHook(e)
              Auth.signout()
              this.go(Config.URL.LOGOUT_PATH)
            })
        } else {
          // 未ログイン時はログインページへ
          this.go(Config.URL.LOGOUT_PATH)
        }

        /* eslint-disable */
        this.vehicleTypeList = []
        let groupList = []
        // 車種グループプルダウン生成
        commonApi.findVehicleTypeGroupList(CARSHARE_CORP_ID).then((result) => {
          result.data.forEach((r) => {
            if (!groupList.includes(r.group)) {
              groupList.push(r.group)
            }
          })
          groupList.forEach((g) => {
            let l = []
            result.data.forEach((r) => {
              if (r.group === g) {
                l.push(r)
              }
            })
            this.vehicleTypeList.push({ label: g, options: l })
          })
        })

        codeApi
          .findAll({ codeKeys: 'vehicle_body_type' })
          .then((ret) => (this.vehicleBodyTypeList = ret.data[0].codeContainer))

        this.getFavoriteStation()

        if (!this.form.tab) this.form.tab = TAB_NAME.SEARCH

        // this.$refs.dateTimePickerRange.setDateCondition()

        if ((this.form.selectVehicleId && this.form.selectEvstationId) || this.form.reservationId) {
          // 予約ダイアログ表示
          this.isModal = true
        } else if (this.form.historyReservationId) {
          // 利用履歴詳細を表示
          this.isShowModalHistoryDetail = true
        }

        this.selectTab(this.form.tab)
      },
      setPlaceSearchInput() {
        let timer = setInterval(() => {
          if (window.google) {
            clearInterval(timer)
            this.inputElement = document.getElementById('pac-input')
            this.searchBox = new google.maps.places.SearchBox(this.inputElement)
            this.searchBox.addListener('places_changed', async () => {
              const places = await this.searchBox.getPlaces()
              if (places.length === 0) {
                return
              } else {
                this.currentLocation = places[0]
                this.form.lat = this.currentLocation.geometry.location.lat()
                this.form.lng = this.currentLocation.geometry.location.lng()
                this.$refs.map.moveMap(new google.maps.LatLng(this.form.lat, this.form.lng))
              }
            })
          }
        }, 500)
      },
      /**
       * MAP移動
       */
      async placesChanged() {
        const places = await this.searchBox.getPlaces()
        const place = document.getElementById('pac-input').value
        if ((!places || places.length === 0) && this.$_.isEmpty(place)) {
          return
        }

        if (!this.$_.isEmpty(place)) {
          // 入力した住所から位置情報を取得してMAP検索
          const geocoder = new google.maps.Geocoder()
          geocoder.geocode(
            {
              address: place,
              region: 'jp',
            },
            (results, status) => {
              if (status == google.maps.GeocoderStatus.OK) {
                this.currentLocation = results[0]
                this.form.lat = this.currentLocation.geometry.location.lat()
                this.form.lng = this.currentLocation.geometry.location.lng()
                this.$refs.map.moveMap(new google.maps.LatLng(this.form.lat, this.form.lng))
              }
            }
          )
        } else {
          // GoogleSearchBoxの結果からMAP検索
          this.currentLocation = places[0]
          this.form.lat = this.currentLocation.geometry.location.lat()
          this.form.lng = this.currentLocation.geometry.location.lng()
          this.$refs.map.moveMap(new google.maps.LatLng(this.form.lat, this.form.lng))
        }
      },
      moveMap(lat, lng) {
        this.$refs.map.moveMap(new google.maps.LatLng(lat, lng))
      },
      modalOpen() {
        this.isModal = true
        this.updateCurrentQuery()
      },
      modalClose() {
        this.isModal = false
        this.form.selectVehicleId = null
        this.form.reservationId = null
        this.updateCurrentQuery()
        // 入力フォームの保持をクリア
        this.$store.commit(FORM_MUTATION_TYPES.CLEAR_VALUES)
        this.reloadDateTime()
      },
      closeModalHistoryDetail() {
        this.isShowModalHistoryDetail = false
        this.form.historyReservationId = null
        this.updateCurrentQuery()
      },
      // 検索条件を更新して検索
      async submitSearch(conditions) {
        this.updateCurrentQuery()
        console.log(this.$map)
        // 同じ条件でマップ検索(日付変更した場合は選択中のステーションを保持して検索する)
        if (conditions) this.$refs.map.searchMap(this.currentQuery.selectEvstationId)
      },
      showDatePicker() {
        this.isOpenDatePicker = true
      },
      setVehicleId(vehicleIds) {
        this.vehicleList = vehicleIds
      },
      /** 予約変更 */
      editReservation(reservationId) {
        this.form.selectVehicleId = null
        // this.form.selectEvstationId = null;
        this.form.reservationId = reservationId
        this.modalOpen()
      },
      /** 予約キャンセル */
      async cancelReservation(reservationId) {
        this.$_confirm
          .confirmOpen('この予約を取り消しますか？', '予約の取り消し')
          .then(async () => {
            this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
            await reservationCancelApi
              .reservationCancel(reservationId)
              .then(() => {
                this.$_confirm.dialogOpen('予約をキャンセルしました。', '取り消し完了')
                // 予約一覧、履歴一覧を一旦クリアして予約一覧を再取得
                this.reservationList = []
                this.historyList = []
                this.processGetListReservation()
              })
              .catch(this.validationErrorHook)
              .finally(() => this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false))
          })
      },
      getFavoriteStation() {
        this.$store.commit(MUTATION_TYPES.SET_MY_STATION_PROCESSING, true)
        favoriteApi
          .findMyStation()
          .then((ret) => (this.favoriteStationList = ret.data[0].favoriteEvstationContainer))
          .catch(this.validationErrorHook)
          .finally(() => {
            this.$store.commit(MUTATION_TYPES.SET_MY_STATION_PROCESSING, false)
          })
      },
      goReservation(vehicleId, evstationId) {
        this.form.selectVehicleId = vehicleId
        this.form.selectEvstationId = evstationId
        this.form.reservationId = null
        // 入力フォームの保持をクリア(過去の入力値が残っている場合を考慮して)
        this.$store.commit(FORM_MUTATION_TYPES.CLEAR_VALUES)
        this.modalOpen()
      },
      setMapResult(conditions, mapResult, evstationId, clusterMode) {
        this.mapResults = mapResult
        this.form.selectEvstationId = evstationId
        this.form = Object.assign(this.form, conditions)
        this.updateCurrentQuery()
        this.clusterMode = clusterMode

        this.reloadDateTime()
      },
      setSelectEvstation(evstationId) {
        this.form.selectEvstationId = evstationId
        this.updateCurrentQuery()
      },
      // 日時が経過していたら開始日時を更新する
      reloadDateTime() {
        if (moment().isAfter(this.unionStartDateTime)) {
          const s = moment().add(15, 'm')
          this.time.startDate = s.format(DATE_TIME_FORMAT.YYYYMMDD)
          this.time.startTime = s.format(DateUtils.formatHHMM15Min())
        }
      },
      updateCurrentQuery() {
        const query = {
          path: this.currentPath,
          query: { ...this.currentQuery, ...this.form },
        }

        this.$router.push(query)
      },
      async selectTab(tabName) {
        this.form.tab = tabName
        if (this.form.tab == TAB_NAME.RESERVATION_LIST && this.reservationList.length === 0) {
          await this.processGetListReservation()
        } else if (this.form.tab == TAB_NAME.HISTORY_LIST && this.historyList.length === 0) {
          await this.processGetListUsageHistory()
        } else {
          this.setPlaceSearchInput()
        }

        this.updateCurrentQuery(this.form)
      },
      reservationMore() {
        this.reservationPage++
        this.processGetListReservation()
      },
      historyMore() {
        this.historyPage++
        this.processGetListUsageHistory()
      },
      async processGetListReservation() {
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
        const param = {
          pageSize: this.reservationPage,
          rows: ROW,
        }
        const res = await reservationApi
          .findList(param)
          .then((res) => {
            this.reservationList = this.reservationList.concat(res.data)
            this.reservationList.forEach((current) => {
              current.reservationStartDatetime = this.$filters.formatYYYYMMDDHHMM(
                moment(current.reservationStartDatetime)
              )
              current.reservationEndDatetime = this.$filters.formatYYYYMMDDHHMM(
                moment(current.reservationEndDatetime)
              )
            })
            this.reservationHasNext = res.totalPages > this.reservationPage
          })
          .catch((e) => {
            this.validationErrorHook(e)
          })
          .finally(() => {
            this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
          })
      },

      async processGetListUsageHistory() {
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
        const param = {
          memberId: this.session.memberId,
          /* eslint-disable */
          carshareCorpId: CARSHARE_CORP_ID,
          pageSize: ROW,
          page: this.historyPage,
        }
        const res = await usageHistoryApi.findList(param).catch((e) => {
          this.validationErrorHook(e)
        })
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
        this.historyList = this.historyList.concat(res.data[0].usageHistoryListContainer)
        this.historyList.forEach((current) => {
          current.reservationStartDatetime = this.$filters.formatYYYYMMDDHHMM(
            moment(current.reservationStartDatetime)
          )
          current.reservationEndDatetime = this.$filters.formatYYYYMMDDHHMM(
            moment(current.reservationEndDatetime)
          )

          if (current.publishStatus === 0 || current.evstationId === null) {
            // station private or deleted
            current.canReserveAgain = false
          } else {
            current.canReserveAgain = true
          }
        })
        this.historyHasNext = res.data[0].hasNext === 1
      },
      /** 検索条件設定 */
      setFilter() {
        if (!this.$_.isEqual(this.vehicleFilter, this.vehicleFilterConfirm)) {
          this.vehicleFilterConfirm = this.$_.cloneDeep(this.vehicleFilter)
        }
      },
      /** 検索条件リセット */
      clearFilter() {
        this.vehicleFilter = this.$_.cloneDeep(defaultVehicleFilter)
        if (!this.$_.isEqual(this.vehicleFilterConfirm, defaultVehicleFilter)) {
          this.vehicleFilterConfirm = this.$_.cloneDeep(defaultVehicleFilter)
        }
      },
      /**
       * Open the Usage History Details dialog
       */
      showModalHistoryDetail(reservationId) {
        this.isShowModalHistoryDetail = true
        this.form.historyReservationId = reservationId
        this.updateCurrentQuery()
      },
      async toggleMyStation(id, delFlg) {
        this.$store.commit(MUTATION_TYPES.SET_MY_STATION_PROCESSING, true)

        let _session = this.$_.cloneDeep(this.session)
        let fs = _session.favoriteStations ? _session.favoriteStations.split(',') : []
        let msg
        if (delFlg || fs.some((f) => f == id)) {
          fs = fs.filter((f) => f != id)
          msg = FAVORITE_MESSAGE.SUCCESS_MYSTATION_DELETE
        } else {
          fs.push(id)
          msg = FAVORITE_MESSAGE.SUCCESS_MYSTATION_INSERT
        }
        _session.favoriteStations = fs.join(',')
        this.$store.commit(SESSION_MUTATION_TYPES.SET_VALUES, _session)
        await favoriteApi
          .updateMyStation({ favoriteStations: _session.favoriteStations })
          .then(async () => {
            this.$toast.show(msg, {
              type: 'success',
              position: 'top-right',
              duration: 3000,
              fullWidth: true,
            })
          })
          .catch(this.validationErrorHook)
          .finally(() => this.$store.commit(MUTATION_TYPES.SET_MY_STATION_PROCESSING, false))
        await this.getFavoriteStation()
      },
      async toggleMyCar(id, delFlg) {
        this.$store.commit(MUTATION_TYPES.SET_VEHICLE_PROCESSING, true)
        let _session = this.$_.cloneDeep(this.session)
        let fs = _session.favoriteVehicles ? _session.favoriteVehicles.split(',') : []
        let msg
        if (delFlg || fs.some((f) => f == id)) {
          fs = fs.filter((f) => f != id)
          msg = FAVORITE_MESSAGE.SUCCESS_MYCAR_DELETE
        } else {
          fs.push(id)
          msg = FAVORITE_MESSAGE.SUCCESS_MYCAR_INSERT
        }
        _session.favoriteVehicles = fs.join(',')
        this.$store.commit(SESSION_MUTATION_TYPES.SET_VALUES, _session)
        await favoriteApi
          .updateMyCar({ favoriteVehicles: _session.favoriteVehicles })
          .then(() => {
            this.$toast.show(msg, {
              type: 'success',
              position: 'top-right',
              duration: 3000,
              fullWidth: true,
            })
          })
          .catch(this.validationErrorHook)
          .finally(() => this.$store.commit(MUTATION_TYPES.SET_VEHICLE_PROCESSING, false))
      },
    },
    computed: {
      screenId: () => 'TOP', // <--- 個別に定義
      evInfo() {
        return isEmptyObject(this.mapResults) ||
          isEmptyObject(this.mapResults.data) ||
          this.form.selectEvstationId == null
          ? {}
          : this.mapResults.data.find((d) => d.evstationId == this.form.selectEvstationId)
      },
      favoriteStationView() {
        return this.favoriteStationList.filter((f) => f.publishStatus != 0)
      },
      unionStartDateTime() {
        return isEmptyObject(this.time.startDate) || isEmptyObject(this.time.startTime)
          ? moment()
          : moment(this.time.startDate + ' ' + this.time.startTime)
      },
      unionEndDateTime() {
        return isEmptyObject(this.time.endDate) || isEmptyObject(this.time.endTime)
          ? moment()
          : moment(this.time.endDate + ' ' + this.time.endTime)
      },
    },
  }
</script>

<style scoped>
  .slide-up-enter-active {
    transition: 0.3s ease-in-out;
  }
  .slide-up-leave-active {
    transition: 0.3s ease-in-out;
  }

  .slide-up-leave-to {
    transform: translateY(-100%);
  }

  .slide-up-leave-to,
  .slide-up-enter-from {
    transform: translateY(100%);
  }
</style>
