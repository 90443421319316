<template>
  <div class="energy reserve_boxs">
    <div class="car_info_box">
      <div class="energy_info" :class="{ not_enough: necessaryDistance > possibleDistance }">
        <div v-if="vehicle.ecocarType == 1">
          <div class="energy_message" v-if="necessaryDistance <= possibleDistance">
            <p>
              今回の利用で途中充電は不要な見込みです。<br />ご利用開始時には予想走行距離分の充電は確保されます。
            </p>
          </div>
          <div class="energy_message not_enough" v-if="necessaryDistance > possibleDistance">
            <p>ご利用途中で充電が必要になる可能性があります。</p>
          </div>
          <div
            class="energy_graph"
            data-necessary-energy="60"
            data-necessary-distance="120"
            data-possible-energy="80"
            data-possible-distance="160"
          >
            <div class="poss_nrg">
              ご利用開始時点で<span
                ><strong>{{ possibleDistance }}</strong
                >km</span
              >走行できる見込みです。
            </div>
            <div class="energy_graph_body">
              <div class="nrg_ttl">充電残量</div>
              <div class="ntg_right">
                <div class="nrg_empty"></div>
                <div class="nrg_fig">
                  <div class="curpos" :style="`left: ${possibleEnergy}%;`"></div>
                  <div class="nrg_bars">
                    <div v-for="(g, index) in graph" :key="index" :class="g"></div>
                  </div>
                  <div class="goal" :style="`left: ${necessaryEnergy}%;`"></div>
                </div>
                <div class="nrg_full"></div>
              </div>
            </div>
            <div class="nece_nrg">
              あなたの予想走行距離は<span
                ><strong>{{ necessaryDistance }}</strong
                >km</span
              >となります。
            </div>
          </div>
        </div>

        <div class="ratiobox" :class="{'no_charge':vehicle.ecocarType != 1}">
          <ul>
            <li :class="'renewable_energy_ratio ratio_' + reEnergyRatioClass">
              <div class="ratio_wrap">
                <div class="ratio_wrap_right">
                  <div class="ratio_ttl">
                    再エネ利用率：<span
                      ><strong class="roboto">{{ reEnergyRatio }}</strong
                      >%</span
                    >
                  </div>
                  <div class="ratio_link">
                    <a :href="corpParam.renewableEnergyRatioUrl" target="_blank" class="icon_help"
                      ><img
                        src="img/icon_help.svg"
                        alt="再エネ利用率とは"
                        title="再エネ利用率とは"
                      /><span>再エネ利用率とは</span></a
                    >
                  </div>
                </div>
              </div>
            </li>
            <li class="car_clearness" :class="'rank_' + vehicle.cleaningEvaluationInside">
              <div class="ratio_wrap">
                <div class="ratio_wrap_right">
                  <div class="ratio_ttl">
                    直近清掃評価：<span>車体</span
                    ><span
                      class="car_clearness_d"
                      :class="'rank_' + vehicle.cleaningEvaluationBody"
                    ></span>
                    <span>車内</span
                    ><span
                      class="car_clearness_d"
                      :class="'rank_' + vehicle.cleaningEvaluationInside"
                    ></span>
                  </div>
                  <div class="ratio_ttl sensya">
                    洗車情報：<span class="ratio_coment"
                      >{{ vehicle.dayBeforeCarWash }}日前に洗車済み</span
                    >
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="bikou">
            <p>備考：<span v-html="vehicle.publishRemarks"></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'VehicleEnergyGraph',
    props: {
      vehicle: Object,

      reEnergyRatio: Number,
      reEnergyRatioClass: String,

      startTime: String,
      endTime: String,
    },
    components: {},
    data() {
      return {
        graph: [
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
          'd',
        ],
        necessaryEnergy: 0,
        possibleEnergy: 0,
        necessaryDistance: 0,
        possibleDistance: 0,
      }
    },
    created() {},
    mounted() {
      this.calDataSocChart()
    },
    methods: {
      /**
       * Prepare data to draw chart SOC
       * Calculate: necessaryDistance, necessaryEnergy, possibleDistance, possibleEnergy
       */
      calDataSocChart() {
        const socPredictiveValue = this.calSocPredictiveValue(this.startTime)

        const timeReservation = moment(this.endTime).diff(moment(this.startTime), 'hours', true)
        // 利用時間により平均速度を切り替える
        const averageSpeed =
          timeReservation >= 12
            ? this.vehicle.averageSpeedMore12h
            : this.vehicle.averageSpeedLess12h
        this.necessaryDistance = Math.round(averageSpeed * timeReservation)

        const socMember = this.necessaryDistance / this.vehicle.mpgePrediction
        this.necessaryEnergy = (socMember / this.vehicle.fullCharge) * 100
        this.necessaryEnergy = this.necessaryEnergy < 100 ? this.necessaryEnergy : 100 // Max: 100%
        this.necessaryEnergy = this.necessaryEnergy > 0 ? this.necessaryEnergy : 0 // Min: 0%

        this.possibleEnergy = (socPredictiveValue / this.vehicle.fullCharge) * 100
        this.possibleEnergy = this.possibleEnergy < 100 ? this.possibleEnergy : 100 // Max: 100%
        this.possibleEnergy = this.possibleEnergy > 0 ? this.possibleEnergy : 0 // Min: 0%

        this.possibleDistance = Math.round(socPredictiveValue * this.vehicle.mpgeDrivingRange)
        this.necessaryDistance = this.necessaryDistance >= 0 ? this.necessaryDistance : '-'

        if (this.necessaryEnergy <= this.possibleEnergy) {
          for (let i = 0; i < this.graph.length; i++) {
            if (i < this.necessaryEnergy / 5) {
              this.graph[i] = 'g'
            } else if (i < this.possibleEnergy / 5) {
              this.graph[i] = 'b'
            } else {
              this.graph[i] = 'd'
            }
          }
        } else {
          for (let i = 0; i < this.graph.length; i++) {
            if (i < this.possibleEnergy / 5) {
              this.graph[i] = 'g'
            } else if (i < this.necessaryEnergy / 5) {
              this.graph[i] = 'y'
            } else {
              this.graph[i] = 'd'
            }
          }
        }
      },
      calSocPredictiveValue(startTime) {
        const results = this.vehicle.socPredictiveContainer.filter((soc) => {
          const predictDatetime = moment(soc.predictiveTargetDatetime)
          return moment(startTime).diff(predictDatetime, 'minutes') === 0
        })
        return results[0] ? results[0].socPredictiveValue : 0
      },
    },
    computed: {},
    watch: {
      startTime() {
        this.calDataSocChart()
      },
      endTime() {
        this.calDataSocChart()
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
