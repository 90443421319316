<template>
  <div class="reserve_left">
    <div class="mypage_list01">
      <!--車両情報-->
      <VehicleInfo
        :vehicle="reservationInfo"
        :status-reservation="statusReservation"
        :is-show-time-charge="false"
      ></VehicleInfo>
      <!--車両情報-->

      <!--充電残量-->
      <vehicle-energy-graph
        v-if="statusReservation <= 3"
        :necessary-distance="necessaryDistance"
        :possible-distance="possibleDistance"
        :necessary-energy="necessaryEnergy"
        :possible-energy="possibleEnergy"
        :ecocar-type="reservationInfo.ecocarType"
        :fuel-level="reservationInfo.fuelLevel"
      >
      </vehicle-energy-graph>
      <!--充電残量-->

      <!--ステーション情報-->
      <EvstationInfo :evInfo="reservationInfo" v-if="reservationInfo.evstationId"></EvstationInfo>
      <!--ステーション情報-->

      <!--ご利用プラン-->
      <div class="plan_area" v-if="reservationInfo.usagePattern === 1" id="planArea">
        <div class="blockttl">
          <span><img src="img/title_plan.svg" /></span>
          <p>ご利用プラン</p>
        </div>
        <div class="plan_wrap">
          <ul>
            <li>
              <div class="plan_box_wrap">
                <div class="plan_box" id="insurancePlanName">
                  <span><img src="img/icon_check.svg" v-if="reservationInfo.insuranceSubscribed == 1"/></span>{{ reservationInfo.insurancePlanName
                  }}<template v-if="reservationInfo.insuranceSubscribed == 1">加入あり</template
                  ><template v-else>加入なし</template>
                </div>
                <div class="plan_box" id="nightPackName">
                  <span><img src="img/icon_check.svg" v-if="reservationInfo.nightpackApplied == 1"/></span>{{ reservationInfo.nightPackName
                  }}<template v-if="reservationInfo.nightpackApplied == 1">適用あり</template
                  ><template v-else>適用なし</template>
                </div>
                <div class="plan_box" v-if="reservationInfo.promotionCode" id="promotionCode">
                  <span><img src="img/icon_check.svg" /></span>プロモーション利用あり
                </div>
              </div>
            </li>
            <li>
              <div class="plan_price">
                概算料金<strong class="roboto" id="approximationPrice">{{
                  $filters.formatNumber(approximationPrice)
                }}</strong
                ><span>円</span>
              </div>
              <div class="price_atention">※充電料金のご負担は不要です。</div>
            </li>
          </ul>
        </div>
      </div>
      <!--ご利用プラン-->
      <!--追加運転者-->
      <div class="driver_area" v-if="reservationInfo.passengerEmailList?.length" id="anotherDriver">
        <div class="blockttl">
          <span><img src="img/title_driver.svg" /></span>
          <p>追加運転者</p>
        </div>
        <div class="driver_wrap">
          <p v-for="(email, index) in reservationInfo.passengerEmailList" :key="index">
            {{ email }}
          </p>
        </div>
      </div>
      <!--追加運転者-->
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import VehicleInfo from '@/components/VehicleInfo'
  import EvstationInfo from '@/components/EvstationInfo'
  import VehicleEnergyGraph from './VehicleEnergyGraph'

  export default {
    name: 'ReserveLeft',
    props: {
      statusReservation: null,
      reservationInfo: {},
    },
    components: {
      VehicleInfo,
      VehicleEnergyGraph,
      EvstationInfo,
    },
    computed: {
      approximationPrice() {
        return this.reservationInfo.estimatedTimeCharge +
          this.reservationInfo.estimatedNightpackCharge +
          this.reservationInfo.estimatedInsuranceCharge -
          this.reservationInfo.estimatedDiscount >
          0
          ? this.reservationInfo.estimatedTimeCharge +
              this.reservationInfo.estimatedNightpackCharge +
              this.reservationInfo.estimatedInsuranceCharge -
              this.reservationInfo.estimatedDiscount
          : 0
      },
    },
    data() {
      return {
        vehicle: {},
        evInfo: {},
        necessaryDistance: null,
        necessaryEnergy: null,
        possibleEnergy: null,
        possibleDistance: null,
      }
    },
    methods: {
      /**
       * Prepare data to draw chart SOC
       * Calculate: necessaryDistance, necessaryEnergy, possibleDistance, possibleEnergy
       */
      calDataSocChart() {
        const timeReservation = moment(this.reservationInfo.reservationEndDatetime).diff(
          moment(this.reservationInfo.reservationStartDatetime),
          'hours',
          true
        )
        this.necessaryDistance = Math.round(this.reservationInfo.averageSpeed * timeReservation)

        const socMember = this.necessaryDistance / this.reservationInfo.mpgePrediction
        this.necessaryEnergy = (socMember / this.reservationInfo.fullCharge) * 100
        this.necessaryEnergy = this.necessaryEnergy < 100 ? this.necessaryEnergy : 100 // Max: 100%
        this.necessaryEnergy = this.necessaryEnergy > 0 ? this.necessaryEnergy : 0 // Min: 0%

        this.possibleEnergy =
          (this.reservationInfo.socPredictiveValue / this.reservationInfo.fullCharge) * 100
        this.possibleEnergy = this.possibleEnergy < 100 ? this.possibleEnergy : 100 // Max: 100%
        this.possibleEnergy = this.possibleEnergy > 0 ? this.possibleEnergy : 0 // Min: 0%

        this.possibleDistance = Math.round(
          this.reservationInfo.socPredictiveValue * this.reservationInfo.mpgeDrivingRange
        )
        this.necessaryDistance = this.necessaryDistance >= 0 ? this.necessaryDistance : '-'
      },
    },
    created() {
      this.calDataSocChart()
    },
  }
</script>

<style scoped></style>
