<template>
  <div class="container">
    <loading
      :active="vehicle_processing || map_processing"
      :loader="'dots'"
      :color="'#007ecb'"
      :can-cancel="true"
      :is-full-page="false"
    />
    <div class="boooking_cont_no-car" style="display: block" v-show="isCluster">
      <div class="no_car">
        <div class="nonstation_coment">
          <p>地図の表示エリアが広すぎます。地図を拡大してください。</p>
        </div>
      </div>
    </div>
    <div class="boooking_cont_no-car" style="display: block" v-show="!isCluster && isEmptyStation">
      <div class="no_car">
        <div class="nonstation_coment">
          <p>この周辺にはステーションがありません</p>
        </div>
      </div>
    </div>
    <div class="booking-cont" v-show="!isCluster && !isEmptyStation">
      <div class="booking-cont-main">
        <div class="stickey-ttl on">
          <h1 class="booking-cont-main-ttl" v-if="evstationRedis">
            <img src="img/car-b.svg" alt="車両一覧" />
            {{ evstationRedis.evstationName }}の車両一覧
            <span>(全</span>
            <b v-if="evstationRedis.vehicleContainer">{{ viewListCount }}</b>
            <span>台)</span>
          </h1>
          <button class="viewchange" @click="changeView">
            <img src="img/listview.svg" />
            <span>{{ viewType == 1 ? 'リストビューに切替える' : 'メインビューに切替える' }}</span>
          </button>
          <ul class="booking-cont-main-head" v-show="viewType == 1">
            <li>
              <img src="img/bookingdate.svg" alt="予約状況の日付を変更" />予約状況の日付を変更
            </li>
            <li>
              <dateSlider
                :dateList="dateList"
                v-if="dateList"
                @changeDate="changeDate"
              ></dateSlider>
            </li>
            <li>予約状況の凡例</li>
            <li>
              <p class="type1"><img src="img/bookingtype1.svg" alt="予約可能" />予約可能</p>
              <p class="type2"><img src="img/bookingtype2.svg" alt="予約済み" />予約済み</p>
              <p class="type3"><img src="img/bookingtype3.svg" alt="充放電予約" />充放電予約</p>
              <p class="type4">
                <img src="img/bookingtype4.svg" alt="メンテナンス中" />メンテナンス中
              </p>
              <p class="type5"><img src="img/bookingtype5.svg" alt="利用時間外" />利用時間外</p>
              <p class="type6"><img src="img/bookingtype6.svg" alt="法人予約枠" />法人予約枠</p>
              <p class="type7" v-if="isReservationSpecific">
                <img
                  src="img/bookingtype7.svg"
                  alt="法人予約枠（特定会員向け）"
                />法人予約枠（特定会員向け）
              </p>
            </li>
          </ul>
        </div>
        <transition-group
          tag="div"
          name="list"
          class="booking-cont-main-child"
          v-show="viewType == 1"
        >
          <!--予約車両loop-->
          <ul
            v-for="vehicle in filterViewList"
            :key="vehicle.vehicleId"
            :class="{ reserved: !vehicle.canReserve }"
          >
            <li>
              <!-- {{evstationRedis}} -->
              <div class="booking-cont-main-child-ttl">
                <span class="label-newcar" v-if="vehicle.isNewCar == 'true'">NEWCAR</span>
                <!-- (1:EV、2:PHEV、3:ガソリン車) -->
                <span v-if="vehicle.ecocarType == 1" class="label-ev">EV</span>
                <span v-if="vehicle.ecocarType == 2" class="label-normal">PHEV</span>
                <span v-if="vehicle.ecocarType == 3" class="label-normal">ガソリン車</span>
                <h4 class="booking-cont-main-child-name">
                  {{ vehicle.vehicleName }}
                </h4>
                <p class="booking-cont-main-child-number">
                  {{ vehicle.vehicleNumber }}
                </p>
                <div v-if="vehicle.onboardUnitInstalled == 0" class="label-keytype">物理キー</div>
                <button
                  class="mycar-icon"
                  :class="{ off: !isFavoriteVehicle(vehicle.vehicleId) }"
                  @click.stop.prevent="toggleMyCar(vehicle.vehicleId)"
                ></button>
              </div>
              <div class="booking-cont-main-child-images">
                <div class="booking-cont-main-child-images-child">
                  <img :src="vehicle.vehicleImageUrl" class="visual" />
                  <img src="img/reserved.svg" class="reserved-label" />
                </div>
                <div class="booking-cont-main-child-price">
                  <span v-if="vehicle.usagePattern == 1">
                    <b>{{ vehicle.timeCharge }}</b>
                    <span class="scale8">円</span>/15
                    <span class="scale8">分</span>
                  </span>
                  <b>{{ vehicle.seatingCapacity }}</b
                  >人乗り
                </div>
              </div>
              <div class="booking-cont-main-child-option">
                <div class="booking-cont-main-child-option-child">
                  <p v-if="vehicle.ecocarType == 1">
                    利用時の走行可能距離：
                    <span>
                      {{ vehicle.drivingRange }}
                      <span class="scale9">km</span>
                    </span>
                  </p>

                  <p class="cleaness car_data_unit">
                    <span class="car_data_title">キレイさ：</span>
                    <span class="value" :class="getcleaness(vehicle.cleanliness)"></span>
                  </p>
                  <!-- ガソリン車は0% -->
                  <p>
                    再エネ利用率：
                    <span>
                      {{ vehicle.ecocarType == 1 ? renewableEnergy : 0 }}
                      <span class="scale9">%</span>
                    </span>
                  </p>
                  <a :href="corpParam.renewableEnergyRatioUrl" target="_blank">
                    <img src="img/que.svg" />
                  </a>
                </div>
                <div class="booking-cont-main-child-option-child2">
                  <p>カラー：{{ vehicle.vehicleColor }}</p>
                  <p v-if="vehicle.installedSection">駐車位置：{{ vehicle.installedSection }}</p>
                  <p>クラス：{{ vehicle.vehicleTypeClassName }}</p>
                </div>
                <div class="booking-cont-main-child-option-child3">
                  <p>車両タイプ：{{ vehicle.vehicleBodyTypeAlias }}</p>
                  <p class="label" v-for="alias in getVehicleusageAlias(vehicle)" :key="alias">
                    {{ alias }}
                  </p>
                  <!-- <p class="label">{{vehicle.usageAliasList}}</p> -->
                </div>
                <div class="booking-cont-main-child-option-child4">
                  <div class="option-icon">
                    <img
                      :src="
                        isEquipment(vehicle.equipmentList, '1')
                          ? 'img/icon_eq_navi.svg'
                          : 'img/icon_eq_navi_off.svg'
                      "
                    />
                  </div>
                  <div class="option-icon">
                    <img
                      :src="
                        isEquipment(vehicle.equipmentList, '2')
                          ? 'img/icon_eq_smapho.svg'
                          : 'img/icon_eq_smapho_off.svg'
                      "
                    />
                  </div>
                  <div class="option-icon">
                    <img
                      :src="
                        isEquipment(vehicle.equipmentList, '3')
                          ? 'img/icon_eq_parking.svg'
                          : 'img/icon_eq_parking_off.svg'
                      "
                    />
                  </div>
                  <div class="option-icon">
                    <img
                      :src="
                        isEquipment(vehicle.equipmentList, '4')
                          ? 'img/icon_eq_jrseat.svg'
                          : 'img/icon_eq_jrseat_off.svg'
                      "
                    />
                  </div>
                  <div class="option-icon">
                    <img
                      :src="
                        isEquipment(vehicle.equipmentList, '5')
                          ? 'img/icon_eq_childseat.svg'
                          : 'img/icon_eq_childseat_off.svg'
                      "
                    />
                  </div>
                  <div class="option-icon">
                    <img
                      :src="
                        isEquipment(vehicle.equipmentList, '6')
                          ? 'img/icon_eq_autobrake.svg'
                          : 'img/icon_eq_autobrake_off.svg'
                      "
                    />
                  </div>
                  <div class="option-icon">
                    <img
                      :src="
                        isEquipment(vehicle.equipmentList, '7')
                          ? 'img/icon_eq_4wd.svg'
                          : 'img/icon_eq_4wd_off.svg'
                      "
                    />
                  </div>
                  <div class="option-icon">
                    <img
                      :src="
                        isEquipment(vehicle.equipmentList, '8')
                          ? 'img/icon_eq_studless.svg'
                          : 'img/icon_eq_studless_off.svg'
                      "
                    />
                  </div>
                  <div class="option-icon">
                    <img
                      :src="
                        isEquipment(vehicle.equipmentList, '9')
                          ? 'img/icon_eq_rec.svg'
                          : 'img/icon_eq_rec_off.svg'
                      "
                    />
                  </div>
                  <div class="option-icon">
                    <img
                      :src="
                        isEquipment(vehicle.equipmentList, '10')
                          ? 'img/icon_eq_lanekeep.svg'
                          : 'img/icon_eq_lanekeep_off.svg'
                      "
                    />
                  </div>
                </div>
              </div>
            </li>
            <!--車両予約状況-->
            <li>
              <!-- {{vehicle.timeListAm}} -->
              <div class="rese_schedule_chart">
                <h3>車両予約状況</h3>
                <!-- period_4, cat4 = 利用時間外 -->
                <!-- 上段 -->
                <div class="chart_am">
                  <div class="time_am"></div>
                  <div class="oclock_am_bg">
                    <div
                      v-for="(v, index) in vehicle.vehicleReservation"
                      :key="index"
                      :class="v.textPropAm.cn"
                      :style="
                        'width:' + v.textPropAm.width + '%;' + 'left:' + v.textPropAm.left + '%;'
                      "
                      v-show="v.textPropAm && isToday(v)"
                    ></div>
                  </div>
                  <div class="oclock_am oclock cf">
                    <div
                      v-for="(n, index) in vehicle.timeListAm"
                      :key="index"
                      :class="n.time + 'o' + n.cn + getCat56AmClassName(vehicle, index)"
                    >
                      {{ n.time }}
                    </div>
                  </div>
                  <!-- 予約バー -->
                  <div
                    v-for="(v, index) in vehicle.vehicleReservation"
                    :key="index"
                    :class="`${v.barPropAm.cn} hastip`"
                    :style="`width:${v.barPropAm.width}%; left: ${v.barPropAm.left}%; z-index: ${v.displayPriority}`"
                    v-show="v.barPropAm && v.barPropAm.cn !== undefined && isToday(v)"
                  >
                    <div class="period_tooltip">
                      <template v-if="v.fullName">
                        <span class="icon-user"></span>{{ v.fullName }}
                        <template v-if="!isEmptyObject(v.belongDeptName)"
                          >{{ v.belongDeptName }}
                        </template>
                      </template>
                      <template v-else>
                        {{ v.categoryName }}
                      </template>
                      <br />{{ $filters.formatDateHHmm(v.startDatetime) }}
                      ~
                      {{ $filters.formatDateHHmm24(v.endDatetime) }}
                    </div>
                  </div>
                </div>
                <!-- 下段 -->
                <div class="chart_pm">
                  <div class="time_pm"></div>
                  <div class="oclock_pm_bg">
                    <div
                      v-for="(v, index) in vehicle.vehicleReservation"
                      :key="index"
                      :class="v.textPropPm.cn"
                      :style="
                        'width:' + v.textPropPm.width + '%;' + 'left:' + v.textPropPm.left + '%;'
                      "
                      v-show="v.textPropPm && isToday(v)"
                    ></div>
                  </div>
                  <div class="oclock_pm oclock cf">
                    <div
                      v-for="(n, index) of vehicle.timeListPm"
                      :key="index"
                      :class="n.time + 'o' + n.cn + getCat56PmClassName(vehicle, index)"
                    >
                      {{ n.time }}
                    </div>
                  </div>
                  <div
                    v-for="(v, index) in vehicle.vehicleReservation"
                    :key="index"
                    :class="`${v.barPropPm.cn} hastip`"
                    :style="`width:${v.barPropPm.width}%; left:${v.barPropPm.left}%; z-index: 1${v.displayPriority}`"
                    v-show="v.barPropPm && v.barPropPm.cn !== undefined && isToday(v)"
                  >
                    <div class="period_tooltip">
                      <template v-if="v.fullName">
                        <span class="icon-user"></span>{{ v.fullName }}
                        <template v-if="!isEmptyObject(v.belongDeptName)"
                          >{{ v.belongDeptName }}
                        </template>
                      </template>
                      <template v-else> {{ v.categoryName }} </template><br />{{
                        $filters.formatDateHHmm(v.startDatetime)
                      }}
                      ~
                      {{ $filters.formatDateHHmm24(v.endDatetime) }}
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="bookinglink"
                @click="openModal(vehicle.vehicleId, evstationRedis.evstationId)"
              >
                この車両を予約する
              </button>
            </li>
          </ul>

          <!--/予約車両loop-->
          <button
            class="moreopner"
            @click="loadMore()"
            v-if="viewListCount !== vehicleMainView.length"
          >
            さらに読み込む
            <img src="img/more.svg" />
          </button>
        </transition-group>

        <div class="booking-cont-main-child2" v-show="viewType == 2">
          <div id="scroll" class="scroll">
            <table class="listview head">
              <tr>
                <th>車両名</th>
                <th>エコカー種別</th>
                <th>利用時の<br />走行可能距離</th>
                <th>クラス</th>
                <th>料金</th>
                <th>車両タイプ</th>
                <th>乗車人数</th>
                <th>車両No.</th>
                <th>駐車位置</th>
                <th>
                  <p>ナビ</p>
                </th>
                <th>
                  <p>スマホ充電</p>
                </th>
                <th>
                  <p>駐車アシスト</p>
                </th>
                <th>
                  <p>ジュニア用</p>
                </th>
                <th>
                  <p>チャイルド用</p>
                </th>
                <th>
                  <p>自動ブレーキ</p>
                </th>
                <th>
                  <p>4WD</p>
                </th>
                <th>
                  <p>スタッドレス</p>
                </th>
                <th>
                  <p>ドラレコ</p>
                </th>
                <th>
                  <p>レーンキープ</p>
                </th>
                <th>
                  <p>予約</p>
                </th>
              </tr>
            </table>
          </div>
          <div id="scroll2" class="scroll2">
            <table class="listview body">
              <tr
                v-for="vehicle in filterViewList"
                :key="vehicle.id"
                :class="{ reserved: !vehicle.canReserve }"
              >
                <td>
                  <div>
                    <button
                      class="mycar-icon-list"
                      :class="{ off: !isFavoriteVehicle(vehicle.vehicleId) }"
                      @click.stop.prevent="toggleMyCar(vehicle.vehicleId)"
                    ></button>
                    <h4>{{ vehicle.vehicleName }}</h4>
                    <div v-if="vehicle.onboardUnitInstalled == 0" class="label-keytype">物理キー</div>
                    <p>
                      <span
                        class="label"
                        v-for="alias in getVehicleusageAlias(vehicle)"
                        :key="alias"
                        >{{ alias }}</span
                      >
                    </p>
                  </div>
                </td>
                <td v-if="vehicle.ecocarType == 1">EV</td>
                <td v-else-if="vehicle.ecocarType == 2">PHEV</td>
                <td v-else-if="vehicle.ecocarType == 3">ガソリン車</td>
                <td>{{ vehicle.drivingRange }}km</td>
                <td>{{ vehicle.vehicleTypeClassName }}</td>
                <td>
                  {{ vehicle.usagePattern == 1 ? vehicle.timeCharge + '円' : '-' }}
                </td>
                <td>{{ vehicle.vehicleBodyTypeAlias }}</td>
                <td>{{ vehicle.seatingCapacity }}人</td>
                <td>{{ vehicle.vehicleNumber }}</td>
                <td>{{ vehicle.installedSection }}</td>
                <td>
                  {{ isEquipment(vehicle.equipmentList, '1') ? '○' : '-' }}
                </td>
                <td>
                  {{ isEquipment(vehicle.equipmentList, '2') ? '○' : '-' }}
                </td>
                <td>
                  {{ isEquipment(vehicle.equipmentList, '3') ? '○' : '-' }}
                </td>
                <td>
                  {{ isEquipment(vehicle.equipmentList, '4') ? '○' : '-' }}
                </td>
                <td>
                  {{ isEquipment(vehicle.equipmentList, '5') ? '○' : '-' }}
                </td>
                <td>
                  {{ isEquipment(vehicle.equipmentList, '6') ? '○' : '-' }}
                </td>
                <td>
                  {{ isEquipment(vehicle.equipmentList, '7') ? '○' : '-' }}
                </td>
                <td>
                  {{ isEquipment(vehicle.equipmentList, '8') ? '○' : '-' }}
                </td>
                <td>
                  {{ isEquipment(vehicle.equipmentList, '9') ? '○' : '-' }}
                </td>
                <td>
                  {{ isEquipment(vehicle.equipmentList, '10') ? '○' : '-' }}
                </td>
                <td>
                  <input
                    type="submit"
                    @click="openModal(vehicle.vehicleId, evstationRedis.evstationId)"
                    value="予約"
                  />
                </td>
              </tr>
            </table>
          </div>
          <button
            class="moreopner"
            @click="loadMore()"
            v-if="viewListCount !== vehicleDetailView.length"
          >
            さらに読み込む
            <img src="img/more.svg" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { MUTATION_TYPES } from '@/store'
  import { vehicleApi } from '@/module/Api'
  import Loading from 'vue-loading-overlay'
  import DateSlider from '@/components/DateSlider.vue'
  import { getDrivingRange, getRenewableEnergyRatio } from '@/helpers/mapUtils'
  import { Utils } from '@/helpers/Utils'
  import { isEmptyObject } from '@/helpers/validators'
  import moment from 'moment'
  import { DATE_TIME_FORMAT, TIME_AM, TIME_PM } from '@/constants/constant'

  const MAIN_VIEW_PAGES = 10
  const LIST_VIEW_PAGES = 30

  export default {
    name: 'VehicleList',
    components: {
      Loading,
      DateSlider,
    },
    props: {
      evstationRedis: Object, // map検索結果のステーション以下を受け取る
      startDateTime: String,
      vehicleFilter: Object, // 車両絞り込み
      isCluster: Boolean,
    },
    data() {
      return {
        vehicleList: [], // RedisとDBの結果をマージしたリスト（表示用）
        vehicleMainView: [],
        vehicleMainPages: 1,
        vehicleDetailView: [],
        vehicleDetailPages: 1,
        dateList: [],
        selectDateIndex: 0,
        renewableEnergy: 0,
        chargeUnit: {},
        viewType: 1, // 1:メインビュー、2:リストビュー
        isReservationSpecific: false, // 特定会員法人枠凡例の表示判定用
        favoriteVehicles: [],
      }
    },
    created() {},
    async mounted() {
      for (let i = 0; i < 15; i++) {
        this.dateList.push(moment().add(i, 'day'))
      }
      this.refresh()

      // 横スクロールをヘッダーと同期
      const s1 = document.getElementById('scroll')
      const s2 = document.getElementById('scroll2')
      if (s1 && s2) {
        s1.addEventListener('scroll', () => {
          s2.scrollLeft = s1.scrollLeft
        })
        s2.addEventListener('scroll', () => {
          s1.scrollLeft = s2.scrollLeft
        })
      }
    },
    methods: {
      openModal(vehicleId, evstationId) {
        this.$emit('goReservation', vehicleId, evstationId)
      },
      async refresh() {
        this.$store.commit(MUTATION_TYPES.SET_VEHICLE_PROCESSING, true)

        this.vehicleList = this.evstationRedis.vehicleContainer
        this.vehicleMainView = []
        this.vehicleDetailView = []
        this.vehicleDetailPages = 1
        ;(this.vehicleMainPages = 1), this.setView()

        // 再エネ利用率取得
        this.renewableEnergy = isEmptyObject(this.evstationRedis)
          ? {}
          : getRenewableEnergyRatio(
              this.evstationRedis.renewableEnergyRatioMapContainer,
              this.startDateTime
            )

        // お気に入り車両を保持
        this.favoriteVehicles = this.session.favoriteVehicles
          ? this.session.favoriteVehicles.split(',')
          : []

        this.$store.commit(MUTATION_TYPES.SET_VEHICLE_PROCESSING, false)
      },
      loadMore() {
        this.viewType === 1 ? this.vehicleMainPages++ : this.vehicleDetailPages++
        this.setView()
      },
      async setView() {
        const rows = this.getRows
        const pages = this.getPages
        let viewList = this.getViewList

        for (let i = (pages - 1) * rows; i < rows * pages; i++) {
          const tmpList = this.convertFilter(this.vehicleList)
          if (tmpList && tmpList.length > i) {
            viewList.push(tmpList[i])

            this.getVehicleInfo(i)
          }
        }
      },

      async getVehicleInfo(i) {
        const v = this.getViewList[i]
        // deep copy
        v.timeListAm = this.$_.cloneDeep(TIME_AM)
        v.timeListPm = this.$_.cloneDeep(TIME_PM)

        // 2週間分の法人枠リストをセット
        v.cat56AmList = []
        v.cat56PmList = []
        this.dateList.forEach((d) => {
          v.cat56AmList.push({
            date: d.format(DATE_TIME_FORMAT.YYYYMMDD),
            list: this.$_.cloneDeep(TIME_AM),
          })
          v.cat56PmList.push({
            date: d.format(DATE_TIME_FORMAT.YYYYMMDD),
            list: this.$_.cloneDeep(TIME_PM),
          })
        })
        v.drivingRange = getDrivingRange(v, this.startDateTime) // 走行可能距離計算

        // 予約状況はメインビューの場合のみ取得する
        if (this.viewType === 1) {
          const rev = await this.findReserve(v.vehicleId)

          await Utils.convertReservationList(rev)
          v.vehicleReservation = rev
          await v.vehicleReservation.forEach((r) => {
            r = Utils.getScheduleChartProp(r, v)
            if (r.category === 6) this.isReservationSpecific = true
          })
        }
      },
      /**
       * 時間料金取得
       */
      getTimeCharge(vehicleTypeClass) {
        const charge = this.chargeUnit.find((c) => c.vehicleTypeClass == vehicleTypeClass)
        return charge == null ? 0 : charge.timeCharge
      },

      async findById(id) {
        const vehicle = await vehicleApi.findById(id)
        return vehicle.data[0]
      },

      /**
       * 車両に紐づく予約取得
       */
      async findReserve(vehicleId) {
        const param = {
          vehicleId: vehicleId,
        }

        let reserve = await vehicleApi.getVehicleReserve(param)

        // 終日利用可以外の場合は利用不可時間をセット
        if (this.evstationRedis.allDayReservable == 0) {
          if (
            moment(this.evstationRedis.reservableStartTime, 'HH:mm') <
            moment(this.evstationRedis.reservableEndTime, 'HH:mm')
          ) {
            const stationStartReserve = {
              category: 4,
              startDatetime: '00:00:00',
              endDatetime: this.evstationRedis.reservableStartTime,
            }
            reserve.data.push(stationStartReserve)
            const stationEndReserve = {
              category: 4,
              startDatetime: this.evstationRedis.reservableEndTime,
              endDatetime: '24:00:00',
            }
            reserve.data.push(stationEndReserve)
          } else {
            const stationReserve = {
              category: 4,
              startDatetime: this.evstationRedis.reservableStartTime,
              endDatetime: this.evstationRedis.reservableEndTime,
            }
            reserve.data.push(stationReserve)
          }
        }

        //  set tooltip
        reserve.data.forEach((r) => {
          if (isEmptyObject(r.fullName)) {
            r.categoryName = Utils.getCategoryName(r.category)
          }
        })

        return reserve.data
      },
      /**
       * 連結した車両Noを取得
       */
      getVehicleNumber(vehicle) {
        if (vehicle.vinPlace) {
          return (
            vehicle.vinPlace +
            ' ' +
            vehicle.vinClassNumber +
            ' ' +
            vehicle.vinKana +
            ' ' +
            vehicle.vinSeriesNumber
          )
        }
        return ''
      },
      /**
       * キレイさ判定
       */
      getcleaness(cleanliness) {
        switch (cleanliness) {
          case 'S':
            return 'cleaness_3'
          case 'A':
            return 'cleaness_2'
          case 'B':
            return 'cleaness_1'
          default:
            return 'cleaness_3'
        }
      },
      /**
       * 表示切り替え
       */
      changeView() {
        this.viewType == 1 ? (this.viewType = 2) : (this.viewType = 1)
        // 表示切り替え時に初回データを読み込む
        if (this.getViewList.length === 0) {
          this.setView()
        }
      },

      isEquipment(equipmentList, equipmentCode) {
        return isEmptyObject(equipmentList)
          ? false
          : equipmentList.split(',').includes(equipmentCode)
      },
      vehicleReservation() {},
      changeDate(date) {
        this.selectDateIndex = date
      },
      isToday(reserve) {
        // 24:00が翌日判定されるためendDateTimeは1分マイナスして評価する
        const startDate = moment(reserve.startDatetime).format('YYYY-MM-DD')
        const endDate = moment(reserve.endDatetime).subtract(1, 'minutes').format('YYYY-MM-DD')

        // ステーション利用不可時間はhhmmss形式で取得されるのでInvalid dateも描画対象
        return (
          (startDate == 'Invalid date' && endDate == 'Invalid date') ||
          moment(this.selectDate).format('YYYY-MM-DD') == startDate ||
          moment(this.selectDate).format('YYYY-MM-DD') == endDate
        )
      },

      /**
       * 日付、時間に該当する法人枠・特定会員法人枠表示用のクラス名を返す
       */
      getCat56AmClassName(vehicle, index) {
        return vehicle.cat56AmList.find((d) => moment(d.date).isSame(this.selectDate, 'day')).list[
          index
        ].cn
      },
      getCat56PmClassName(vehicle, index) {
        return vehicle.cat56PmList.find((d) => moment(d.date).isSame(this.selectDate, 'day')).list[
          index
        ].cn
      },
      getVehicleusageAlias(vehicle) {
        return vehicle.usageAliasList ? vehicle.usageAliasList.split(',') : []
      },
      convertFilter(l) {
        if (l && l.length > 0) {
          // ソート順
          // 1.エコカー種別の昇順（EV→PHEV→ガソリン車）
          // 2.予約可能な車両を優先
          // 3.想定走行可能距離の降順
          l.sort((a, b) => {
            if (a.ecocarType < b.ecocarType) {
              return -1
            } else if (a.ecocarType > b.ecocarType) {
              return 1
            }
            if (a.canReserve && !b.canReserve) {
              return -1
            } else if (!a.canReserve && b.canReserve) {
              return 1
            } else if (a.canReserve && b.canReserve) {
              return b.drivingRange - a.drivingRange
            } else {
              return b.drivingRange - a.drivingRange
            }
          })

          // お気に入り車両を先頭に表示
          l.sort((a, b) => {
            if (
              !this.favoriteVehicles.includes(String(a.vehicleId)) &&
              this.favoriteVehicles.includes(String(b.vehicleId))
            ) {
              return 1
            } else if (
              this.favoriteVehicles.includes(String(a.vehicleId)) &&
              !this.favoriteVehicles.includes(String(b.vehicleId))
            ) {
              return -1
            }
          })

          // 車両絞り込み処理
          return l.filter((v) => {
            const vehicleEquipment = v.equipmentList ? v.equipmentList.split(',') : []
            return (
              (this.vehicleFilter.canReserveFlg == false ||
                (this.vehicleFilter.canReserveFlg == true && v.canReserve == true)) &&
              (this.$_.isEmpty(this.vehicleFilter.ecocarType) ||
                this.vehicleFilter.ecocarType.includes(v.ecocarType)) &&
              (this.vehicleFilter.vehicleTypeGroupId == '' ||
                v.vehicleTypeGroupId == this.vehicleFilter.vehicleTypeGroupId) &&
              (this.vehicleFilter.estimatedTravelDistance == 0 ||
                v.drivingRange >= this.vehicleFilter.estimatedTravelDistance) &&
              (this.vehicleFilter.equipment.length == 0 ||
                this.vehicleFilter.equipment.every((eq) => vehicleEquipment.includes(eq))) &&
              (this.vehicleFilter.vehicleBodyType == '' ||
                this.vehicleFilter.vehicleBodyType == v.vehicleBodyTypeAlias)
            )
          })
        } else {
          return []
        }
      },
      // お気に入り車両更新
      toggleMyCar(id) {
        this.$emit('toggleMyCar', id)
      },
      isFavoriteVehicle(id) {
        const favoriteVehicles = this.$_.cloneDeep(this.session.favoriteVehicles)
        const fs = favoriteVehicles ? favoriteVehicles.split(',') : []
        return fs.some((f) => f == id)
      },
    },
    computed: {
      selectDate() {
        return this.dateList[this.selectDateIndex]
      },
      isEmptyStation() {
        return isEmptyObject(this.evstationRedis)
      },
      getViewList() {
        return this.viewType === 1 ? this.vehicleMainView : this.vehicleDetailView
      },
      filterViewList() {
        const l = this.getViewList
        return this.convertFilter(l)
      },
      viewListCount() {
        if (this.vehicleList && this.vehicleList.length > 0) {
          // 車両絞り込み処理
          return this.vehicleList.filter((v) => {
            const vehicleEquipment = v.equipmentList ? v.equipmentList.split(',') : []
            return (
              (this.vehicleFilter.canReserveFlg == false ||
                (this.vehicleFilter.canReserveFlg == true && v.canReserve == true)) &&
              (this.$_.isEmpty(this.vehicleFilter.ecocarType) ||
                this.vehicleFilter.ecocarType.includes(v.ecocarType)) &&
              (this.vehicleFilter.vehicleTypeGroupId == '' ||
                v.vehicleTypeGroupId == this.vehicleFilter.vehicleTypeGroupId) &&
              (this.vehicleFilter.estimatedTravelDistance == 0 ||
                v.drivingRange >= this.vehicleFilter.estimatedTravelDistance) &&
              (this.vehicleFilter.equipment.length == 0 ||
                this.vehicleFilter.equipment.every((eq) => vehicleEquipment.includes(eq))) &&
              (this.vehicleFilter.vehicleBodyType == '' ||
                this.vehicleFilter.vehicleBodyType == v.vehicleBodyTypeAlias)
            )
          }).length
        } else {
          return 0
        }
      },
      getPages() {
        return this.viewType === 1 ? this.vehicleMainPages : this.vehicleDetailPages
      },
      getRows() {
        return this.viewType === 1 ? MAIN_VIEW_PAGES : LIST_VIEW_PAGES
      },
    },
    watch: {
      evstationRedis() {
        this.refresh()
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .scale9 {
    font-size: 10px;
    display: inline-block;
  }

  .list-enter-active {
    transition: all 0.5s ease;
  }

  .list-leave-active {
    transition: all 0.2s ease-in-out;
  }

  .list-enter-from {
    opacity: 0;
    transform: translateY(50px);
  }

  .list-leave-to {
    opacity: 0;
    transform: translateX(50px);
    position: absolute;
    background: white;
  }
</style>
