<template>
  <div class="station_area" id="stationArea">
    <div class="blockttl">
      <span><img src="img/title_station.svg" /></span>
      <p>ステーション情報</p>
    </div>
    <div class="station-info">
      <h3 class="station-name">{{ evInfo.evstationName }}</h3>
      <ul>
        <li>
          <div class="scroll">
            <p class="station-bicycle-info">
              <img src="img/icon_bicycle.svg" />駐車枠への駐車
              <br />
              自転車：{{ evInfo.bicycleAvailable === 1 ? '可' : '不可' }} 自家用車：{{
                evInfo.vehicleAvailable === 1 ? '可' : '不可'
              }}
            </p>
            <p class="station-time-info" v-if="evInfo.allDayReservable !== 1">
              <img src="img/icon_time.svg" />貸出・返却可能時間
              <br />
              {{ $filters.formatHHMM(evInfo.reservableStartTime) }} ～
              {{ $filters.formatHHMM(evInfo.reservableEndTime) }}
            </p>
            <p class="station-time-info" v-if="evInfo.allDayReservable === 1">
              <img src="img/icon_time.svg" />24時間可
            </p>
            <p class="station-traffic-info" v-if="evInfo.access">
              <img src="img/icon_traffic.svg" />
              <span v-html="$filters.convertBreakLine(evInfo.access)"></span>
            </p>
            <p class="station-caution-info" v-if="evInfo.notes">
              <img src="img/icon_caution.svg" />
              <span v-html="$filters.convertBreakLine(evInfo.notes)"></span>
            </p>
          </div>
          <div class="bolttype_wrap">
            <div class="bolttype" v-if="evInfo.powerPlantName">
              <img src="img/bolttype.svg" />このステーションの電気は{{
                evInfo.powerPlantName
              }}を利用しています。
            </div>
            <div class="bolttype-image_wrap">
              <div class="bolttype-image" v-if="evInfo.powerPlantName">
                <img
                  src="img/imagepickup.svg"
                  class="bolttype-imagepickup"
                  @click="isPowerPlantModal = true"
                />
                <img :src="evInfo.powerPlantImageUrl" />
              </div>
              <app-modal :isVisible="isPowerPlantModal" @close="isPowerPlantModal = false">
                <carousel :img-list="[evInfo.powerPlantImageUrl]" :isModal="true"></carousel>
              </app-modal>
            </div>
          </div>
        </li>
        <li>
          <div class="ev-caroucel">
            <carousel :img-list="evstationImages" :isModal="false"></carousel>
            <img src="img/imagepickup.svg" class="imagepickup" @click="isEvModal = true" />
          </div>
          <app-modal :isVisible="isEvModal" @close="isEvModal = false">
            <carousel :img-list="evstationImages" :isModal="true"></carousel>
          </app-modal>

          <main class="map_main cf">
            <section class="map_section">
              <div
                class="map"
                id="map3"
                style="height: 158px; position: relative; overflow: hidden"
              ></div>
              <div class="map_info">
                <div class="balloon balloon--top_left">
                  <p></p>
                </div>
                <div class="powerplant_info">
                  <div class="pp_fig" style="height: 0px"></div>
                  <p></p>
                </div>
              </div>

              <div class="sub_search">
                <div class="search_plus" @click="map.setZoom(map.getZoom() + 1)"></div>
                <div class="search_minus" @click="map.setZoom(map.getZoom() - 1)"></div>
              </div>
            </section>
          </main>
          <div class="maplink">
            <a href="#" @click.stop.prevent="openMapToEvstation()">大きな地図で見る</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import Carousel from '@/components/Carousel.vue'
  import { MAP_OPTION } from '@/conf/MapConfig'

  /* global google */
  export default {
    name: 'EvstationInfo',
    props: {
      evInfo: {},
    },
    components: {
      Carousel,
    },
    data() {
      return {
        isEvModal: false,
        isPowerPlantModal: false,
        map: '',
      }
    },
    created() {},
    mounted() {
      this.initStationMap()
    },
    methods: {
      openMapToEvstation() {
        const url = `https://www.google.com/maps?q=${this.evInfo.latitude},${this.evInfo.longitude}`
        // const url = `http://maps.google.com?saddr=Current+Location&daddr=${this.evInfo.latitude},${this.evInfo.longitude}&directionsmode=driving`
        window.open(url, '_blank', 'location=yes')
      },
      initStationMap() {
        const latlng = new google.maps.LatLng(
          this.evInfo.latitude,
          this.evInfo.longitude
        )
        // console.log("initMap", latlng);
        this.center = { lat: latlng.lat(), lng: latlng.lng() }

        let mapCondition = MAP_OPTION
        mapCondition = {
          ...MAP_OPTION,
          center: latlng,
          keyboardShortcuts: false,
        }

        this.map = new google.maps.Map(document.getElementById('map3'), mapCondition)
        // googlemapの吹き出しを表示しない
        google.maps.event.addListener(this.map, 'click', (event) => {
          if (event.placeId) {
            event.stop()
          }
        })

        new google.maps.Marker({
          position: latlng,
          icon: {
            url: '/img/mappin.svg',
            scaledSize: new google.maps.Size(45, 64),
            anchor: new google.maps.Point(28, 51),
          },
          map: this.map,
          zIndex: 2,
        })
      },
    },
    computed: {
      evstationImages() {
        let images = []
        if (this.evInfo.evstationImage1Url) images.push(this.evInfo.evstationImage1Url)
        if (this.evInfo.evstationImage2Url) images.push(this.evInfo.evstationImage2Url)
        if (this.evInfo.evstationImage3Url) images.push(this.evInfo.evstationImage3Url)
        if (this.evInfo.evstationImage4Url) images.push(this.evInfo.evstationImage4Url)
        if (this.evInfo.evstationImage5Url) images.push(this.evInfo.evstationImage5Url)
        return images
      },
    },
    watch: {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
