<template>
  <div class="password errorpage">
    <form>
      <main class="cf password1">
        <section class="common_section reminder">
          <h2>システムメンテナンスのお知らせ</h2>
          <div class="nobox">
            <p class="body_text">
              いつもご利用いただき、ありがとうございます。<br />
              現在サービス向上のため、システムメンテナンスを実施しております。<br/>
              ご不便をおかけいたしますが、メンテナンス終了までしばらくお待ち下さい。
            </p>
          </div>
        </section>
      </main>
    </form>
  </div>

</template>
<script>
  export default {
    components: {},
    created() {},
    mounted() {},
    data() {
      return {}
    },
    methods: {},
  }
</script>
<style scoped></style>
