<template>
  <div>
    <loading
      :active="processing"
      :loader="'dots'"
      :color="'#007ecb'"
      :can-cancel="true"
      :is-full-page="true"
    />
    <main class="login_main cf">
      <section class="login_section" v-if="corpParamContents.COMM_001">
        <Form @submit="onSubmit" :validation-schema="loginSchema" v-slot="{ errors }">
          <div class="login_form">
            <div class="login_logo">
              <img :src="corpParamContents.COMM_001.images.logo" alt />
            </div>
            <div class="login_label"><span>LOGIN</span>ログイン</div>

            <div class="login_input">
              <Field
                placeholder="メールアドレス"
                type="text"
                name="email"
                v-model="loginForm.email"
                :class="[
                  'maxw login_email',
                  {
                    err: errors.email,
                  },
                ]"
                label="メールアドレス"
                max-lenth="128"
                id="email"
              />
              <ErrorMessage name="email" class="err_msg" id="email_err_msg" />
              <Field
                type="password"
                name="password"
                v-model="loginForm.password"
                placeholder="パスワード"
                :class="[
                  'maxw login_password',
                  {
                    err: errors.password,
                  },
                ]"
                label="パスワード"
                id="password"
              />
              <ErrorMessage name="password" class="err_msg" id="password_err_msg" />
            </div>

            <div class="login_btn">
              <button class="btn_l btn_blue loginbtn" id="form-submit" :disabled="processing">
                ログイン
              </button>
            </div>
          </div>
          <div class="login_reminder">
            <a @click="goToResetPassword" id="resetPasswordLink">パスワードを忘れた方はこちら</a>
          </div>
          <div class="login_newmember">
            <a id="registerLink" @click="goToRegisterPage">新規会員登録はこちら</a>
          </div>
          <div class="login_rexev">
            <img
              :src="corpParamContents.COMM_001.images.corpLogo"
              :srcset="`${corpParamContents.COMM_001.images.corpLogo} 1x, ${corpParamContents.COMM_001.images.corpLogo2x} 2x`"
              alt
            />
          </div>
        </Form>
      </section>
    </main>
    <app-footer :isLoginFooter="true"></app-footer>
  </div>
</template>

<script>
  import Config from '@/conf/Config'
  import { MUTATION_TYPES } from '@/store'
  import { SESSION_MUTATION_TYPES } from '@/store/modules/session'
  import { LOGIN_MESSAGE } from '@/conf/Message'
  import { sessionApi, commonApi } from '@/module/Api'
  import ErrorTracking from '@/module/ErrorTracking'
  import Auth from '@/module/Auth'
  import { Field, Form, ErrorMessage } from 'vee-validate'
  import { MESSAGE } from '@/constants/constant.message'
  import { API_ERROR_CODE } from '@/constants/constant.api'
  import Loading from 'vue-loading-overlay'
  import { translateApi } from '@/module/Api'
  import moment from 'moment'

  export default {
    name: 'Login',
    components: {
      Loading,
      Field,
      Form,
      ErrorMessage,
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (from.path === Config.URL.LOGOUT_PATH) {
          vm.$toast.show(LOGIN_MESSAGE.LOGOUTED, {
            type: 'success',
            position: 'top-right',
            duration: 3000,
            fullWidth: true,
          })
        }
      })
    },

    data() {
      const loginSchema = {
        email: 'required|email|max:128',
        password: 'required',
      }

      return {
        loginSchema,
        cognitoUser: null,
        loginForm: {
          email: '',
          password: '',
        },
      }
    },

    created() {
      document.cookie = Config.FUNCTION_ID + this.screenId
      this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
      this.$store.commit(SESSION_MUTATION_TYPES.CLEAR_VALUES)
    },
    methods: {
      goToRegisterPage() {
        window.open(this.corpParam.memberRegistrationInformationUrl, '_blank')
      },
      async goToResetPassword() {
        await this.$router.push(Config.URL.RESET_PASSWORD)
      },
      onSubmit() {
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
        Auth.signin(this.loginForm.email, this.loginForm.password)
          .then(async (result) => {
            const refreshToken = result.getRefreshToken()
            this.$store.commit(MUTATION_TYPES.SET_REFRESH_TOKEN, refreshToken.token)
            const session = Auth.getCurrentUser()
            await sessionApi
              .start(session.username)
              .then(async (response) => {
                const user = this.$_.head(response.data)
                ErrorTracking.configureScope(user)
                document.cookie = `cid=${session.username}`
                this.$store.commit(SESSION_MUTATION_TYPES.SET_VALUES, user)

                const redirect = JSON.parse(sessionStorage.getItem(Config.REDIRECT_URL))

                if (redirect) {
                  sessionStorage.removeItem(Config.REDIRECT_URL)
                  await this.$router.push({
                    path: redirect.path,
                    query: redirect.query,
                  })
                } else {
                  await this.$router.push(Config.URL.ROOT)
                }

                this.$toast.show(LOGIN_MESSAGE.LOGIN, {
                  type: 'success',
                  position: 'top-right',
                  duration: 3000,
                  fullWidth: true,
                })

                // システムメンテナンス時間のチェック
                commonApi
                  .getSystemMaintenance()
                  .then(async (res) => {
                    if (res.data.length) {
                      let msg = `以下の期間にシステムメンテナンスを実施します。
                      以下の期間に跨る予約の登録・変更・延長は行えません。
                      また、以下の期間中は車両の返却も含め、全ての操作が行えなくなるためご注意下さい。
                      <br/>`
                      res.data.forEach((s) => {
                        msg +=
                          '<br/>・' +
                          this.$filters.formatMDddHHmm(moment(s.maintenanceStartDatetime)) +
                          ' ~ ' +
                          this.$filters.formatMDddHHmm(moment(s.maintenanceEndDatetime))
                      })
                      this.$_confirm.dialogOpen(msg, 'メンテナンスのお知らせ')
                    }
                  })
                  .catch((e) => {
                    this.validationErrorHook(e)
                  })
              })
              .catch((e) => {
                this.validationErrorHook(e)
              })
          })
          .catch(async (err) => {
            if (
              err.code === API_ERROR_CODE.EMAIL_NOT_REGISTERED ||
              err.code === API_ERROR_CODE.WRONG_PASSWORD
            ) {
              await this.$_confirm.dialogOpen(MESSAGE.LOGIN_FAIL)
            } else {
              // Cognitoから返却されたメッセージを日本語に訳して出力する
              const errMess = await translateApi.translateEnglish(err.message)
              await this.$_confirm.dialogOpen(errMess)
            }
          })
          .finally(() => {
            this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
          })
      },
    },
    computed: {
      screenId: () => 'LOGIN', // <--- 個別に定義
    },
  }
</script>

<style scoped></style>
