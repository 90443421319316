<template>
  <div class="term_start">
    <el-date-picker
      v-model="time.startDate"
      type="date"
      placeholder="日時を選択"
      :format="'MM/DD'"
      value-format="YYYY-MM-DD"
      :clearable="false"
      :editable="false"
      :disabled-date="disableStartDate"
      :class="'date_picker'"
      @change="change(time)"
    />
    <el-time-select
      v-model="time.startTime"
      placeholder="End time"
      start="00:00"
      step="00:15"
      end="23:45"
      :max-time="startDateCondition.maxTime"
      :min-time="startDateCondition.minTime"
      :editable="false"
      :clearable="false"
      :format="'HH:mm'"
      @change="change(time)"
    />
  </div>
  <div class="term_dash"></div>
  <div class="term_end">
    <el-date-picker
      v-model="time.endDate"
      type="date"
      placeholder="日時を選択"
      :format="'MM/DD'"
      value-format="YYYY-MM-DD"
      :clearable="false"
      :editable="false"
      :disabled-date="disableEndDate"
      @change="change(time)"
    />
    <el-time-select
      v-model="time.endTime"
      placeholder="End time"
      start="00:00"
      step="00:15"
      end="23:45"
      :max-time="endDateCondition.maxTime"
      :min-time="endDateCondition.minTime"
      :editable="false"
      :clearable="false"
      :format="'HH:mm'"
      @change="change(time)"
    />
  </div>
</template>

<script>
  // 予約開始日時 - 予約終了日時を選択するためのDateTimePicker
  // 現在日時から2週間以降を選択できない制御
  // 選択範囲が3日を超過しないよう制御
  import moment from 'moment'
  import { DATE_TIME_FORMAT } from '@/constants/constant'
  import { DateUtils } from '@/helpers/DateUtils'
  import { isEmptyObject } from '@/helpers/validators'

  const currentDate = moment().format(DATE_TIME_FORMAT.YYYY_MM_DDHHmm)
  export default {
    name: 'dateTimePickerRange',
    props: {
      value: {
        type: Object,
        required: true,
        default: () => ({
          startDate: null,
          endDate: null,
          startTime: null,
          endTime: null,
        }),
      },
    },
    emits: ['change'],
    data() {
      return {
        time: {
          startDate: null,
          endDate: null,
          startTime: null,
          endTime: null,
        },
        startDateCondition: {
          min: currentDate,
          max: moment().add(14, 'd').format(`${DATE_TIME_FORMAT.YYYYMMDD} 23:30`),
          minDate: moment().format(DATE_TIME_FORMAT.YYYYMMDD),
          maxDate: moment().add(14, 'd').format(DATE_TIME_FORMAT.YYYYMMDD),
          minTime: null,
          maxTime: null,
        },
        endDateCondition: {
          min: null,
          max: moment().add(14, 'd').format(`${DATE_TIME_FORMAT.YYYYMMDD} 23:45`),
          minDate: moment().format(DATE_TIME_FORMAT.YYYYMMDD),
          maxDate: moment().add(14, 'd').format(DATE_TIME_FORMAT.YYYYMMDD),
          minTime: null,
          maxTime: null,
        },
      }
    },
    mounted() {
      console.log(this.value)
      if (this.value.startDate == null) {
        const startDateTime = moment().add(15, 'm')
        this.time.startDate = startDateTime.format(DATE_TIME_FORMAT.YYYYMMDD)
        this.time.startTime = startDateTime.format(DateUtils.formatHHMM15Min())

        const endDateTime = moment().add(1, 'h').add(15, 'm')
        this.time.endDate = endDateTime.format(DATE_TIME_FORMAT.YYYYMMDD)
        this.time.endTime = endDateTime.format(DateUtils.formatHHMM15Min())
        
      }else {
        this.time = this.value
      }
      this.setDateCondition()
    },
    methods: {
      disableStartDate(value) {
        return !moment(value).isBetween(
          this.startDateCondition.minDate,
          this.startDateCondition.maxDate,
          null,
          '[]'
        )
      },
      disableEndDate(value) {
        return !moment(value).isBetween(
          this.endDateCondition.minDate,
          this.endDateCondition.maxDate,
          null,
          '[]'
        )
      },
      async change() {
        await this.setDateCondition()
      },
      async setDateCondition() {
        // 開始日時のMinを制御
        if (moment(this.time.startDate).isSame(this.startDateCondition.minDate, 'day')) {
          this.startDateCondition.minTime = moment().format(DATE_TIME_FORMAT.HHMM)
          if (this.unionStartDateTime.isBefore(moment())) {
            this.time.startTime = moment().format(DateUtils.formatHHMM15Min())
          }
        } else {
          this.startDateCondition.minTime = null
        }

        // 開始日時のMaxを制御
        if (moment(this.time.startDate).isSame(this.startDateCondition.max, 'day')) {
          this.startDateCondition.maxTime = moment(this.startDateCondition.max)
            .add(15, 'm')
            .format(DATE_TIME_FORMAT.HHMM)

          if (this.unionStartDateTime.isAfter(moment(this.startDateCondition.max))) {
            this.time.startTime = moment(this.startDateCondition.max).format(
              DATE_TIME_FORMAT.HHMM
            )
          }
        } else {
          this.startDateCondition.maxTime = null
        }

        // 終了日時のMinをセット
        this.endDateCondition.min = this.unionStartDateTime
          .clone()
          .add(15, 'm')
          .format('YYYY-MM-DD HH:mm')

        const endMin = moment(this.endDateCondition.min)
        this.endDateCondition.minDate = endMin.format(DATE_TIME_FORMAT.YYYYMMDD)

        if (moment(this.endDateCondition.minDate).isAfter(moment(this.time.endDate))) {
          this.time.endDate = this.endDateCondition.minDate
        }

        if (this.unionStartDateTime.isSame(this.unionEndDateTime)) {
          // 開始と終了が同じ日時の場合は終了時間を15分後に設定
          this.time.endTime = endMin.format(DATE_TIME_FORMAT.HHMM)
        } else if (this.unionStartDateTime.isAfter(this.unionEndDateTime)) {
          // 日付が逆転した場合
          this.time.endDate = endMin.format(DATE_TIME_FORMAT.YYYYMMDD)
          this.time.endTime = endMin.format(DATE_TIME_FORMAT.HHMM)
        }

        // 終了日時の最小時間をセット
        if (this.unionEndDateTime.isSame(this.endDateCondition.minDate, 'day')) {
          this.endDateCondition.minTime =
            endMin.format(DATE_TIME_FORMAT.HHMM) === '00:00'
              ? null
              : endMin.clone().subtract(15, 'm').format(DATE_TIME_FORMAT.HHMM)
        } else {
          this.endDateCondition.minTime = null
        }

        // 終了日時のmaxを設定
        if (this.unionStartDateTime.clone().add(3, 'd').isAfter(this.startDateCondition.max)) {
          // 現在日の15日後
          this.endDateCondition.max = moment()
            .add(14, 'd')
            .format(`${DATE_TIME_FORMAT.YYYYMMDD} 23:45`)
        } else {
          // 開始日時から3日間
          this.endDateCondition.max = this.unionStartDateTime
            .clone()
            .add(3, 'd')
            .format(`${DATE_TIME_FORMAT.YYYYMMDD} HH:mm`)
        }

        const endMax = moment(this.endDateCondition.max)
        this.endDateCondition.maxDate = endMax.format(DATE_TIME_FORMAT.YYYYMMDD)
        if (this.unionEndDateTime.isSame(this.endDateCondition.maxDate, 'day')) {
          this.endDateCondition.maxTime =
            endMax.format(DATE_TIME_FORMAT.HHMM) === '23:45'
              ? null
              : endMax.clone().add(15, 'm').format(DATE_TIME_FORMAT.HHMM)
        } else {
          this.endDateCondition.maxTime = null
        }

        // 終了日時のMAXの範囲内に収める
        if (this.unionEndDateTime.isAfter(endMax)) {
          this.time.endDate = this.endDateCondition.maxDate
          if (!isEmptyObject(this.endDateCondition.maxTime)) {
            this.time.endTime = endMax.format(DATE_TIME_FORMAT.HHMM)
          }
        }

        // 開始日時を変更して3日間以上空いた場合の終了時間調整
        if (this.unionStartDateTime.diff(this.unionEndDateTime, 'days') <= -3) {
          this.endDateCondition.maxTime =
            endMax.format(DATE_TIME_FORMAT.HHMM) === '23:45'
              ? null
              : endMax.clone().add(15, 'm').format(DATE_TIME_FORMAT.HHMM)
          this.time.endTime = endMax.format(DATE_TIME_FORMAT.HHMM)
        }

        this.$emit('change', this.time)
      },
    },
    computed: {
      unionStartDateTime() {
        return isEmptyObject(this.time.startDate) || isEmptyObject(this.time.startTime)
          ? moment()
          : moment(this.time.startDate + ' ' + this.time.startTime)
      },
      unionEndDateTime() {
        return isEmptyObject(this.time.endDate) || isEmptyObject(this.time.endTime)
          ? moment()
          : moment(this.time.endDate + ' ' + this.time.endTime)
      },
    },
    watch: {
      value(newValue) {
        if (newValue.startDate) {
          console.log(newValue)
          this.time = newValue
          this.setDateCondition()
        }
      },
    },
  }
</script>
