<template>
  <main class="map_main cf">
    <section class="map_section">
      <div id="map" class="map"></div>
      <div class="map_info">
        <div class="balloon balloon--top_left">
          <p></p>
        </div>
      </div>

      <div class="sub_search">
        <button class="search_plus" @click="$map.setZoom($map.getZoom() + 1)" />
        <button class="search_minus" @click="$map.setZoom($map.getZoom() - 1)" />
        <button class="search_pinpoint" @click="getMyPlace(true)" />
      </div>
    </section>
  </main>
</template>

<script>
  import { MAP_OPTION, MAP_ZOOM, MapMarkType } from '@/conf/MapConfig'
  import {
    getRenewableEnergyRatio,
    checkTypeEvStation,
    checkTimeReserveDuplicate,
    checkTimeReserveExistInListCorp,
    checkTimeEvStationOpen,
    getDrivingRange,
  } from '@/helpers/mapUtils'
  import { MUTATION_TYPES } from '@/store'
  import { searchMapApi } from '@/module/Api'
  import MarkerClusterer from '@google/markerclusterer'
  import jQuery from 'jquery'
  import moment from 'moment'

  const MIN_ZOOM_VAL = 12

  /* global google */
  export default {
    name: 'Map',
    props: {
      // とりあえず仮の初期値設定
      lat: {},
      lng: {},
      zoom: { default: MAP_ZOOM },
      startDateTime: String,
      endDateTime: String,
      // canReserveFlg: { type: Boolean, default: false },
      vehicleFilter: Object, // 車両絞り込み
    },
    data() {
      return {
        center: { lat: 35.26699295046353, lng: 138.15820022108042 },
        currentMarker: null,
        where: {
          lat: '',
          lng: '',
          zoom: '',
        },
        time: {
          startDateTime: '',
          endDateTime: '',
        },
        clusterOptions: {
          zoomOnClick: true,
          imagePath: '/img/cluster-maker/m',
        },
        markerCluster: null,
      }
    },
    created() {
      this.$store.commit(MUTATION_TYPES.SET_MAP_PROCESSING, false)
    },
    mounted() {
      if (this.lat && this.lng) {
        // GoogleMapの位置をセットする
        this.where.lat = this.lat
        this.where.lng = this.lng
        this.where.zoom = this.zoom
        this.time.startDateTime = this.startDateTime
        this.time.endDateTime = this.endDateTime
      }

      // GoogleMapが利用可能になるまで繰りかえす
      let timer = setInterval(() => {
        if (window.google) {
          clearInterval(timer)
          this.initMap()
        }
      }, 500)
    },

    methods: {
      async initMap() {
        const latlng = new google.maps.LatLng(this.lat, this.lng)
        this.center = { lat: latlng.lat(), lng: latlng.lng() }

        let mapCondition = MAP_OPTION
        const zoom = this.zoom ? this.zoom : MAP_ZOOM
        if (this.lat && this.lng) {
          mapCondition = {
            ...MAP_OPTION,
            center: latlng,
            keyboardShortcuts: false,
            zoom: parseInt(zoom),
          }
        }

        this.$map = new google.maps.Map(document.getElementById('map'), mapCondition)

        google.maps.event.addListener(this.$map, 'dragend', () => {
          if (!this.map_processing) {
            this.searchMap()
          }
        })

        google.maps.event.addListener(this.$map, 'dblclick', () => {})

        google.maps.event.addListener(this.$map, 'dragstart', () => {
          jQuery('.balloon').fadeOut(100)
        })

        google.maps.event.addListener(this.$map, 'zoom_changed', () => {
          if (!this.map_processing) {
            this.searchMap()
          }
        })

        // googlemapの吹き出しを表示しない
        google.maps.event.addListener(this.$map, 'click', (event) => {
          if (event.placeId) {
            event.stop()
          }
        })

        if (this.lat && this.lng) {
          // 現在地マーカーセットするだけ
          this.getMyPlace(false)
          await this.searchMap()
        } else {
          // 現在地マーカーセットしてmap移動
          this.getMyPlace(true)
        }
      },
      async clearMap() {
        this.$markers.forEach((marker) => {
          marker.setMap(null)
        })
        if (this.markerCluster) {
          await this.markerCluster.clearMarkers()
        }
        this.$markers = []
        jQuery('.balloon').fadeOut(100)
      },

      async addMarker(data) {
        // ピン配置
        const latlng = new google.maps.LatLng(data.location.y, data.location.x)
        const renewEnergy = getRenewableEnergyRatio(
          data.renewableEnergyRatioMapContainer,
          this.time.startDateTime
        )
        let isVehicleCanReserve = true
        let isMapActive = false
        let isFilterOk = false //全件車両絞り込み条件の対象外のステーション

        const stationOpen = checkTimeEvStationOpen(
          data,
          this.time.startDateTime,
          this.time.endDateTime
        )

        // 車両単位ループ
        data.vehicleContainer.forEach((v) => {
          let resultCheckTime = null
          isVehicleCanReserve = stationOpen
          // 予約チェック
          if (v.reservationContainer && v.reservationContainer.length > 0) {
            resultCheckTime = checkTimeReserveDuplicate(
              v.reservationContainer,
              this.time.startDateTime,
              this.time.endDateTime
            )
            if (resultCheckTime.conflict) {
              isVehicleCanReserve = false
            }
          }

          // 法人枠チェック
          if (
            isVehicleCanReserve &&
            v.corporationFrameContainer &&
            v.corporationFrameContainer.length > 0 &&
            checkTimeReserveExistInListCorp(
              v.corporationFrameContainer,
              this.startDateTime,
              this.endDateTime,
              this.session.corpId != null,
              this.session.memberId
            )
          ) {
            isVehicleCanReserve = false
          }

          // コミットSOCチェック
          if (
            v.ecocarType != 3 &&
            isVehicleCanReserve &&
            resultCheckTime &&
            resultCheckTime.reserveAfter
          ) {
            if (!v.isGuaranteedCommitSoc) {
              isVehicleCanReserve = false
            }
          }

          v.drivingRange = getDrivingRange(v, this.time.startDateTime)

          // 車両絞り込み条件に該当するもののみをマーカーセットする
          const vehicleEquipment = v.equipmentList ? v.equipmentList.split(',') : []
          if (
            (this.$_.isEmpty(this.vehicleFilter.ecocarType) ||
              this.vehicleFilter.ecocarType.includes(v.ecocarType)) &&
            (this.vehicleFilter.vehicleTypeGroupId == '' ||
              v.vehicleTypeGroupId == this.vehicleFilter.vehicleTypeGroupId) &&
            (this.vehicleFilter.estimatedTravelDistance == 0 ||
              v.drivingRange >= this.vehicleFilter.estimatedTravelDistance) &&
            (this.vehicleFilter.equipment.length == 0 ||
              this.vehicleFilter.equipment.every((eq) => vehicleEquipment.includes(eq))) &&
            (this.vehicleFilter.vehicleBodyType == '' ||
              this.vehicleFilter.vehicleBodyType == v.vehicleBodyTypeAlias)
          ) {
            // 車両絞り込み条件クリア
            // 1件でも予約可能な車両があればステーションは予約可能アイコン
            isFilterOk = true
          } else {
            // 絞り込み条件対象外
          }

          if (isVehicleCanReserve) {
            isMapActive = isVehicleCanReserve
          }
          v.canReserve = isVehicleCanReserve
        })

        // 予約不可車両を表示しないフラグONの場合はピンを立てない
        if (!isFilterOk || (this.vehicleFilter.canReserveFlg && !isMapActive)) {
          data.vehicleContainer = []
          return
        }

        const evType = checkTypeEvStation(isMapActive, renewEnergy, data.powerPlantId)

        const iconUrl = this.getIconUrl(evType)
        const marker = new google.maps.Marker({
          position: latlng,
          icon: {
            url: iconUrl,
            scaledSize:
              iconUrl == '/img/marker_1.png'
                ? new google.maps.Size(56, 50)
                : new google.maps.Size(45, 64),
            anchor:
              iconUrl == '/img/marker_1.png'
                ? new google.maps.Point(28, 25)
                : new google.maps.Point(28, 51),
          },
          map: this.$map,
          zIndex: 2,
        })

        const scale = Math.pow(2, this.$map.getZoom())
        const nw = new google.maps.LatLng(
          this.$map.getBounds().getNorthEast().lat(),
          this.$map.getBounds().getSouthWest().lng()
        )
        const worldCoordinateNW = this.$map.getProjection().fromLatLngToPoint(nw)
        const worldCoordinate = this.$map.getProjection().fromLatLngToPoint(marker.getPosition())
        const pixelOffset = new google.maps.Point(
          Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
          Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
        )

        marker.evstationName = data.evstationName
        marker.evstationId = data.evstationId
        marker.pixelOffset = pixelOffset

        marker.addListener('click', (p) => {
          if (this.$map.getZoom() <= MIN_ZOOM_VAL) {
            // 1件クラスターの場合は拡大表示
            this.$map.setZoom(MAP_ZOOM)
            let mapLatLng = new google.maps.LatLng(p.latLng.lat(), p.latLng.lng())
            this.moveMap(mapLatLng)
          } else {
            this.selectStation(marker)
          }
        })
        this.$markers.push(marker)
      },

      async searchMap(evstationId) {
        if (this.$map == null) return
        this.$store.commit(MUTATION_TYPES.SET_MAP_PROCESSING, true)
        this.clearMap()
        const center = this.$map.getCenter()
        this.where.lat = center.lat()
        this.where.lng = center.lng()
        this.where.zoom = this.$map.getZoom() ? this.$map.getZoom() : this.zoom
        const distance = String(
          this.calculateDistanceByZoomAndCenter(this.where.zoom, center.lat())
        )
        const isCluster = this.where.zoom <= MIN_ZOOM_VAL;
        // 日付が渡って来ない場合は初期値で検索する
        if (!this.time.startDateTime) {
          this.time.startDateTime = moment()
            .add(15, 'm')
            .format(
              'YYYY-MM-DD HH:' +
                ('00' + Math.floor(moment().add(15, 'm').minutes() / 15) * 15).slice(-2) +
                ':00'
            )
        }
        if (!this.time.endDateTime) {
          this.time.endDateTime = moment()
            .add(1, 'h')
            .add(15, 'm')
            .format(
              'YYYY-MM-DD HH:' +
                ('00' + Math.floor(moment().add(15, 'm').minutes() / 15) * 15).slice(-2) +
                ':00'
            )
        }

        await this.$nextTick(); // 予約日時の反映を待機
        const result = await searchMapApi
          .searchMap(
            this.where.lat,
            this.where.lng,
            distance,
            this.time.startDateTime,
            this.time.endDateTime,
            isCluster
          )
          .catch(async (e) => {
            await this.validationErrorHook(e)
            this.$store.commit(MUTATION_TYPES.SET_MAP_PROCESSING, false)
          })
        if (result && result.data.length > 0) {
          // ピン配置
          result.data.forEach(async (data) => {
            await this.addMarker(data)
          })

          // 中心のピンを選択 マーカークラスターが有効だと機能しない
          if (this.$map.getZoom() > MIN_ZOOM_VAL && this.$markers.length > 0) {
            if (evstationId) {
              this.$markers.forEach((m) => {
                if (m.evstationId == evstationId) {
                  // 日付を変更しただけの場合は前回選択中のピンを選択状態にしたい
                  this.selectStation(m)
                }
              })
            } else {
              this.selectStation(this.$markers[0])
            }
          }

          // マーカークラスター表示設定
          if (this.$map.getZoom() <= MIN_ZOOM_VAL) {
            this.markerCluster = new MarkerClusterer(this.$map, this.$markers, this.clusterOptions)
          } else if (this.markerCluster) {
            this.markerCluster.clearMarkers()
          }
        }

        // マップ検索の結果、中心地点のステーション情報を返す
        this.$emit(
          'setMapResult',
          this.where,
          result,
          this.$markers.length > 0 ? this.$markers[0].evstationId : null,
          this.$map.getZoom() <= MIN_ZOOM_VAL
        )
        this.$store.commit(MUTATION_TYPES.SET_MAP_PROCESSING, false)
      },

      // ステーション選択イベント
      selectStation(marker) {
        // infoWindow.open(this.$map, marker);
        this.$emit('setSelectEvstation', marker.evstationId)

        if (this.$map.getZoom() <= MIN_ZOOM_VAL) {
          return
        }
        jQuery('.balloon').fadeOut(100)

        if (marker.pixelOffset) {
          const posX = marker.pixelOffset.x
          const posY = marker.pixelOffset.y
          const mapW = jQuery('.map').width()
          let rorL
          let torB
          const ballonDiv = jQuery('.balloon')
          const balw = ballonDiv.outerWidth()
          const balh = ballonDiv.outerHeight()

          if (posX > Math.floor(mapW - balw)) {
            rorL = 'right'
          } else {
            rorL = 'left'
          }

          if (posY < Math.floor(balh + 200)) {
            torB = 'top'
          } else {
            torB = 'bottom'
          }

          const balDir = `${torB}_${rorL}`

          const sName = marker.evstationName

          ballonDiv.fadeOut(100, 'swing', () => {
            jQuery('.balloon p').html(sName)

            ballonDiv.removeClass(
              'balloon--top_left balloon--top_right balloon--bottom_left balloon--bottom_right'
            )

            ballonDiv.addClass('balloon--' + balDir)

            if (balDir === 'bottom_right') {
              ballonDiv.css({ top: posY - 128, left: posX - 128 })
            } else if (balDir === 'bottom_left') {
              ballonDiv.css({ top: posY - 128, left: posX - 47 })
            } else if (balDir === 'top_right') {
              ballonDiv.css({ top: posY + 12, left: posX - 128 })
            } else {
              ballonDiv.css({ top: posY + 12, left: posX - 47 })
            }
            ballonDiv.fadeIn(100, 'swing')
          })
        }
      },

      // 現在地へ移動
      async getMyPlace(isMove) {
        if (navigator.geolocation) {
          // 現在地を取得
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              // 緯度・経度を変数に格納
              let mapLatLng = new google.maps.LatLng(
                position.coords.latitude,
                position.coords.longitude
              )

              if (this.currentMarker) {
                this.currentMarker.setMap(null)
              } else {
                // 現在地マーカーは表示しない
                // this.currentMarker = new google.maps.Marker({
                //   map: this.$map,
                //   position: mapLatLng,
                //   icon: {
                //     url: '/img/icon_current_pos.png',
                //   },
                // })
              }

              // map移動
              if (isMove) {
                await this.moveMap(mapLatLng)
              }
            },
            (error) => {
              let msg = ''
              switch (error.code) {
                case 1: // PERMISSION_DENIED
                  msg = '位置情報の利用が許可されていません'
                  // alert("位置情報の利用が許可されていません");
                  break
                case 2: // POSITION_UNAVAILABLE
                  msg = '現在位置が取得できませんでした'
                  break
                case 3: // TIMEOUT
                  msg = 'タイムアウトになりました'
                  break
                default:
                  console.log(error.code)
                  msg = 'エラーが発生しました(エラーコード:' + error.code + ')'
                  break
              }

              this.$toast.show(msg, {
                type: 'error',
                position: 'top-right',
                duration: 3000,
                fullWidth: true,
              })
            }
          )
          // Geolocation APIに対応していない
        } else {
          alert('この端末では位置情報が取得できません')
        }
      },
      getIconUrl(type) {
        let url = '/img/marker_1.png'
        if (this.$map.getZoom() > MIN_ZOOM_VAL) {
          switch (type) {
            case MapMarkType.typeOne:
              url = '/img/map_marker_1_pp_on.png'
              break
            case MapMarkType.typeTwo:
              url = '/img/map_marker_2_pp_on.png'
              break
            case MapMarkType.typeThree:
              url = '/img/map_marker_3_pp_on.png'
              break
            case MapMarkType.typeFour:
              url = '/img/map_marker_1_on.png'
              break
            case MapMarkType.typeFive:
              url = '/img/map_marker_2_on.png'
              break
            case MapMarkType.typeSix:
              url = '/img/map_marker_3_on.png'
              break
            case MapMarkType.typeSeven:
              url = '/img/map_marker_1_pp_off.png'
              break
            case MapMarkType.typeEight:
              url = '/img/map_marker_2_pp_off.png'
              break
            case MapMarkType.typeNine:
              url = '/img/map_marker_3_pp_off.png'
              break
            case MapMarkType.typeTen:
              url = '/img/map_marker_1_off.png'
              break
            case MapMarkType.typeEleven:
              url = '/img/map_marker_2_off.png'
              break
            case MapMarkType.typeTwelve:
              url = '/img/map_marker_3_off.png'
              break
          }
        }
        return url
      },
      async moveMap(latLng) {
        this.$store.commit(MUTATION_TYPES.SET_MAP_PROCESSING, true)
        await this.$map.panTo(latLng)
        await this.$map.setCenter(latLng)
        await this.$map.setZoom(MAP_ZOOM)
        await this.searchMap()
      },
      calculateDistanceByZoomAndCenter(zoom, lat, widthMap) {
        if (!widthMap) {
          widthMap = 450
        }
        const metersPerPx = (156543.03392 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom)
        return metersPerPx * widthMap
      },
      convertDistanceByZoom(zoom) {
        switch (zoom) {
          case 21:
            return 25
          case 20:
            return 50
          case 19:
            return 100
          case 18:
            return 250
          case 17:
            return 350
          case 16:
            return 700
          case 15:
            return 1500
          case 14:
            return 2500
          case 13:
            return 5000
          default:
            return 20
        }
      },
    },
    computed: {},
    watch: {
      startDateTime() {
        this.time.startDateTime = this.startDateTime
      },
      endDateTime() {
        this.time.endDateTime = this.endDateTime
      },
      lat() {
        this.where.lat = this.lat
      },
      lng() {
        this.where.lng = this.lng
      },
      vehicleFilter() {
        // 車両絞込条件が切り替わったら再検索する
        this.searchMap()
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /*-------------- map --------------*/

  main.map_main {
    display: block;
    /*position:fixed;*/
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  main.map_main .map_section {
    display: block;
    /*position:fixed;*/
    width: 100%;
    /*height:calc(100vh - 49px - env(safe-area-inset-bottom));*/
    padding-top: 0;
    padding-bottom: 0;
  }

  main.map_main .map_section .map_search_bar {
    display: block;
    position: absolute;
    height: 50px;
    top: calc(10px + env(safe-area-inset-top));
    right: 10px;
    left: 10px;
    padding: 10px 10px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    z-index: 10000;
  }

  main.map_main .map_section .map_search_bar .term_start,
  main.map_main .map_section .map_search_bar .term_end {
    width: 40%;
    height: 30px;
    margin: 0;
  }

  main.map_main .map_section .map_search_bar .terms {
    display: flex;
  }

  main.map_main .map_section .map_search_bar .term_dash {
    width: 10%;
    height: 30px;
    background: url(/img/term_dash.svg) no-repeat center center;
    background-size: 80%;
  }

  main.map_main .map_section .map_search_bar .search_option {
    width: 10%;
    background: url(/img/icon_mapoption.svg) no-repeat center center;
    background-size: 80%;
    margin-left: 5px;
  }

  main.map_main .map_section .map_search_bar .search_option.active {
    width: 10%;
    background: #007ecb url(/img/icon_mapoption_active.svg) no-repeat center center;
    background-size: 80%;
    border-radius: 4px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2) inset;
  }

  main.map_main .map_section .sub_search {
    display: block;
    position: absolute;
    right: 14px;
    /*bottom:calc(165px + env(safe-area-inset-bottom));*/
    bottom: 0;
    z-index: 50;
  }

  main.map_main .map_section .sub_search .search_plus {
    display: block;
    width: 44.32px;
    height: 44.32px;
    margin: 12px auto;
    border-radius: 50px;
    background: #ffffff url(/img/plus.svg) no-repeat center center;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  }

  main.map_main .map_section .sub_search .search_minus {
    display: block;
    width: 44.32px;
    height: 44.32px;
    margin: 12px auto;
    border-radius: 50px;
    background: #ffffff url(/img/minus.svg) no-repeat center center;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  }

  main.map_main .map_section .sub_search .search_pinpoint {
    display: block;
    width: 44.32px;
    height: 44.32px;
    margin: 12px auto;
    border-radius: 50px;
    background: #ffffff url(/img/pinpoint.svg) no-repeat center center;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  }

  main.map_main .map {
    display: block;
    position: relative;
    width: 100%;
    height: 350px;
    /* add toyama */
  }

  main.map_main .map_info {
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }

  main.map_main .map_info .balloon {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 160px;
    min-height: 50px;
    margin: 10px;
    padding: 8px 10px;
    color: #ffffff;
    z-index: 8000;
  }

  main.map_main .map_info .balloon p {
    color: #ffffff;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.6em;
  }

  main.map_main .map_info .balloon::before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 30px;
    height: 30px;
  }

  main.map_main .map_info .balloon::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  main.map_main .map_info .balloon > * {
    position: relative;
    z-index: 3;
  }

  main.map_main .map_info .balloon,
  main.map_main .map_info .balloon::after {
    border-radius: 0px;
  }

  main.map_main .map_info .balloon,
  main.map_main .map_info .balloon::before {
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.25);
  }

  main.map_main .map_info .balloon,
  main.map_main .map_info .balloon::before,
  main.map_main .map_info .balloon::after {
    background: #213e57;
  }

  main.map_main .map_info .balloon--top_left::before {
    top: 0px;
    left: 20px;
    transform: rotate(45deg) skew(20deg, 20deg);
  }

  main.map_main .map_info .balloon--top_right::before {
    top: 0px;
    right: 20px;
    transform: rotate(45deg) skew(20deg, 20deg);
  }

  main.map_main .map_info .balloon--bottom_left::before {
    bottom: 0px;
    left: 20px;
    transform: rotate(45deg) skew(20deg, 20deg);
  }

  main.map_main .map_info .balloon--bottom_right::before {
    bottom: 0px;
    right: 20px;
    transform: rotate(45deg) skew(20deg, 20deg);
  }

  main.map_main .map_info .powerplant_info {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 162px;
    padding: 2px 2px;
    background: #ffffff;
    font-size: 1rem;
    line-height: 1.5em;
    color: #213e57;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 9000;
  }

  main.map_main .map_info .powerplant_info p {
    padding: 4px 6px;
    font-size: 12px;
  }

  main.map_main .map_info .powerplant_info p.photo {
    padding: 8px;
    background-image: url(/img/icon_photo.svg);
    background-repeat: no-repeat;
    background-position: right 6px center;
    font-size: 13px;
    border-radius: 3px;
  }

  main.map_main .map_info .powerplant_info .pp_fig {
    width: 100%;
    height: 0px;
    overflow: hidden;
  }

  main.map_main .map_info .powerplant_info .pp_fig img {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  main.map_main .selected_marker {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 85px;
    height: 45px;
    background: url(/img/selected_marker.svg) no-repeat center center;
    z-index: 1;
  }
</style>
