<template>
  <div class="station-info pos-station car_section container">
    <loading
      :active="favoriteProcessing || ev_processing || map_processing || my_station_processing"
      :loader="'dots'"
      :color="'#007ecb'"
      :can-cancel="true"
      :is-full-page="false"
    />
    <div class="no_car" style="display: block" v-if="isCluster">
      <div class="nonstation_coment">
        <p>地図の表示エリアが広すぎます。地図を拡大してください。</p>
      </div>
    </div>
    <div class="no_car" style="display: block" v-else-if="!evstationId">
      <div class="nonstation_coment">
        <p>この周辺にはステーションがありません</p>
      </div>
    </div>

    <ul v-else>
      <li>
        <h3 class="station-name">
          <button
            class="mystation-icon"
            :class="{ off: !isFavoriteStation }"
            @click.stop.prevent="toggleMyStation(evInfo.evstationId)"
          ></button>
          {{ evInfo.evstationName }}
        </h3>
        <div class="scroll">
          <p class="station-bicycle-info">
            <img src="img/icon_bicycle.svg" />駐車枠への駐車
            <br />
            自転車：{{ evInfo.bicycleAvailable === 1 ? '可' : '不可' }} 自家用車：{{
              evInfo.vehicleAvailable === 1 ? '可' : '不可'
            }}
          </p>
          <p class="station-time-info" v-if="evInfo.allDayReservable !== 1">
            <img src="img/icon_time.svg" />貸出・返却可能時間
            <br />
            {{ $filters.formatHHMM(evInfo.reservableStartTime) }} ～
            {{ $filters.formatHHMM(evInfo.reservableEndTime) }}
          </p>
          <p class="station-time-info" v-if="evInfo.allDayReservable === 1">
            <img src="img/icon_time.svg" />24時間可
          </p>
          <p class="station-traffic-info" v-if="evInfo.access">
            <img src="img/icon_traffic.svg" />
            <span v-html="$filters.convertBreakLine(evInfo.access)"></span>
          </p>
          <p class="station-caution-info" v-if="evInfo.notes">
            <img src="img/icon_caution.svg" />
            <span v-html="$filters.convertBreakLine(evInfo.notes)"></span>
          </p>
        </div>
      </li>
      <li>
        <div class="ev-caroucel">
          <carousel :img-list="evstationImages" :isModal="false"></carousel>
          <img src="img/imagepickup.svg" class="imagepickup" @click="isEvModal = true" />
        </div>
        <app-modal :isVisible="isEvModal" @close="isEvModal = false">
          <carousel :img-list="evstationImages" :isModal="true"></carousel>
        </app-modal>

        <div class="bolttype" v-if="evInfo.powerPlantName">
          <img src="img/bolttype.svg" />
          このステーションは{{ evInfo.powerPlantName }}の電気を使用しています。
        </div>
        <div class="bolttype-image" v-if="evInfo.powerPlantName">
          <img
            src="img/imagepickup.svg"
            class="bolttype-imagepickup"
            @click="isPowerPlantModal = true"
          />
          <img :src="evInfo.powerPlantImageUrl" />
        </div>
        <app-modal :isVisible="isPowerPlantModal" @close="isPowerPlantModal = false">
          <carousel :img-list="[evInfo.powerPlantImageUrl]" :isModal="true"></carousel>
        </app-modal>
      </li>
    </ul>
  </div>
</template>

<script>
  import { MUTATION_TYPES } from '@/store'
  import { evApi } from '@/module/Api'
  import Loading from 'vue-loading-overlay'
  import Carousel from '@/components/Carousel.vue'

  export default {
    name: 'EvstationInfoMap',
    props: {
      evstationId: { type: Number, default: null },
      isCluster: Boolean,
    },
    components: {
      Loading,
      Carousel,
    },
    data() {
      return {
        evInfo: {},
        chargeInfo: {},
        vehicleIdList: [],
        isEvModal: false,
        isPowerPlantModal: false,
        favoriteProcessing: false,
      }
    },
    created() {
      this.$store.commit(MUTATION_TYPES.SET_EV_PROCESSING, true)
    },
    mounted() {
      if (this.evstationId) {
        this.findById(this.evstationId)
      } else {
        this.favoriteProcessing = false
        this.$store.commit(MUTATION_TYPES.SET_EV_PROCESSING, false)
      }
    },
    methods: {
      async findById(id) {
        this.$store.commit(MUTATION_TYPES.SET_EV_PROCESSING, true)
        const ev = await evApi.findById(id).catch((e) => {
          this.$store.commit(MUTATION_TYPES.SET_EV_PROCESSING, false)
          this.validationErrorHook(e)
        })
        // console.log('取得結果', ev.data)
        this.evInfo = ev.data[0].evstationContainer
        this.chargeInfo = ev.data[0].chargeContainer
        this.vehicleIdList = []
        this.chargeInfo.forEach((c) => this.vehicleIdList.push(c.vehicleId))
        this.$emit('setVehicleId', this.vehicleIdList)
        this.$store.commit(MUTATION_TYPES.SET_EV_PROCESSING, false)
      },
      // お気に入りステーション更新
      async toggleMyStation(id) {
        // ダブルクリック制御
        this.favoriteProcessing = true
        setTimeout(() => {
          this.favoriteProcessing = false
        }, 1500)
        this.$emit('toggleMyStation', id)
      },
    },
    computed: {
      evstationImages() {
        let images = []
        if (this.evInfo.evstationImage1Url) images.push(this.evInfo.evstationImage1Url)
        if (this.evInfo.evstationImage2Url) images.push(this.evInfo.evstationImage2Url)
        if (this.evInfo.evstationImage3Url) images.push(this.evInfo.evstationImage3Url)
        if (this.evInfo.evstationImage4Url) images.push(this.evInfo.evstationImage4Url)
        if (this.evInfo.evstationImage5Url) images.push(this.evInfo.evstationImage5Url)
        return images
      },
      isFavoriteStation() {
        const favoriteStations = this.$_.cloneDeep(this.session.favoriteStations)
        const fs = favoriteStations ? favoriteStations.split(',') : []
        return fs.some((f) => f == this.evstationId)
      },
    },
    watch: {
      evstationId(newId) {
        if (newId) this.findById(newId)
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
