<template>
  <div class="mt-53px">
    <loading :active="processing">
      <loading
        :active="processing"
        :loader="'dots'"
        :color="'#007ecb'"
        :can-cancel="true"
        :is-full-page="true"
      >
      </loading>
      <p class="loading-msg">
        <strong>{{ loadingMessage }}</strong>
      </p>
    </loading>
    <app-header></app-header>
    <!--車両詳細・予約モーダル-->
    <main class="mypage">
      <div id="reserve">
        <div class="modal__content">
          <!--予約なしの表示-->
          <div
            class="side-cont noreserve"
            v-if="!processing && !lastReservation && !currentReservation"
            id="noReservation"
          >
            <div class="side-minicont dateprice">
              <div class="terms2">
                <p>予約はありません</p>
              </div>
            </div>
          </div>
          <!--予約なしの表示-->
          <template v-else>
            <ReservationInfo
              v-if="lastReservation"
              :reservation-info="lastReservation"
              :status-reservation="statusLastReservation"
              @emitChangeData="initDataReservation"
              @changeLoadingMessage="setLoadingMessage"
            />
            <ReservationInfo
              v-if="currentReservation"
              :reservation-info="currentReservation"
              :status-reservation="statusCurrentReservation"
              @emitChangeData="initDataReservation"
              @changeLoadingMessage="setLoadingMessage"
            />
          </template>
        </div>
      </div>
      <!--modal-->
      <!--車両詳細・予約モーダル-->
    </main>
    <app-footer></app-footer>
  </div>
</template>
<script>
  import Config from '@/conf/Config'
  import Loading from 'vue-loading-overlay'
  import ReservationInfo from './ReservationInfo'
  import { myPageInfoApi } from '@/module/Api'
  import moment from 'moment'
  import { MUTATION_TYPES } from '@/store'
  import { UsagePattern } from '@/constants/constant'

  const PAST_RESERVATION_STATUS = 2
  const CURRENT_RESERVATION_STATUS = 1

  export const VAL_10_MINUTES = 10
  export const VAL_15_MINUTES = 15
  export const STATUS_RESERVATION = {
    STATUS_1: 1, // not used yet: now <= startTime - 10minutes or 15minutes
    STATUS_2: 2, // not used yet: startTime - 10minutes or 15minutes < now() <= startTime
    STATUS_3: 3, // not used yet: now > startTime
    STATUS_4: 4, //  using: startTime < now < endTime
    STATUS_5: 5, // end of using, not yet unlock car
    STATUS_6: 6, // unlocking in forgetting items
    STATUS_7: 7, // locked in forgetting items
  }

  export default {
    components: { ReservationInfo, Loading },
    data() {
      return {
        lastReservation: null,
        currentReservation: null,
        statusLastReservation: null,
        statusCurrentReservation: null,
        loadingMessage: null,
      }
    },
    methods: {
      async initDataReservation() {
        this.lastReservation = null
        this.currentReservation = null

        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
        try {
          const result = await myPageInfoApi.findMyPageInfo()
          const listReservation = result.data
          if (listReservation.length === 0) {
            this.lastReservation = null
            this.currentReservation = null
          } else {
            for (const reservation of listReservation) {
              if (reservation.reservationStatus === PAST_RESERVATION_STATUS) {
                this.lastReservation = reservation
                this.lastReservation.reservationEndDatetime = this.lastReservation
                  .extentionEndDatetime
                  ? this.lastReservation.extentionEndDatetime
                  : this.lastReservation.reservationEndDatetime
                this.statusLastReservation = this.calStatusReservation(this.lastReservation)
              } else if (reservation.reservationStatus === CURRENT_RESERVATION_STATUS) {
                this.currentReservation = reservation
                this.currentReservation.reservationEndDatetime = this.currentReservation
                  .extentionEndDatetime
                  ? this.currentReservation.extentionEndDatetime
                  : this.currentReservation.reservationEndDatetime
                this.statusCurrentReservation = this.calStatusReservation(this.currentReservation)
              }
            }
            // If user start current reservation, then no display the last reservation.
            if (this.currentReservation && this.currentReservation.usingStartDatetime != null) {
              this.lastReservation = null
            }
            if (
              this.statusLastReservation === STATUS_RESERVATION.STATUS_6 &&
              this.currentReservation
            ) {
              this.currentReservation.inactiveUnlockButton = true
            }
          }
        } catch (e) {
          await this.validationErrorHook(e)
        } finally {
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
        }
      },

      /**
       * Define status of reservation to display
       */
      calStatusReservation(reservationInfo) {
        const startTime = moment(reservationInfo.reservationStartDatetime)
        const startableTime = reservationInfo.usagePattern === UsagePattern.JOINT_USE ? VAL_15_MINUTES : VAL_10_MINUTES
        const minStartTime = moment(startTime).subtract(startableTime, 'minutes')
        // Case: reservation is not ended
        if (reservationInfo.reservationStatus === CURRENT_RESERVATION_STATUS) {
          if (moment() < minStartTime) {
            return STATUS_RESERVATION.STATUS_1
          } else if (
            moment().isBetween(minStartTime, startTime) &&
            reservationInfo.usingStartDatetime == null
          ) {
            return STATUS_RESERVATION.STATUS_2
          } else if (moment() > startTime && reservationInfo.usingStartDatetime == null) {
            return STATUS_RESERVATION.STATUS_3
          } else if (
            (reservationInfo.usingStartDatetime != null &&
              reservationInfo.usingEndDatetime == null) ||
            (reservationInfo.delayUsingStartDatetime != null &&
              reservationInfo.delayUsingEndDatetime == null)
          ) {
            return STATUS_RESERVATION.STATUS_4
          }
        } else {
          // Case: reservation is ended
          if (reservationInfo.leftBehindUnlockStartDatetime == null) {
            return STATUS_RESERVATION.STATUS_5
          } else if (reservationInfo.leftBehindUnlockEndDatetime == null) {
            return STATUS_RESERVATION.STATUS_6
          } else {
            return STATUS_RESERVATION.STATUS_7
          }
        }
      },
      setLoadingMessage(message) {
        this.loadingMessage = message
      },
    },
    created() {
      document.cookie = Config.FUNCTION_ID + this.screenId
      this.initDataReservation()
    },
    computed: {
      screenId: () => 'MYPAGE', // <--- 個別に定義
    },
  }
</script>
<style scoped>
  .mt-53px {
    margin-top: 53px !important;
  }

  .loading-msg {
    margin-top: 60px;
  }
</style>
