export const EDIT_CREDIT_CARD = {
  titleDialog: '変更完了',
  messageDialog: 'カード情報を更新しました。',
  messageConfirm: '以前のクレジットカード情報が存在する場合は削除されます。よろしいですか？',
}

export const BUTTON_TEXT_DEFAULT = {
  buttonAgree: 'はい',
  buttonDisagree: 'いいえ',
}

export const UNSUBSCRIBE_DIALOG = {
  title: '退会確認',
  message:
    '退会後は本アプリにログインすることはできません。<br>' +
    'いつも使いプランにご加入中の方は、今月の月額料金は翌月はじめに決済されます。<br>' +
    '本当に退会しますか？<br>',
  buttonAgree: 'はい',
  buttonDisagree: 'いいえ',
}
export const USER_EDIT_LICENSE_MESSAGE = {
  titleDialog: '変更完了',
  messageDialog: '運転免許証情報の変更を受け付けました。\n確認完了の通知をお待ち下さい\n',
}

export const MENU002_CANCEL_RESERVE_CONFIRM = {
  header: '予約の取り消し',
  message1: 'この予約を取り消しますか？',
  message2:
    'この予約を取り消します。\n' +
    '開始時間を過ぎてからの取り消しは、料金が発生します。取り消しますか？\n',
  message3:
    'この予約を取り消します。\n' +
    'よろしいですか？\n',
  cancelBtn: 'いいえ',
  okBtn: 'はい',
}

export const MENU002_UNLOCK_CONFIRM = {
  header: '利用開始',
  messageOnboardUnitInstalled: '開始すると鍵が開きます。\n' + '利用を開始してよろしいですか？\n',
  messageNoOnboardUnitInstalled: '利用を開始してよろしいですか？\n',
  cancelBtn: 'キャンセル',
  okBtn: 'OK',
}

export const NETWORK_ERROR = {
  header: '通信エラーが発生しました',
  okBtn: 'OK',
}

export const MENU002_UNLOCK_SUCCESS = {
  header: '利用開始',
  message: '利用を開始しました。',
  okBtn: 'OK',
}

export const MENU002_UNLOCK_FORGOT_ITEMS_SUCCESS = {
  header: '忘れ物確認開始',
  message: '忘れ物確認を開始しました。',
  okBtn: 'OK',
}

export const MENU002_UNLOCK_FAIL = {
  header: '利用開始エラー',
  message: '利用開始できません。\n',
  okBtn: 'OK',
}

export const MENU002_UNLOCK_FORGOT_ITEMS_FAIL = {
  header: '忘れ物確認開始エラー',
  message: '忘れ物確認を開始できません。\n',
  okBtn: 'OK',
}

export const MENU002_EXTEND_CONFIRM = {
  header: '利用時間の延長',
  message: '利用時間を%sまで（%s分）延長します。\n' + 'よろしいですか？\n',
  cancelBtn: 'キャンセル',
  okBtn: 'OK',
}

export const MENU002_EXTEND_SUCCESS = {
  header: '利用時間の延長',
  message: '%sまで延長しました。',
  okBtn: 'OK',
}

export const RESERVATION_CANCEL_SUCCESS = {
  header: '取り消し完了',
  message: '予約をキャンセルしました。',
}

export const MENU002_LOCK_FAIL = {
  header: '利用終了エラー',
  message: '利用終了できません。\n鍵をキーボックスに戻してください。',
  okBtn: 'OK',
}

export const MENU002_LOCK_SUCCESS = {
  header: '利用終了',
  message: '利用を終了しました。',
  okBtn: 'OK',
}

export const MENU002_QUESTIONNAIRE_MILEAGE_CONFIRM = {
  message: `入力された値が前回の走行距離よりも小さいです。
    ※TRIPメーターの値を入力していませんか？
    不明な場合は<a href="%s" target="blank" class="link_text">ユーザーガイド</a>を参照してください。
    利用を終了する場合は「はい」、修正する場合は「いいえ」を選択してください。`,
  okBtn: 'はい',
  cancelBtn: 'いいえ',
};

export const MENU002_UNLOCK_FORGOT_CONFIRM = {
  header: '忘れ物確認',
  message:
    '利用終了から30分以内に一度だけ忘れ物の確認ができます。\n' +
    '忘れ物確認を開始後、10分以内に終了されない場合は帰着遅延としてペナルティ料金が発生しますので十分ご注意ください。開始しますか？',
  okBtn: 'OK',
  cancelBtn: 'キャンセル',
}

export const MENU002_LOCK_FORGOT_CONFIRM = {
  header: '忘れ物確認',
  message: '忘れ物確認を終了します。\n' + 'よろしいですか？',
  okBtn: 'OK',
  cancelBtn: 'キャンセル',
}

export const MENU002_LOCK_FORGOT_SUCCESS = {
  header: '忘れ物確認',
  message: '忘れ物確認を終了しました。',
  okBtn: 'OK',
}

export const MENU002_PUSH_NOTIFICATION_SETTING = {
  header: 'PUSH通知の設定',
  message:
    'PUSH通知の設定がオフになっています。\n' +
    '端末の設定画面から通知設定をオンにしてから利用してください',
}

export const USER_EDIT_CONFIRM_PAGE = {
  titleDialog: '変更完了',
  messageDialog: '基本情報の変更が完了しました。',
}

export const CONFIRM_TITLE = {
  title: '確認',
}

export const DIALOG_TEXT_BUTTON = {
  cancel: 'キャンセル',
  setting: '設定する',
}

export const DIALOG_MESSAGE = {
  requestLocationSettingOs: '設定から位置情報の利用を許可するように変更してください。',
}

export const LOG_OUT_MESSAGE = {
  logOutAlert: 'ログアウトしますか？',
}

export const COMM001_LOGIN_DIALOG_MESSAGE = {
  ERROR_CONTENT: 'メールアドレスまたはパスワードが誤っています',
  RESPONSE_FAIL_CONTENT:
    '予期せぬエラーが発生しました。ログインページよりもう一度操作してください。',
}

export const NETWORK_ERROR_MESSAGE = {
  NETWORK_DISCONNECT: '通信エラーが発生しました',
}

export const VALID_FORM_INPUT = {
  header: '入力不備のある項目が存在します。',
}

export const RESET_PASSWORD_TITLE = {
  header: 'パスワードの再設定が完了しました。',
}

export const DIALOG_NOTIFICATION_API_GET_061_ERROR = {
  message: 'データの読み込みに失敗しました。',
  okBtn: '再読み込み',
}

export const DIALOG_ERROR_USER_DONT_EXIST_COGNITO = {
  message: '認証に失敗しました。ログアウトします。',
}

export const CHANGE_EMAIL_MEMBER = {
  header: 'メールアドレスの変更が完了しました。',
}

export const USAGE_HISTORY_FIXEX_DATETIME_CONF = {
  header: '確認',
  message: '修正後利用開始日時・終了日時を更新してよろしいですか？',
  okBtn: 'OK',
  cancelBtn: 'キャンセル',
}
