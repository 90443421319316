import moment from 'moment'
import _ from 'lodash'

export class Utils {
  static convertNumberToHalfSizeAndKanaToFullsize(text) {
    text = Utils.convertNumberFullSizeToHalfSize(text)
    text = Utils.convertKanaHalfSizeToFullSize(text)
    return text
  }

  static calDiffTimeByMinutes(endTime, startTime) {
    const end = new Date(endTime)
    const start = new Date(startTime)
    const diffTime = end.getTime() - start.getTime()
    if (diffTime > 0) {
      return Math.ceil(diffTime / (1000 * 60))
    }
    return 0
  }

  /**
   *
   * @param driverLicenseDate: YYYY-MM-DD
   */
  static validateDriverLicense(driverLicenseDate, reserveDateEnd) {
    const reserveEnd = new Date(reserveDateEnd)
    reserveEnd.setHours(0, 0, 0, 0)
    const diffTime = driverLicenseDate.getTime() - reserveEnd.getTime()
    if (diffTime >= 0) {
      return true
    }
    return false
  }

  static createTimeStartByBlock() {
    const timeNow = new Date()
    const minutes = timeNow.getMinutes()
    if (minutes === 0 || minutes === 15 || minutes === 30 || minutes === 45) {
      return timeNow
    } else if (0 < minutes && minutes < 15) {
      timeNow.setMinutes(15)
    } else if (15 < minutes && minutes < 30) {
      timeNow.setMinutes(30)
    } else if (30 < minutes && minutes < 45) {
      timeNow.setMinutes(45)
    } else {
      timeNow.setHours(timeNow.getHours() + 1)
      timeNow.setMinutes(0)
    }
    return timeNow
  }

  static createTimeEndByBlock(timeStart) {
    timeStart.setHours(timeStart.getHours() + 1)
    return timeStart
  }

  static checkTimeReserveFromTo(timeStart, timeEnd) {
    return new Date(timeEnd) > new Date(timeStart)
  }

  static convertDistanceByZoom(zoom) {
    switch (zoom) {
      case 21:
        return 25
      case 20:
        return 50
      case 19:
        return 100
      case 18:
        return 250
      case 17:
        return 350
      case 16:
        return 700
      case 15:
        return 1500
      case 14:
        return 2500
      case 13:
        return 5000
      default:
        return 20
    }
  }

  /**
   * Minimal `sprintf` function in JavaScript.
   *
   * @param format : message
   * @param args: param to fill
   */
  static sprintf(format, args) {
    let i = 0
    return format.replace(/%s/g, () => args[i++])
  }

  /**
   * Calculate file size from dataURL
   *
   */
  static dataURLtoFile(dataurl) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  static dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1])
    } else {
      byteString = unescape(dataURI.split(',')[1])
    }
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ia], { type: mimeString })
  }

  /**
   * 0.155→16%、
   * 0.154→15%
   */
  static calculateRenewEnergyPercent(renewEnergyValue) {
    const numberEnergy = parseFloat(renewEnergyValue)
    return Math.round(numberEnergy * 100)
  }

  /**
   * Calculate selected year
   * From current year to 5 year later
   */
  static calSelectedYear() {
    const curYear = moment().year()
    const listYear = []
    for (let i = 0; i <= 6; i++) {
      listYear.push(curYear + i)
    }
    return listYear
  }

  static getOptionsNormal(list) {
    const options = []
    list.forEach((item) => {
      options.push({
        text: item.value,
        value: item.key,
      })
    })
    return options
  }

  static getOptionsCodeCategory(list) {
    const options = []
    list.forEach((item) => {
      options.push({
        text: item.codeAlias,
        value: item.codeKey,
      })
    })
    return options
  }

  static getOptionsTime(list) {
    const options = []
    list.forEach((item) => {
      options.push({
        text: item.key,
        value: item.value,
      })
    })
    return options
  }

  static reloadPage() {
    window.location.reload()
  }

  // #1593: Get query params from url open app
  static getQueryParams(params, url) {
    const reg = new RegExp('[?&]' + params + '=([^&#]*)', 'i')
    const queryString = reg.exec(url)
    return queryString ? queryString[1] : null
  }

  static isNormalInteger(str) {
    return /^\+?\d+$/.test(str)
  }

  /**
   * 1日単位の予約に分割
   */
  static async convertReservationList(reserveList) {
    let addList = []

    await reserveList.forEach(async (r) => {
      const start = moment(r.startDatetime).format('YYYY-MM-DDT00:00:00')
      const end = moment(r.endDatetime).format('YYYY-MM-DDT00:00:00')
      const diff = moment(end).diff(start, 'days')
      const startM = moment(start)

      // 開始終了が異なる日付の場合は調整する
      for (let i = 1; i < diff + 1; i++) {
        let nextdayReserve = _.cloneDeep(r)
        startM.add(1, 'd') // addすると本体にも1日日付が追加されるので1日ずつ追加していく。
        if (startM.isSame(end, 'days')) {
          // 開始時間0時
          nextdayReserve.startDatetime = startM.format('YYYY-MM-DDT00:00:00')

          r.endDatetime = moment(r.startDatetime).format('YYYY-MM-DDT24:00:00')
        } else {
          nextdayReserve.startDatetime = startM.format('YYYY-MM-DDT00:00:00')
          // 終了時間24時
          nextdayReserve.endDatetime = startM.format('YYYY-MM-DDT24:00:00')
        }
        if (!moment(nextdayReserve.startDatetime).isSame(nextdayReserve.endDatetime)) {
          addList.push(nextdayReserve)
        }
      }
    })

    reserveList.splice(reserveList.length, 0, ...addList)
  }

  /**
   * 車両予約スケジュール表示用のプロパティ設定
   */
  static getScheduleChartProp(item, vehicle) {
    const chartParams = this.chartParamProcessing(item.startDatetime, item.endDatetime)

    // console.log(item.startDatetime, item.endDatetime, { chartParams });

    const startTimeX = chartParams.startTimeX
    const hrx = chartParams.hrx
    const startTimeHour = chartParams.startTimeHour
    const endTimeHour = chartParams.endTimeHour
    const BLOCK_PERIOD = 8 // 96% width divide 12 hours (margin left, right: 2%)

    let textPropAm = {}
    let textPropPm = {}
    let barPropAm = {}
    let barPropPm = {}

    if (item.category < 4) {
      if (startTimeX < 12 && Number(startTimeX + hrx) <= 12) {
        barPropAm.width = hrx * BLOCK_PERIOD
        barPropAm.left = Math.floor(startTimeX * BLOCK_PERIOD + 2)
        barPropAm.cn = 'period_' + item.category
      } else if (startTimeX < 12 && Number(startTimeX + hrx) > 12) {
        barPropAm.width = Math.abs((12 - startTimeX) * BLOCK_PERIOD)
        barPropAm.left = Math.floor(startTimeX * BLOCK_PERIOD + 2)
        barPropAm.cn = 'straddle period_' + item.category
        barPropPm.width = (hrx - (12 - startTimeX)) * BLOCK_PERIOD
        barPropPm.left = 2
        barPropPm.cn = 'straddle period_' + item.category
      } else if (startTimeX >= 12 && Number(startTimeX + hrx) >= 12) {
        barPropPm.width = hrx * BLOCK_PERIOD
        barPropPm.left = Math.floor((startTimeX - 12) * BLOCK_PERIOD + 2)
        barPropPm.cn = 'period_' + item.category
      }
    } else if (item.category === 4) {
      if (startTimeX < 12 && Number(startTimeX + hrx) <= 12) {
        textPropAm.width = Math.floor(hrx * BLOCK_PERIOD)
        textPropAm.left = Math.floor(startTimeX * BLOCK_PERIOD + 2)
        textPropAm.cn = 'period_4'
      } else if (startTimeX < 12 && Number(startTimeX + hrx) > 12) {
        textPropAm.width = Math.floor((12 - startTimeX) * BLOCK_PERIOD)
        textPropAm.left = Math.floor(startTimeX * BLOCK_PERIOD + 2)
        textPropAm.cn = 'period_4'

        textPropPm.width = Math.floor((hrx - (12 - startTimeX)) * BLOCK_PERIOD)
        textPropPm.left = 2
        textPropPm.cn = 'period_4'
      } else if (startTimeX >= 12 && Number(startTimeX + hrx) >= 12) {
        textPropPm.width = Math.floor(hrx * BLOCK_PERIOD)
        textPropPm.left = Math.floor((startTimeX - 12) * BLOCK_PERIOD + 2)
        textPropPm.cn = 'period_4'
      }

      for (let j = parseInt(startTimeHour); j <= parseInt(endTimeHour); j++) {
        if (j < 13) {
          vehicle.timeListAm[j].cn += ' cat4'
        }
        if (j >= 12) {
          vehicle.timeListPm[j - 12].cn += ' cat4'
        }
      }
    } else if (item.category === 5) {
      if (startTimeX < 12 && Number(startTimeX + hrx) <= 12) {
        textPropAm.width = Math.floor(hrx * BLOCK_PERIOD)
        textPropAm.left = Math.floor(startTimeX * BLOCK_PERIOD + 2)
        textPropAm.cn = 'period_5'
      } else if (startTimeX < 12 && Number(startTimeX + hrx) > 12) {
        textPropAm.width = Math.floor((12 - startTimeX) * BLOCK_PERIOD)
        textPropAm.left = Math.floor(startTimeX * BLOCK_PERIOD + 2)
        textPropAm.cn = 'period_5'

        textPropPm.width = Math.floor((hrx - (12 - startTimeX)) * BLOCK_PERIOD)
        textPropPm.left = 2
        textPropPm.cn = 'period_5'
      } else if (startTimeX >= 12 && Number(startTimeX + hrx) >= 12) {
        textPropPm.width = Math.floor(hrx * BLOCK_PERIOD)
        textPropPm.left = Math.floor((startTimeX - 12) * BLOCK_PERIOD + 2)
        textPropPm.cn = 'period_5'
      }

      for (let j = parseInt(startTimeHour); j <= parseInt(endTimeHour); j++) {
        if (j < 13) {
          const ret = vehicle.cat56AmList.find((c) =>
            moment(c.date).isSame(item.startDatetime, 'day')
          )
          if (ret) ret.list[j].cn += ' cat5'
        }
        if (j >= 12) {
          const ret = vehicle.cat56PmList.find((c) =>
            moment(c.date).isSame(item.startDatetime, 'day')
          )
          if (ret) ret.list[j - 12].cn += ' cat5'
        }
      }
    } else if (item.category === 6) {
      if (startTimeX < 12 && Number(startTimeX + hrx) <= 12) {
        textPropAm.width = Math.floor(hrx * BLOCK_PERIOD)
        textPropAm.left = Math.floor(startTimeX * BLOCK_PERIOD + 2)
        textPropAm.cn = 'period_6'
      } else if (startTimeX < 12 && Number(startTimeX + hrx) > 12) {
        textPropAm.width = Math.floor((12 - startTimeX) * BLOCK_PERIOD)
        textPropAm.left = Math.floor(startTimeX * BLOCK_PERIOD + 2)
        textPropAm.cn = 'period_6'

        textPropPm.width = Math.floor((hrx - (12 - startTimeX)) * BLOCK_PERIOD)
        textPropPm.left = 2
        textPropPm.cn = 'period_6'
      } else if (startTimeX >= 12 && Number(startTimeX + hrx) >= 12) {
        textPropPm.width = Math.floor(hrx * BLOCK_PERIOD)
        textPropPm.left = Math.floor((startTimeX - 12) * BLOCK_PERIOD + 2)
        textPropPm.cn = 'period_6'
      }

      // tslint:disable-next-line:radix
      for (let j = parseInt(startTimeHour); j <= parseInt(endTimeHour); j++) {
        if (j < 13) {
          vehicle.cat56AmList.find((c) => moment(c.date).isSame(item.startDatetime, 'day')).list[
            j
          ].cn += ' cat6'
        }
        if (j >= 12) {
          vehicle.cat56PmList.find((c) => moment(c.date).isSame(item.startDatetime, 'day')).list[
            j - 12
          ].cn += ' cat6'
        }
      }
    }

    item.textPropAm = textPropAm
    item.textPropPm = textPropPm
    item.barPropAm = barPropAm
    item.barPropPm = barPropPm

    return item
  }

  static chartParamProcessing(start, end) {
    /* eslint-disable */
    start = start.replace(/^\s+|\s+$|\:/g, '')

    /* eslint-disable */
    end = end.replace(/^\s+|\s+$|\:/g, '')
    start = '0000' + start
    end = '0000' + end
    // 時と分を2桁ずつ切り出します。
    const startTimeMinute = start.substr(-4, 2)
    const endTimeMinute = end.substr(-4, 2)
    let startTimeHour = start.substr(-6, 2)
    const endTimeHour = end.substr(-6, 2)

    const startTimeX = parseInt(startTimeHour) + parseInt(startTimeMinute) / 15 / 4

    const hrx =
      Math.floor(
        ((parseInt(endTimeHour) - parseInt(startTimeHour)) * 60 +
          parseInt(endTimeMinute) -
          parseInt(startTimeMinute)) /
          15
      ) / 4

    if (parseInt(startTimeMinute) !== 0) {
      startTimeHour = Math.floor(parseInt(startTimeHour) + 1)
    }

    return {
      startTimeX,
      hrx,
      startTimeHour,
      endTimeHour,
    }
  }

  static getCategoryName(cat) {
    switch (cat) {
      case 1:
        return '予約済み'
      case 2:
        return '充放電予約'
      case 3:
        return 'メンテナンス中'
      case 4:
        return '利用時間外'
      case 5:
        return '法人予約枠'
      case 6:
        return '法人予約枠（特定会員向け）'
      default:
        return ''
    }
  }

  /**
   * Check answer is empty or not
   * return true: if empty
   * else, return false
   */
  static isEmpty(answer) {
    return (
      !(Array.isArray(answer) && answer.length) ||
      (answer.length === 1 && (answer[0] === '' || answer[0] === null))
    )
  }

  /**
   * Check string is empty or not
   * return true: if empty
   * else, return false
   */
  static isEmptyString(str) {
    return !str || str === ''
  }

  /**
   * Check string is number
   * return true: if type is number
   * else, return false
   */
  static isNumber(n) {
    return parseInt(n)
  }
}
