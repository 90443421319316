<template>
  <loading
    :active="processing"
    :loader="'dots'"
    :color="'#007ecb'"
    :can-cancel="true"
    :is-full-page="true"
  />
  <div class="password errorpage">
    <form>
      <main class="cf password1">
        <section class="common_section reminder">
          <h2>3Dセキュア認証中</h2>
          <div class="nobox">
            <p class="body_text">処理中です。そのままお待ち下さい</p>
          </div>
        </section>
      </main>
    </form>
  </div>
</template>
<script>
  import { GETTER_TYPES, MUTATION_TYPES } from '@/store'
  import Loading from 'vue-loading-overlay'
  import { reservationApi } from '@/module/Api'
  import Config from '@/conf/Config'

  export default {
    components: { Loading },
    beforeCreate() {},
    created() {
      this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
      this.form = {
        ...this.currentQuery,
      }
    },
    async mounted() {
      this.upsertReservation()
    },
    data() {
      return {
        form: {
          stationName: null,
          location: null,
          startTime: null,
          endTime: null,
          onboardUnitInstalled: null,
          '3ds_auth_id': null,
          '3dsecure_requestor_error_code': null,
          '3dsecure_server_error_code': null,
          acq_id: null,
          acq_name: null,
          attempt_kbn: null,
          card_brand: null,
          fingerprint: null,
          hc: null,
          issur_class: null,
          issur_id: null,
          issur_name: null,
          masked_card_number: null,
          result: null,
        },
      }
    },
    methods: {
      async upsertReservation() {
        const title = '予約完了'
        let msg = '予約を受け付けました。'
        msg = this.$store.getters[GETTER_TYPES.GET_RESERVATION_COMPLETE_MSG]

        reservationApi
          .createReservationSecured(this.form)
          .then(() => {
            this.$_confirm.dialogOpen(msg, title).then(async () => {
              // マイページへ
              this.go(Config.URL.COMPLETE)
            })
          })
          .catch(async (e) => {
            await this.validationErrorHook(e)

            // エラー発生時にリダイレクト先があれば予約画面に戻る
            const redirect = JSON.parse(sessionStorage.getItem(Config.REDIRECT_URL))
            if (redirect) {
              sessionStorage.removeItem(Config.REDIRECT_URL)
              await this.$router.push({
                path: redirect.path,
                query: redirect.query,
              })
            } else {
              await this.$router.push(Config.URL.ROOT)
            }
          })
          .finally(() => {
            this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
          })
      },
    },
  }
</script>
<style scoped></style>
