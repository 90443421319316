<template>
  <div id="reserve" class="modal js-modal station_body" style="display: block; opacity: 1">
    <div class="modal__content">
      <loading
        :active="processing"
        :loader="'dots'"
        :color="'#007ecb'"
        :can-cancel="true"
        :is-full-page="true"
      />
      <!--モーダルヘッダー-->
      <div class="modal_header">
        <a class="js-modal-close" hlef="#" @click.stop.prevent="closeModal()">戻る</a>
        <div class="modal_ttl">{{ title }}</div>
      </div>
      <div class="modalbody vehiclemodal">
        <div class="modalbody_wrap">
          <div class="reserve_box">
            <div class="reserve_left">
              <!--車両情報-->
              <VehicleInfo :vehicle="vehicle"></VehicleInfo>
              <!--車両情報-->

              <!--充電残量-->
              <vehicle-energy-graph
                v-if="vehicle.vehicleId"
                :vehicle="vehicle"
                :startTime="unionStartDateTime.format('YYYY-MM-DD HH:mm:ss')"
                :endTime="unionEndDateTime.format('YYYY-MM-DD HH:mm:ss')"
                :reEnergyRatio="reEnergyRatio"
                :reEnergyRatioClass="reEnergyRatioClass"
              >
              </vehicle-energy-graph>

              <!--ステーション情報-->
              <EvstationInfo :evInfo="evInfo" v-if="evInfo.evstationId"></EvstationInfo>

              <!--予約状況-->
              <div class="reserved_area" v-show="!isConfirm">
                <div class="blockttl">
                  <span><img src="img/title_calendar.svg" /></span>
                  <p>予約状況</p>
                </div>

                <div class="dateselect_chart">
                  <dateSlider
                    :dateList="dateList"
                    v-if="dateList"
                    @changeDate="changeDate"
                    :viewIcon="true"
                  >
                  </dateSlider>
                  <div class="rese_schedule_chart">
                    <!-- 上段 -->
                    <div class="chart_am">
                      <div class="time_am"></div>
                      <div class="oclock_am_bg">
                        <div
                          v-for="(v, index) in vehicle.vehicleReservation"
                          :key="index"
                          :class="v.textPropAm.cn"
                          :style="
                            'width:' +
                            v.textPropAm.width +
                            '%;' +
                            'left:' +
                            v.textPropAm.left +
                            '%;'
                          "
                          v-show="v.textPropAm && isToday(v)"
                        ></div>
                      </div>
                      <div class="oclock_am oclock cf">
                        <div
                          v-for="(n, index) in vehicle.timeListAm"
                          :key="index"
                          :class="n.time + 'o' + n.cn + getCat56AmClassName(vehicle, index)"
                        >
                          {{ n.time }}
                        </div>
                      </div>
                      <!-- 予約バー -->
                      <div
                        v-for="(v, index) in vehicle.vehicleReservation"
                        :key="index"
                        :class="`${v.barPropAm.cn} hastip`"
                        :style="`width: ${v.barPropAm.width}%; left:${v.barPropAm.left}%; z-index: ${v.displayPriority}`"
                        v-show="v.barPropAm && v.barPropAm.cn !== undefined && isToday(v)"
                      >
                        <div class="period_tooltip">
                          <template v-if="v.fullName">
                            <span class="icon-user"></span>{{ v.fullName }}
                            <template v-if="!isEmptyObject(v.belongDeptName)">{{
                              v.belongDeptName
                            }}</template>
                          </template>
                          <template v-else>
                            {{ v.categoryName }}
                          </template>
                          <br />{{ $filters.formatDateHHmm(v.startDatetime) }}
                          ~
                          {{ $filters.formatDateHHmm24(v.endDatetime) }}
                        </div>
                      </div>
                    </div>
                    <!-- 下段 -->
                    <div class="chart_pm">
                      <div class="time_pm"></div>
                      <div class="oclock_pm_bg">
                        <div
                          v-for="(v, index) in vehicle.vehicleReservation"
                          :key="index"
                          :class="v.textPropPm.cn"
                          :style="
                            'width:' +
                            v.textPropPm.width +
                            '%;' +
                            'left:' +
                            v.textPropPm.left +
                            '%;'
                          "
                          v-show="v.textPropPm && isToday(v)"
                        ></div>
                      </div>
                      <div class="oclock_pm oclock cf">
                        <div
                          v-for="(n, index) of vehicle.timeListPm"
                          :key="index"
                          :class="n.time + 'o' + n.cn + getCat56PmClassName(vehicle, index)"
                        >
                          {{ n.time }}
                        </div>
                      </div>
                      <div
                        v-for="(v, index) in vehicle.vehicleReservation"
                        :key="index"
                        :class="`${v.barPropPm.cn} hastip`"
                        :style="`width:${v.barPropPm.width}%; left:${v.barPropPm.left}%; z-index: ${v.displayPriority}`"
                        v-show="v.barPropPm && v.barPropPm.cn !== undefined && isToday(v)"
                      >
                        <div class="period_tooltip">
                          <template v-if="v.fullName">
                            <span class="icon-user"></span>{{ v.fullName }}
                            <template v-if="!isEmptyObject(v.belongDeptName)">{{
                              v.belongDeptName
                            }}</template>
                          </template>
                          <template v-else> {{ v.categoryName }} </template><br />{{
                            $filters.formatDateHHmm(v.startDatetime)
                          }}
                          ~
                          {{ $filters.formatDateHHmm24(v.endDatetime) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="hanrei_wrap">
                  <p class="type1"><img src="img/bookingtype1.svg" alt="予約可能" />予約可能</p>
                  <p class="type2"><img src="img/bookingtype2.svg" alt="予約済み" />予約済み</p>
                  <p class="type3"><img src="img/bookingtype3.svg" alt="充放電予約" />充放電予約</p>
                  <p class="type4">
                    <img src="img/bookingtype4.svg" alt="メンテナンス中" />メンテナンス中
                  </p>
                  <p class="type5"><img src="img/bookingtype5.svg" alt="利用時間外" />利用時間外</p>
                  <p class="type6"><img src="img/bookingtype6.svg" alt="法人予約枠" />法人予約枠</p>
                  <p class="type7" v-if="isReservationSpecific">
                    <img
                      src="img/bookingtype7.svg"
                      alt="法人予約枠（特定会員向け）"
                    />法人予約枠（特定会員向け）
                  </p>
                </div>

                <div class="reserved_area_plan" v-if="isUsageOpen">
                  <ul>
                    <li>
                      料金体系やご利用にあたっての注意事項については料金プランの詳細をご確認ください。
                    </li>
                    <li>
                      <div class="plun_link">
                        <a :href="corpParam.fareUrl" target="blank"
                          ><span><img src="img/icon_plan01.svg" /></span>料金プラン詳細ページ</a
                        >
                      </div>
                    </li>
                  </ul>
                  <ul v-if="corpParam.warrantyPlan == 1">
                    <li>
                      {{
                        insurancePlan.insurancePlanName
                      }}にご加入される場合は、加入料金や加入される予約内容、保証に関する詳細をご確認ください。
                    </li>
                    <li>
                      <div class="plun_link">
                        <a :href="corpParam.warrantyUrl" target="blank"
                          ><span><img src="img/icon_plan02.svg" /></span
                          >{{ insurancePlan.insurancePlanName }}について</a
                        >
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="reserved_area_atention" v-if="session.corpId !== null">
                  ※予約時のご注意<br />法人予約枠外の利用は予約者様個人の負担となりますのでご注意ください。
                </div>
              </div>
              <!--予約状況-->
            </div>
            <div class="reserve_right">
              <transition-group tag="div" :name="tranName">
                <!--車両詳細・予約-->
                <div id="using" class="reserve-edit" v-show="!isConfirm" :key="1">
                  <div class="using-ttl">
                    <b>ご予約はこちらから</b>
                    <p>{{ isAvailable ? '予約可能' : '予約不可' }}</p>
                  </div>
                  <div class="notscrolarea">
                    <div class="notscrol_box">
                      <h3 class="sidettl">
                        <img src="img/pin.svg" alt="" /><a
                          href="#stationArea"
                          style="color: #007ecc"
                          >{{ evInfo.evstationName }}</a
                        >
                      </h3>
                    </div>
                    <div class="notscrol_box terms">
                      <date-time-picker-range
                        :value="time"
                        @change="changeReservationDateTime"
                        ref="dateTimePicker"
                        v-if="vehicle.vehicleTypeClass"
                      />
                      <div class="checkoption" v-if="isUsageOpen" v-show="!isInitial">
                        <input
                          type="checkbox"
                          id="check6"
                          name="check6"
                          v-model="form.nightpackApplied"
                          :disabled="nightPackDisable"
                        />
                        <label for="check6" :class="{ 'light-text': nightPackDisable }">{{
                          chargeUnit.nightPackName
                        }}</label>
                        <input
                          type="checkbox"
                          id="check7"
                          name="check7"
                          v-model="form.insuranceSubscribed"
                          :disabled="hasCorpFrameInTime"
                          v-if="corpParam.warrantyPlan == 1"
                        />
                        <label
                          for="check7"
                          :class="{ 'light-text': hasCorpFrameInTime }"
                          v-if="corpParam.warrantyPlan == 1"
                          >{{ insurancePlan.insurancePlanName }}</label
                        >
                      </div>
                    </div>
                  </div>

                  <ul class="infocontent using-infocontent" v-show="!isInitial">
                    <li
                      class="pay-type"
                      v-if="isUsageOpen && !cleanOrMaintenance && isCorporateMember"
                    >
                      <h4>支払方法</h4>
                      <select
                        name="cartype"
                        id="paymentMethod"
                        v-model="form.paymentMethod"
                        v-if="session.paymentMethod == 1"
                      >
                        <option value="1" selected>請求書払い（法人利用）</option>
                        <option value="2">クレジットカード払い</option>
                      </select>
                      <p id="paymentMethod" v-if="session.paymentMethod != 1 && isCorporateMember">
                        クレジットカード払い
                      </p>
                    </li>
                    <li class="promotion-code" v-if="isUsageOpen">
                      <h4>プロモーションコード</h4>
                      <input
                        type="text"
                        id="promotion"
                        placeholder="例: 0000"
                        v-model="form.promotionCode"
                        :disabled="hasCorpFrameInTime"
                        maxlength="4"
                      />
                      <p class="error">{{ errorMessage.promotion }}</p>
                    </li>
                    <li class="plus-driver" v-if="isUsageOpen">
                      <h4>
                        追加運転者<img
                          :src="isPassengerArea ? 'img/openarrow.svg' : 'img/openarrow.svg'"
                          class="plus-driver-opener"
                          @click="togglePassenger()"
                        />
                      </h4>
                      <div v-show="isPassengerArea">
                        <div
                          class="driver_box"
                          v-for="(passengerEmail, index) in temp.passengerEmailList"
                          :key="index"
                        >
                          <div class="driver_ttl">追加運転者（{{ index + 1 }}） メールアドレス</div>
                          <div class="drivers_email">
                            <input
                              type="text"
                              v-model="temp.passengerEmailList[index]"
                              placeholder="例: yourname@yourdomain.com"
                            />
                            <div class="btn_delete_driver">
                              <span @click="temp.passengerEmailList.splice(index, 1)">×</span>
                            </div>
                          </div>
                          <p class="error" v-if="errorMessage.passenger[index]">
                            {{ errorMessage.passenger[index] }}
                          </p>
                        </div>

                        <div
                          class="btn_add_driver btn_s btn_neblue"
                          @click="temp.passengerEmailList.push('')"
                          v-show="temp.passengerEmailList.length < this.vehicle.seatingCapacity - 1"
                        >
                          + 運転者を追加する
                        </div>
                        <div class="about_adddrivers">
                          <a :href="corpParam.passengerExplanationUrl" target="blank"
                            >追加運転者とは</a
                          >
                        </div>
                      </div>
                    </li>
                    <li class="drive-setting" v-if="vehicle && !isUsageOpen">
                      <h3>運行日報への記録の為、以下の項目に入力してください。</h3>
                      <h4>利用目的<span>必須</span></h4>
                      <select name="cartype" id="usePurpose" v-model="form.usePurpose">
                        <option value="">選択してください</option>
                        <option
                          v-for="code in usePurposeList"
                          :key="code.codeKey"
                          :value="code.codeKey"
                        >
                          {{ code.codeAlias }}
                        </option>
                      </select>
                      <input
                        type="text"
                        placeholder="利用目的（その他）"
                        v-model="form.usePurposeOther"
                        :disabled="form.usePurpose != 7"
                        class="drive-setting-input1"
                      />
                      <p class="error">{{ errorMessage.usePurpose }}</p>
                      <h4>目的地<span>必須</span></h4>
                      <input
                        type="text"
                        id="pac-input-reserve"
                        placeholder="目的地を入力してください"
                        class="drive-setting-input2"
                      />
                      <p class="error">{{ errorMessage.destination }}</p>
                    </li>
                    <li></li>
                  </ul>
                  <div class="button_bg">
                    <button
                      id="confirm-submit"
                      class="submit"
                      @click.stop.prevent="goConfirm()"
                      :disabled="isInitial"
                    >
                      <img src="img/bookingbtn-bk.svg" />{{
                        reservationId ? 'この内容で変更する' : 'この車両を予約する'
                      }}
                    </button>
                  </div>
                </div>
                <!--/車両詳細・予約-->

                <!-- 確認フォーム -->
                <div id="using" class="confarm" v-show="isConfirm" :key="2">
                  <div class="using-ttl">
                    <b v-if="reservationId == null"
                      >まだ予約は確定していません。内容を確認の上、<br />「予約を確定する」ボタンを押してください。</b
                    >
                    <b v-else
                      >まだ予約は確定していません。内容を確認の上、<br />「変更内容を確定する」ボタンを押してください。</b
                    >
                  </div>
                  <ul class="infocontent using-infocontent">
                    <li>
                      <h3 class="sidettl"><img src="img/pin.svg" />{{ evInfo.evstationName }}</h3>
                    </li>
                    <li class="terms">
                      <h4>予約日時</h4>
                      <div class="term_start">
                        <input
                          type="datetime"
                          :value="formatStartDateTime"
                          class="date_picker"
                          readonly
                        />
                      </div>
                      <div class="term_dash"></div>
                      <div class="term_end">
                        <input
                          type="datetime"
                          :value="formatEndDateTime"
                          class="date_picker"
                          readonly
                        />
                      </div>
                      <div class="drive-time">
                        利用時間<span>{{ displayDuration.hour }}</span
                        >時間<span>{{ displayDuration.minute }}</span
                        >分
                      </div>
                    </li>
                    <li
                      class="pay-type flex-style"
                      v-if="isUsageOpen && !cleanOrMaintenance && isCorporateMember"
                    >
                      <h4>支払方法</h4>
                      <p v-if="form.paymentMethod == 1">請求書払い（法人利用）</p>
                      <p v-if="form.paymentMethod == 2">クレジットカード払い</p>
                    </li>
                    <li
                      class="plus-driver"
                      v-if="isUsageOpen && form.passengerEmailList.length > 0"
                    >
                      <h4>追加運転者</h4>
                      <p v-for="p in form.passengerEmailList" :key="p">
                        {{ p }}
                      </p>
                    </li>
                    <li class="planning flex-style" v-if="isUsageOpen">
                      <h4>プラン</h4>
                      <div>
                        <p v-show="form.nightpackApplied">
                          {{ chargeUnit.nightPackName }}を利用する
                        </p>
                        <p v-show="form.insuranceSubscribed">
                          {{ insurancePlan.insurancePlanName }}に申し込む
                        </p>
                      </div>
                    </li>
                    <li class="drive-setting flex-style" v-if="!isUsageOpen">
                      <h4>利用目的</h4>
                      <p>{{ usePurposeAlias }}</p>
                    </li>
                    <li class="drive-setting flex-style" v-if="!isUsageOpen">
                      <h4>目的地</h4>
                      <p>{{ form.destination }}</p>
                    </li>
                    <li class="" v-if="isUsageOpen">
                      <h4>ご利用料金<span class="small">(内訳)</span></h4>
                      <table class="price_info_table">
                        <tbody>
                          <tr>
                            <th>利用料金</th>
                            <td>{{ estimatedTimeCharge.toLocaleString() }}円</td>
                          </tr>
                          <tr>
                            <th>{{ chargeUnit.nightPackName }}</th>
                            <td>{{ estimatedNightpackCharge.toLocaleString() }}円</td>
                          </tr>
                          <tr>
                            <th>{{ insurancePlan.insurancePlanName }}</th>
                            <td>{{ estimatedInsuranceCharge.toLocaleString() }}円</td>
                          </tr>
                          <tr v-if="promotionName">
                            <th>{{ promotionName }}</th>
                            <td class="promotion_value">
                              <strong>-{{ discount.toLocaleString() }}</strong
                              >円
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </li>
                    <li class="total-cost flex-style" v-if="isUsageOpen">
                      <b class="attention-text">※充電料金のご負担は不要です。</b>
                      <h4>概算料金<span class="small">(税込)</span></h4>
                      <p>{{ totalEst.toLocaleString() }}<span>円</span></p>
                    </li>
                  </ul>
                  <button
                    id="regist-submit"
                    v-if="reservationId == null"
                    class="submit"
                    @click.stop.prevent="registReservation()"
                  >
                    <img src="img/bookingbtn-bk.svg" />予約を確定する
                  </button>
                  <button
                    v-else
                    id="regist-submit"
                    class="submit"
                    @click.stop.prevent="registReservation()"
                  >
                    <img src="img/bookingbtn-bk.svg" />変更内容を確定する
                  </button>
                  <button type="button" @click="backInput()" class="submit btn_l btn_neblue cancel">
                    戻る
                  </button>
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--modal__inner-->
  </div>
</template>

<script>
  import { MUTATION_TYPES } from '@/store'
  import {
    reservationApi,
    vehicleApi,
    evApi,
    chargeApi,
    reEnergyApi,
    insuranceApi,
    codeApi,
  } from '@/module/Api'
  import Loading from 'vue-loading-overlay'
  import Config from '@/conf/Config'
  import moment from 'moment'
  import { isEmptyObject, isEmpty } from '@/helpers/validators'
  import {
    DATE_TIME_FORMAT,
    TIME_AM,
    TIME_PM,
    PASSENGER_ERROR,
    USE_PURPOSE_OTHER,
  } from '@/constants/constant'
  import VehicleEnergyGraph from '@/components/VehicleEnergyGraph.vue'
  import EvstationInfo from '@/components/EvstationInfo.vue'
  import VehicleInfo from '@/components/VehicleInfo.vue'
  import DateSlider from '@/components/DateSlider.vue'
  import { Utils } from '@/helpers/Utils'
  import { DateUtils } from '@/helpers/DateUtils'
  import { FORM_MUTATION_TYPES, FORM_GETTER_TYPES } from '@/store/modules/form'
  import DateTimePickerRange from './DateTimePickerRange.vue'

  const LOW_RATIO = 10
  const HIGHT_RATIO = 17

  const CLASS_LOW = '25' // icon_eco_25.svg
  const CLASS_MEDIUM = '75' // icon_eco_75.svg
  const CLASS_HIGHT = '100' // icon_eco_100.svg

  export const RESERVATION_TYPE_PERSONAL_USE = 1
  export const RESERVATION_TYPE_CORPORATE_USE = 2

  // フロントバリデーション
  const PURPOSE_USE_REQUIRED = '利用目的は必須項目です。'
  const LOCATION_DESTINATION_REQUIRED = '目的地は必須項目です。'

  const currentDate = moment().format('YYYY-MM-DD HH:mm')
  /* global google , CARSHARE_CORP_ID */
  export default {
    name: 'ReservationEdit',
    // 画面表示時のパラメータ
    props: {
      reservationId: { type: Number, default: null },
      vehicleId: { type: Number, default: null },
      evstationId: { type: Number, default: null },
      startDateTime: { type: String, default: '' },
      endDateTime: { type: String, default: '' },
    },
    emits: ['closeModal'],
    components: {
      Loading,
      DateSlider,
      VehicleEnergyGraph,
      EvstationInfo,
      VehicleInfo,
      DateTimePickerRange,
    },
    data() {
      return {
        map: '',
        isAvailable: false,
        reservationInfo: {},
        vehicle: {},
        evInfo: {},
        searchBox: {},
        chargeInfo: {}, // ステーション
        chargeUnit: {}, // 料金プラン
        insurancePlan: {},
        nightPackDisable: false,
        hasCorpFrameInTime: false, // 予約時間が法人枠内に収まっている場合はtrue(予約可否チェックの返却値)
        activePromotionCode: false,
        listReEnergyRatio: [],
        usePurposeList: [], // 利用目的選択肢
        reEnergyRatio: 0,
        reEnergyRatioClass: '',
        isReservationSpecific: false,
        dateList: [],
        selectDateIndex: 0,
        isPassengerArea: false,
        isConfirm: false,
        tranName: 'slide-right',
        // 入力フォーム
        form: {
          reservationId: null,
          vehicleId: null,
          evstationId: null,
          startDateTime: null,
          endDateTime: null,
          promotionCode: '',
          passengerEmailList: [],
          nightpackApplied: false,
          insuranceSubscribed: false,
          paymentMethod: null,
          // 利用目的
          usePurpose: '',
          usePurposeOther: '',
          // 目的地
          destination: '',
          destinationLatitude: 0,
          destinationLongitude: 0,
        },
        startDateCondition: {
          min: currentDate,
          max: moment().add(14, 'd').format('YYYY-MM-DD 23:30:00'),
        },
        endDateCondition: {
          min: moment(currentDate).add(15, 'm').format('YYYY-MM-DD HH:mm'),
          max: moment(this.startDateTime).add(3, 'd').format('YYYY-MM-DD HH:mm'),
        },
        time: {
          // 初期表示時に仮で日付をセットしておかないと日付を引き継げず、min日付がセットされてしまう。
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        },
        temp: {
          passengerEmailList: [],
        },
        errorMessage: {
          promotion: '',
          passenger: [],
          usePurpose: '',
          destination: '',
        },
        // 予約確認エリア
        estimatedTimeCharge: 0,
        estimatedNightpackCharge: 0,
        estimatedInsuranceCharge: 0,
        discount: 0,
        promotionName: '',
        totalEst: 0,
        displayDuration: {},
        isInitial: true,
      }
    },
    async created() {
      this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)

      // 支払い方法のデフォルト設定
      this.form.paymentMethod = this.session.paymentMethod === 1 ? 1 : 2

      for (let i = 0; i < 14; i++) {
        this.dateList.push(moment().add(i, 'day'))
      }

      if (this.reservationId) {
        // 変更
        await this.findReservation(this.reservationId)
        if (isEmptyObject(this.reservationInfo)) {
          return
        }
        this.form.reservationId = this.reservationId
        this.form.vehicleId = this.reservationInfo.vehicleId
        this.form.evstationId = this.reservationInfo.evstationId
        ;(this.form.startDateTime = moment(this.reservationInfo.reservationStartDatetime).format(
          'YYYY-MM-DD HH:mm:ss'
        )),
          (this.form.endDateTime = moment(this.reservationInfo.reservationEndDatetime).format(
            'YYYY-MM-DD HH:mm:ss'
          )),
          (this.form.promotionCode = this.reservationInfo.promotionCode)
        this.form.passengerEmailList = this.reservationInfo.passengerEmailList
        this.temp.passengerEmailList = this.reservationInfo.passengerEmailList

        // 追加運転者
        this.form.nightpackApplied = this.reservationInfo.nightpackApplied == 1
        this.form.insuranceSubscribed = this.reservationInfo.insuranceSubscribed == 1
        if (
          this.reservationInfo.passengerEmailList &&
          this.reservationInfo.passengerEmailList.length > 0
        ) {
          this.togglePassenger()
        }
        this.form.paymentMethod = this.reservationInfo.paymentMethod
        // 利用目的
        this.form.usePurpose = this.reservationInfo.usePurpose
        this.form.usePurposeOther = this.reservationInfo.usePurposeOther
        // 目的地
        this.form.destination = this.reservationInfo.destination
        this.form.destinationLatitude = this.reservationInfo.destinationLatitude
        this.form.destinationLongitude = this.reservationInfo.destinationLongitude
      } else {
        // 新規
        this.form.vehicleId = this.vehicleId
        this.form.evstationId = this.evstationId
        this.form.startDateTime = this.startDateTime
        this.form.endDateTime = this.endDateTime
      }

      // 確認画面から戻ってきた場合は入力値を保持する
      const storeForm = this.$store.getters[FORM_GETTER_TYPES.VALUES]
      if (!isEmptyObject(storeForm)) {
        this.form = this.$_.cloneDeep(storeForm)
        if (this.form.destination) {
          document.getElementById('pac-input-reserve').value = this.form.destination
        }
      }

      this.time.startDate = moment(this.form.startDateTime).format(DATE_TIME_FORMAT.YYYYMMDD)
      this.time.startTime = moment(this.form.startDateTime).format(DATE_TIME_FORMAT.HHMM)
      this.time.endDate = moment(this.form.endDateTime).format(DATE_TIME_FORMAT.YYYYMMDD)
      this.time.endTime = moment(this.form.endDateTime).format(DATE_TIME_FORMAT.HHMM)

      await this.findVehicle(this.form.vehicleId, this.form.startDateTime)
      await this.findEvstation(this.form.evstationId)
      await this.getChargeUnit()
      await this.getInsurance()
      await this.findCode()

      const pac = document.getElementById('pac-input-reserve')
      // 目的地は車両の条件を取得してからセット
      if (this.reservationId && !this.isUsageOpen) {
        pac.value = this.form.destination
      } else if (pac) {
        // 前回の入力内容が残ってしまう為クリア
        pac.value = ''
      }

      this.getVehicleInfo()

      this.initPlaceSearchBox()

      // 予約可否チェックの前にdatepickerの日付をformに反映する（max/min)の設定での上書を取りこぼさないため
      this.form.startDateTime = this.unionStartDateTime.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss)
      this.form.endDateTime = this.unionEndDateTime.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss)

      this.isInitial = false
      this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
    },
    async mounted() {},
    methods: {
      togglePassenger() {
        this.isPassengerArea = !this.isPassengerArea
        if (this.temp.passengerEmailList.length == 0) this.temp.passengerEmailList.push('')
      },

      /** 場所検索BOX */
      initPlaceSearchBox() {
        if (window.google) {
          const inputElement = document.getElementById('pac-input-reserve')
          this.searchBox = new google.maps.places.SearchBox(inputElement)

          this.searchBox.addListener('places_changed', async () => {
            const places = await this.searchBox.getPlaces()
            console.log(places)
            if (places.length === 0) {
              return
            } else {
              const currentLocation = places[0]
              this.form.destination = document.getElementById('pac-input-reserve').value
              this.form.destinationLatitude = currentLocation.geometry.location.lat()
              this.form.destinationLongitude = currentLocation.geometry.location.lng()
            }
          })
        }
      },
      /**
       * 予約情報取得
       */
      async findReservation(id) {
        await reservationApi
          .findById(id)
          .then((ret) => (this.reservationInfo = ret.data[0]))
          .catch((err) => {
            this.validationErrorHook(err)
            this.$router.push('/')
            this.closeModal()
          })
          .finally(() => {})
      },

      /**
       * 汎用コード取得
       */
      async findCode() {
        const params = { codeKeys: 'use_purpose' }
        const ret = await codeApi.findAll(params)
        this.usePurposeList = ret.data[0].codeContainer
      },

      /**
       * 車両に紐づく予約取得
       */
      async findReserveList(vehicleId) {
        const param = {
          vehicleId: vehicleId,
          corpId: this.session.corpId,
          cleanMaintainFlag:
            this.session.cleaningCorp || this.session.maintenanceCorp ? true : null, // メンテナンス会員でない場合はNullをセットする
        }

        let reserve = await vehicleApi.getVehicleReserve(param)

        // 終日利用可以外の場合は利用不可時間をセット
        if (this.evInfo.allDayReservable == 0) {
          if (
            moment(this.evInfo.reservableStartTime, 'HH:mm') <
            moment(this.evInfo.reservableEndTime, 'HH:mm')
          ) {
            const stationStartReserve = {
              category: 4,
              startDatetime: '00:00:00',
              endDatetime: this.evInfo.reservableStartTime,
            }
            reserve.data.push(stationStartReserve)
            const stationEndReserve = {
              category: 4,
              startDatetime: this.evInfo.reservableEndTime,
              endDatetime: '24:00:00',
            }
            reserve.data.push(stationEndReserve)
          } else {
            const stationReserve = {
              category: 4,
              startDatetime: this.evInfo.reservableStartTime,
              endDatetime: this.evInfo.reservableEndTime,
            }
            reserve.data.push(stationReserve)
          }
        }

        //  set tooltip
        reserve.data.forEach((r) => {
          if (isEmptyObject(r.fullName)) {
            r.categoryName = Utils.getCategoryName(r.category)
          }
        })

        return reserve.data
      },

      // 予約可否チェック
      async availableCheck() {
        let reservationType
        if (this.reservation) {
          reservationType = this.reservation.reservationType
        } else if (this.isCorporateMember) {
          reservationType = RESERVATION_TYPE_CORPORATE_USE
        } else {
          reservationType = RESERVATION_TYPE_PERSONAL_USE
        }

        this.form.passengerEmailList = []
        this.temp.passengerEmailList.forEach((p) => {
          if (p.trim().length > 0) {
            this.form.passengerEmailList.push(p)
          }
        })

        const params = {
          corpId: this.session.corpId,
          reservationId: this.reservationId,
          vehicleId: this.vehicle.vehicleId,
          reservationType: reservationType,
          reservationStartDatetime: this.unionStartDateTime.format(
            DATE_TIME_FORMAT.YYYY_MM_DDHHmmss
          ),
          reservationEndDatetime: this.unionEndDateTime.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss),
          promotionCode: this.form.promotionCode,
          passengerEmailList: this.form.passengerEmailList,
        }

        this.temp.passengerEmailList = this.form.passengerEmailList

        // ナイトパック制御
        this.updateCheckableNightPack()

        const ret = await reservationApi
          .findAvailability(params)
          .then((ret) => {
            this.isAvailable = true
            // 法人枠内に収まる場合の処理
            this.hasCorpFrameInTime = ret.hasCorpFrameInTime
            if (this.hasCorpFrameInTime) {
              this.insuranceSubscribed = false
              // this.form.promotionCode = null;
            }

            return true
          })
          .catch(async (e) => {
            this.isAvailable = false
            const err = await this.validationErrorHook(e)

            // エラーメッセージ初期化
            this.errorMessage.promotion = ''
            this.errorMessage.passenger = []

            // プロモーション関連
            if (err.response && RegExp('プロモーション').test(err.response.message)) {
              this.errorMessage.promotion = err.response.message
            }

            // 追加運転者関連
            if (PASSENGER_ERROR.some((m) => RegExp(m).test(err.response.message))) {
              const validateMessList = err.response.message.split('\n')
              for (let i = 0; i < this.form.passengerEmailList.length; i++) {
                for (const validateMess of validateMessList) {
                  if (
                    this.form.passengerEmailList[i] &&
                    validateMess.includes(this.form.passengerEmailList[i])
                  ) {
                    this.errorMessage.passenger[i] = validateMess
                  }
                }
              }
            }
            return false
          })

        return ret
      },

      // 車輌情報取得
      async findVehicle(id, startDateTime) {
        const ret = await vehicleApi
          .findById(id, { reservationStartDatetime: startDateTime })
          .catch((e) => {
            this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
            this.validationErrorHook(e)
          })
        this.vehicle = ret.data[0]

        const lastWashDate = moment(this.vehicle.lastWashDatetime)
        lastWashDate.hours(0).minutes(0).seconds(0)
        this.vehicle.dayBeforeCarWash = moment().diff(lastWashDate, 'days')
      },
      async getVehicleInfo() {
        const v = this.vehicle

        if (v.vehicleId == null) {
          return
        }
        // deep copy
        v.timeListAm = this.$_.cloneDeep(TIME_AM)
        v.timeListPm = this.$_.cloneDeep(TIME_PM)

        // 2週間分の法人枠リストをセット
        v.cat56AmList = []
        v.cat56PmList = []
        this.dateList.forEach((d) => {
          v.cat56AmList.push({
            date: d.format(DATE_TIME_FORMAT.YYYYMMDD),
            list: this.$_.cloneDeep(TIME_AM),
          })
          v.cat56PmList.push({
            date: d.format(DATE_TIME_FORMAT.YYYYMMDD),
            list: this.$_.cloneDeep(TIME_PM),
          })
        })

        const rev = await this.findReserveList(v.vehicleId)
        await Utils.convertReservationList(rev)
        v.vehicleReservation = rev
        await v.vehicleReservation.forEach((r) => {
          r = Utils.getScheduleChartProp(r, v)
          if (r.category === 6) this.isReservationSpecific = true
        })
      },

      // EVステーション情報取得
      async findEvstation(id) {
        await evApi
          .findById(id)
          .then((ev) => {
            this.evInfo = ev.data[0].evstationContainer
            this.chargeInfo = ev.data[0].chargeContainer
            this.getReEnergy()
          })
          .catch((e) => {
            this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
            this.validationErrorHook(e)
          })
      },
      // 再エネ利用率取得
      async getReEnergy() {
        const result = await reEnergyApi.findOne(this.form.evstationId)
        this.listReEnergyRatio = result.data
        // Calculate re-en ratio
        this.calculateReenergyRatio()
      },

      /**
       * Calculate re-energy ratio after change reservation start time
       */
      calculateReenergyRatio() {
        const results = this.listReEnergyRatio.filter((ratio) => {
          const next = moment(ratio.predictiveTargetDatetime).add(30, 'minutes')
          return (
            moment(ratio.predictiveTargetDatetime) <= this.unionStartDateTime &&
            this.unionStartDateTime < next
          )
        })

        this.reEnergyRatio =
          results[0] && this.vehicle.ecocarType === 1
            ? Utils.calculateRenewEnergyPercent(results[0].renewableEnergyRatio)
            : 0
        if (this.reEnergyRatio < LOW_RATIO) {
          this.reEnergyRatioClass = CLASS_LOW
        } else if (this.reEnergyRatio < HIGHT_RATIO) {
          this.reEnergyRatioClass = CLASS_MEDIUM
        } else {
          this.reEnergyRatioClass = CLASS_HIGHT
        }
      },
      async getChargeUnit() {
        // Get charge unit of vehicle
        let memberId = null
        let corpFlg = null
        let maintenanceFlg = null
        if (this.session.corpId == null) {
          memberId = this.session.memberId
        } else if (this.session.carshareUseCorp) {
          corpFlg = true
        } else if (this.session.cleaningCorp || this.session.maintenanceCorp) {
          maintenanceFlg = true
        }

        const params = {
          date: this.unionStartDateTime.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss),
          vehicleTypeClass: this.vehicle.vehicleTypeClass,
          memberId: memberId,
          corpFlg: corpFlg,
          maintenanceFlg: maintenanceFlg,
        }
        const response = await chargeApi.findChargeUnit(params)
        this.chargeUnit = response.data[0]
        this.vehicle.timeCharge = this.chargeUnit.timeCharge
      },
      async getInsurance() {
        const params = {
          date: this.unionStartDateTime.format('YYYY-MM-DD'),
          corpId: CARSHARE_CORP_ID,
        }
        const result = await insuranceApi.findByReservationStartDate(params)
        this.insurancePlan = result.data[0]
      },
      /**
       * 予約日時変更時
       */
      async changeReservationDateTime() {
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
        await this.getChargeUnit()
        await this.getInsurance()
        this.calculateReenergyRatio()
        await this.availableCheck()
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
      },
      async goConfirm() {
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
        if (!(await this.availableCheck())) {
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
          return
        }

        if (!this.isUsageOpen) {
          // 目的地
          const place = document.getElementById('pac-input-reserve').value

          this.errorMessage.usePurpose = ''
          this.errorMessage.destination = ''

          if (
            isEmpty(this.form.usePurpose) ||
            (this.form.usePurpose == USE_PURPOSE_OTHER.value && isEmpty(this.form.usePurposeOther))
          ) {
            // 利用目的
            this.$_confirm.dialogOpen(PURPOSE_USE_REQUIRED)
            this.errorMessage.usePurpose = PURPOSE_USE_REQUIRED
            this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
            return
          } else if (isEmpty(place)) {
            // 目的地
            this.$_confirm.dialogOpen(LOCATION_DESTINATION_REQUIRED)
            this.errorMessage.destination = LOCATION_DESTINATION_REQUIRED
            this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
            return
          }

          if (this.form.destination != place) {
            this.form.destination = place
            this.form.destinationLongitude = null
            this.form.destinationLatitude = null
          }
        }

        //料金計算
        const param = {
          vehicleId: this.vehicle.vehicleId,
          chargePlanId: this.chargeUnit.chargePlanId,
          corpId: this.session.corpId,
          reservationStartDatetime: this.unionStartDateTime.format('YYYY-MM-DD HH:mm:ss'),
          reservationEndDatetime: this.unionEndDateTime.format('YYYY-MM-DD HH:mm:ss'),
          nightpackApplied: this.form.nightpackApplied ? 1 : 0,
          insuranceSubscribed: this.form.insuranceSubscribed ? 1 : 0,
          insurancePlanId: this.insurancePlan.insurancePlanId,
          promotionCode: this.form.promotionCode,
        }
        const estPriceApi = await chargeApi.findUsageCharge(param)

        this.estimatedTimeCharge = estPriceApi.data[0].timeCharge
        this.estimatedNightpackCharge = estPriceApi.data[0].nightpackCharge
        this.estimatedInsuranceCharge = estPriceApi.data[0].insuranceCharge
        this.discount = estPriceApi.data[0].discount
        this.promotionName = estPriceApi.data[0].promotionName
        this.totalEst =
          this.estimatedTimeCharge +
          this.estimatedNightpackCharge +
          this.estimatedInsuranceCharge -
          this.discount
        this.totalEst = this.totalEst > 0 ? this.totalEst : 0
        this.displayDuration = DateUtils.getDurationBetweenTime(
          this.unionStartDateTime,
          this.unionEndDateTime
        )

        this.form.startDateTime = this.unionStartDateTime.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss)
        this.form.endDateTime = this.unionEndDateTime.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss)

        this.saveForm()

        this.tranName = 'slide-right'
        this.isConfirm = true
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
      },
      backInput() {
        this.tranName = 'slide-left'
        this.isConfirm = false
        // レンダリングが済んでから値をセットする

        if (!this.isUsageOpen) {
          setTimeout(
            () => (document.getElementById('pac-input-reserve').value = this.form.destination),
            500
          )
        }
      },

      // vuex
      saveForm() {
        this.$store.commit(FORM_MUTATION_TYPES.SET_VALUES, this.$_.cloneDeep(this.form))
      },
      // vuex
      clearForm() {
        this.$store.commit(FORM_MUTATION_TYPES.CLEAR_VALUES)
      },

      /**
       * ナイトパック活性判定
       */
      updateCheckableNightPack() {
        this.nightPackDisable =
          this.chargeUnit.nightpackAvailable !== 1 ||
          !DateUtils.isBetWeen(
            this.unionStartDateTime,
            this.chargeUnit.nightpackApplicationStartTime,
            this.chargeUnit.nightpackApplicationEndTime
          )

        if (this.nightPackDisable) {
          this.form.nightpackApplied = false
        }
      },
      closeModal(isRefresh) {
        this.$emit('closeModal', isRefresh)
      },
      getCat56AmClassName(vehicle, index) {
        return vehicle.cat56AmList.find((d) => moment(d.date).isSame(this.selectDate, 'day')).list[
          index
        ].cn
      },
      getCat56PmClassName(vehicle, index) {
        return vehicle.cat56PmList.find((d) => moment(d.date).isSame(this.selectDate, 'day')).list[
          index
        ].cn
      },
      changeDate(date) {
        this.selectDateIndex = date
      },
      isToday(reserve) {
        // 24:00が翌日判定されるためendDateTimeは1分マイナスして評価する
        const startDate = moment(reserve.startDatetime).format('YYYY-MM-DD')
        const endDate = moment(reserve.endDatetime).subtract(1, 'minutes').format('YYYY-MM-DD')

        // ステーション利用不可時間はhhmmss形式で取得されるのでInvalid dateも描画対象
        return (
          (startDate == 'Invalid date' && endDate == 'Invalid date') ||
          moment(this.selectDate).format('YYYY-MM-DD') == startDate ||
          moment(this.selectDate).format('YYYY-MM-DD') == endDate
        )
      },
      /** 予約登録・変更 */
      async registReservation() {
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
        let param = {
          memberId: this.session.memberId,
          vehicleId: this.form.vehicleId,
          evstationId: this.form.evstationId,
          reservationStartDatetime: this.unionStartDateTime.format('YYYY-MM-DD HH:mm:ss'),
          reservationEndDatetime: this.unionEndDateTime.format('YYYY-MM-DD HH:mm:ss'),
          nightpackApplied: this.form.nightpackApplied ? 1 : 0,
          insuranceSubscribed: this.form.insuranceSubscribed ? 1 : 0,
          promotionCode: this.form.promotionCode,
          passengerEmailList: this.form.passengerEmailList,
          paymentMethod: this.form.paymentMethod,
          destination: this.form.destination,
          destinationLatitude: this.form.destinationLatitude,
          destinationLongitude: this.form.destinationLongitude,
          usePurpose: Number(this.form.usePurpose),
          usePurposeOther: this.form.usePurposeOther,
        }

        const location = this.evInfo.prefecturesName.concat(
          this.evInfo.city,
          this.evInfo.town,
          this.evInfo.otherAddress ? this.evInfo.otherAddress : ''
        )
        const calTitle = this.corpParam.serviceName
          .concat('(')
          .concat(this.evInfo.evstationName)
          .concat(')')
        const date =
          moment(param.reservationStartDatetime).format('YYYYMMDDTHHmmss') +
          '/' +
          moment(param.reservationEndDatetime).format('YYYYMMDDTHHmmss')

        const url = 'http://www.google.com/calendar/event?'
          .concat('action=TEMPLATE')
          .concat(`&text=${encodeURIComponent(calTitle)}`)
          .concat(`&details=${encodeURIComponent('')}`)
          .concat(`&location=${encodeURIComponent(location)}`)
          .concat(`&dates=${encodeURIComponent(date)}`)
          .concat('&trp=false')
        const msg =
          this.vehicle.onboardUnitInstalled === 1
            ? `<p>予約を受け付けました。<br>
          予約内容を登録メールアドレス宛に送信しました。<br>
          予約の変更・取り消しは予約一覧から行ってください。</p>`
            : `<p>予約を受け付けました。<br>
          予約された車両の解錠/施錠には物理キーが必要となりますので、<br>
          利用開始前にご準備ください。<br>
          予約変更・取り消しはマイページ（予約一覧）から行って下さい。</p>`
        const cal = `<a href="${url}" target="blank" class="googlecr"><img src="img/gg-cr.svg">Googleカレンダーに追加</a>`
        const title = '予約完了'

        if (this.reservationId) {
          param = { ...param, ...{ reservationId: this.reservationId } }
          await reservationApi
            .updateReservation(this.reservationId, param)
            .then((res) => {
              if (res.status === 302 && res['3ds_auth_id']) {
                this.secureRedirect(msg, res.outAcsHtml)
                return
              }

              this.$_confirm.dialogOpen(msg, title).then(async () => {
                this.closeModal()
                // マイページへ
                this.go(Config.URL.COMPLETE)
              })
            })
            .catch((e) => {
              this.validationErrorHook(e)
            })
            .finally(() => {
              this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
            })
        } else {
          await reservationApi
            .createReservation(param)
            .then((res) => {
              if (res.status === 302 && res['3ds_auth_id']) {
                this.secureRedirect(msg + cal, res.outAcsHtml)
                return
              }

              this.$_confirm.dialogOpen(msg + cal, title).then(async () => {
                this.closeModal()
                // マイページへ
                this.go(Config.URL.COMPLETE)
              })
            })
            .catch((e) => {
              this.validationErrorHook(e)
            })
            .finally(() => {
              this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
            })
        }
      },
      secureRedirect(msg, redirectHtml) {
        // 登録・変更完了時メッセージをセット
        this.$store.commit(MUTATION_TYPES.SET_RESERVATION_COMPLETE_MSG, msg)
        sessionStorage.setItem(
          Config.REDIRECT_URL,
          JSON.stringify({
            path: this.$route.path,
            query: this.$route.query,
          })
        )

        // 3DS認証画面のHTMLへリダイレクトさせる
        const blob = new Blob([redirectHtml], { type: 'text/html' })
        const url = URL.createObjectURL(blob)
        window.location.href = url
      },
    },
    computed: {
      selectDate() {
        return this.dateList[this.selectDateIndex]
      },
      title() {
        if (this.reservationId == null) {
          return this.isConfirm ? '予約確認' : '車両詳細・予約'
        } else {
          return this.isConfirm ? '予約変更確認' : '予約変更'
        }
      },
      /** 利用目的が一般向けシェアの場合true */
      isUsageOpen() {
        return this.vehicle.usagePattern === 1
      },
      formatStartDateTime() {
        return this.$filters.formatMMDDHHMM(this.unionStartDateTime)
      },
      formatEndDateTime() {
        return this.$filters.formatMMDDHHMM(this.unionEndDateTime)
      },
      /** 利用目的エイリアス */
      usePurposeAlias() {
        let ret = ''
        if (!isEmptyObject(this.usePurposeList) && this.form.usePurpose) {
          ret = this.usePurposeList.find((u) => u.codeKey == this.form.usePurpose).codeAlias
          ret += this.form.usePurposeOther ? ' ' + this.form.usePurposeOther : ''
        }
        return ret
      },
      unionStartDateTime() {
        return isEmptyObject(this.time.startDate) || isEmptyObject(this.time.startTime)
          ? moment()
          : moment(this.time.startDate + ' ' + this.time.startTime)
      },
      unionEndDateTime() {
        return isEmptyObject(this.time.endDate) || isEmptyObject(this.time.endTime)
          ? moment()
          : moment(this.time.endDate + ' ' + this.time.endTime)
      },
    },
    watch: {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a {
    cursor: pointer;
  }
  /* トランジション */
  .slide-right-leave-active,
  .slide-right-enter-active,
  .slide-left-leave-active,
  .slide-left-enter-active {
    transition: 0.5s ease-in-out;
    position: absolute;
  }

  .slide-right-enter-from,
  .slide-left-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }

  .slide-right-leave-to,
  .slide-left-enter-from {
    transform: translateX(-100%);
    opacity: 0;
  }
</style>
