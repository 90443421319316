export class Reservation {
  reservationId
  reservationType
  reservationStartDatetime
  reservationEndDatetime
  commitSoc

  constructor(reservationStartDatetime, reservationEndDatetime) {
    this.reservationStartDatetime = reservationStartDatetime
    this.reservationEndDatetime = reservationEndDatetime
  }
}
