<template>
  <div class="carousel">
    <transition-group
      tag="div"
      class="slider"
      :name="style"
      v-if="imgList"
      :class="isModal ? 'modal-cal' : 'normal-cal'"
    >
      <div v-for="(img, index) in imgList" :key="index" v-show="index === displayImg">
        <img :src="img" />
      </div>
      <div class="btn_left" :key="'btn1'" v-if="imgList.length > 1">
        <button type="button" class="button button-clear" @click="prev">
          <img src="img/prev-arrow.svg" class="slide-arrow prev-arrow" />
        </button>
      </div>
      <div class="btn_right" :key="'btn2'" v-if="imgList.length > 1">
        <button type="button" class="button button-clear" @click="next">
          <img src="img/next-arrow.svg" class="slide-arrow next-arrow" />
        </button>
      </div>
    </transition-group>
    <div class="footer" v-if="imgList.length > 1">
      <div class="center">
        <span
          class="point"
          v-for="(number, index) in imgList.length"
          :key="index"
          :class="{ active: index === displayImg }"
          @click="switchImg(index)"
          >●</span
        >
      </div>
    </div>
  </div>
</template>

<script>
  // import Loading from "vue-loading-overlay";

  export default {
    name: 'Carousel',
    props: {
      imgList: Array,
      isModal: { type: Boolean, default: false },
    },
    components: {},
    data() {
      return {
        displayImg: 0,
        style: '',
      }
    },
    created() {},
    mounted() {
      setInterval(this.next, 500000)
    },
    methods: {
      next() {
        if (this.displayImg >= this.imgList.length - 1) {
          this.displayImg = 0
        } else {
          this.displayImg++
        }
        this.style = 'slide-right'
      },
      prev() {
        if (this.displayImg == 0) {
          this.displayImg = this.imgList.length - 1
        } else {
          this.displayImg--
        }
        this.style = 'slide-left'
      },
      switchImg(index) {
        this.displayImg = index
        this.style = 'slide-right'
      },
    },
    computed: {},
    watch: {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .modal-cal {
    height: 528px;
    width: 807px;
  }
  .normal-cal {
    height: 125px;
  }

  .station_area .normal-cal {
    height: 142px;
  }

  #reserve .normal-cal {
    border-radius: 8px;
    /* min-height: 180px; */
  }
  .main {
    width: 80%;
    margin: 0 auto;
  }

  .carousel {
    position: relative;
    width: 100%;
  }

  .btn_left {
    position: absolute;
    top: 45%;
    left: 5px;
    z-index: 0;
    width: 20px;
  }

  .btn_right {
    position: absolute;
    top: 45%;
    right: 5px;
    z-index: 0;
    width: 20px;
  }

  .carousel button {
    width: 100%;
    height: 100%;
    font-size: 2em;
  }

  .slider {
    overflow: hidden;
    position: relative;
    margin: auto;
  }

  .carousel img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .center {
    width: 100%;
  }

  .footer {
    text-align: center;
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 5px;
  }

  .point {
    margin: 0.5em;
    cursor: pointer;
    color: white;
  }

  .point.active {
    color: #007ecb;
  }

  /* トランジション */
  .slide-right-leave-active,
  .slide-right-enter-active,
  .slide-left-leave-active,
  .slide-left-enter-active {
    transition: 0.5s ease-in-out;
  }

  .slide-right-enter-from,
  .slide-left-leave-to {
    transform: translateX(100%);
  }

  .slide-right-leave-to,
  .slide-left-enter-from {
    transform: translateX(-100%);
  }
</style>
