<template>
  <!--充電残量-->
  <div class="energy reserve_boxs">
    <div class="car_info_box">
      <div
        class="energy_info"
        v-if="ecocarType === 1"
        :class="{ not_enough: necessaryDistance > possibleDistance }"
      >
        <div class="energy_message" v-if="necessaryDistance <= possibleDistance">
          <p>
            今回の利用で途中充電は不要な見込みです。<br />ご利用開始時には予想走行距離分の充電は確保されます。
          </p>
        </div>
        <div class="energy_message not_enough" v-else>
          <p>ご利用途中で充電が必要になる可能性があります。</p>
        </div>
        <div
          class="energy_graph"
          data-necessary-energy="60"
          data-necessary-distance="120"
          data-possible-energy="80"
          data-possible-distance="160"
        >
          <div class="poss_nrg">
            ご利用開始時点で<span
              ><strong>{{ possibleDistance }}</strong
              >km</span
            >走行できる見込みです。
          </div>
          <div class="energy_graph_body">
            <div class="nrg_ttl">充電残量</div>
            <div class="ntg_right">
              <div class="nrg_empty"></div>
              <div class="nrg_fig">
                <div id="goal" class="goal"></div>
                <div id="curpos" class="curpos"></div>
                <div id="energyBars" class="nrg_bars"></div>
              </div>
              <div class="nrg_full"></div>
            </div>
          </div>
          <div class="nece_nrg">
            あなたの予想走行距離は<span
              ><strong>{{ necessaryDistance }}</strong
              >km</span
            >となります。
          </div>
        </div>
      </div>
      <div class="energy_info" v-else>
        <div class="energy_label">
          <div class="energy_fuel_box">
            <div class="energy_fuel_label">燃料残量</div>
            <div class="energy_fuel_body">
              <div class="energy_fuel_e">E</div>
              <div
                class="energy_fuel_bars"
                :class="{
                  level_0: fuelLevel === 0 || fuelLevel == null,
                  level_1: fuelLevel === 25,
                  level_2: fuelLevel === 50,
                  level_3: fuelLevel === 75,
                  level_4: fuelLevel === 100,
                }"
              >
                <div class="energy_fuel_bar"></div>
                <div class="energy_fuel_bar"></div>
                <div class="energy_fuel_bar"></div>
                <div class="energy_fuel_bar"></div>
              </div>
              <div class="energy_fuel_f">F</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--充電残量-->
</template>

<script>
  import { ChartUtils } from '@/helpers/ChartUtils'
  import { EcocarType } from '@/constants/constant'

  export default {
    name: 'VehicleEnergyGraph',
    props: {
      necessaryDistance: Number,
      possibleDistance: Number,
      necessaryEnergy: Number,
      possibleEnergy: Number,
      ecocarType: Number,
      fuelLevel: Number,
    },
    components: {},
    data() {
      return {
        idGraph: '',
      }
    },
    methods: {
      /**
       * Draw chart
       */
      drawEnergyChart() {
        ChartUtils.drawEnergyChart(this.necessaryEnergy, this.possibleEnergy, this.idGraph)
      },
    },
    mounted() {
      if (this.ecocarType === EcocarType.EV) {
        this.drawEnergyChart()
      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
