<template>
  <div class="password">
    <h1 class="page_name">パスワード再設定</h1>
    <Loading
        :active="processing"
        :loader="'dots'"
        :color="'#007ecb'"
        :can-cancel="true"
        :is-full-page="true"
    />
    <Form @submit="onSubmit">
      <main class="cf">
        <section class="common_section reminder remider2">
          <div class="nobox">
            <div class="ninshou">
              <ul class="flex">
                <li>
                  <h4>認証コード</h4>
                  <Field
                      placeholder="認証コード"
                      type="text"
                      name="confirmationCode"
                      label="認証コード"
                      v-model="form.confirmationCode"
                      rules="required"
                      id="verifyCode"
                      class="maxw code" />
                  <ErrorMessage name="confirmationCode" class="err_msg" id="err_verify_msg" />
                </li>
                <li><p class="text">入力されたメールアドレス宛に認証コードを送りました。<br>
                  メールに記載の認証コードを入力してください。</p></li>
              </ul>
            </div>
            <div class="login_input">
              <ul class="flex">
                <li>
                  <h4>新しいパスワード</h4>
                  <div class="passwordInput">
                  <Field
                      placeholder="新しいパスワード"
                      type="password"
                      name="password"
                      label="新しいパスワード"
                      v-model="form.password"
                      rules="required|betweenMinMaxLength:新しいパスワード,8,16|passwordPattern:新しいパスワード"
                      id="password"
                      class="maxw login_password" />
                  </div>
                  <ErrorMessage name="password" class="err_msg" id="err_msg_password" />
                </li>
                <li>
                  <h4>新しいパスワード（確認用）</h4>
                  <div class="passwordInput">
                    <Field
                        placeholder="新しいパスワード（確認用）"
                        type="password"
                        name="confirmPassword"
                        label="新しいパスワード（確認用）"
                        v-model="form.confirmPassword"
                        rules="required|betweenMinMaxLength:新しいパスワード（確認用）,8,16|passwordPattern:新しいパスワード（確認用）|confirmed:@password,新しいパスワード,新しいパスワード（確認用）"
                        id="confirmPassword"
                        class="maxw login_password" />
                  </div>
                  <ErrorMessage name="confirmPassword" class="err_msg" id="err_confirm_password" />
                </li>
              </ul>
            </div>
          </div>
        </section>

        <p class="memo2" v-if="listServices && listServices.length > 1">【注意事項】<br>以下のカーシェアリングサービス全てのアカウントパスワードが変更されます。</p>
        <section class="common_section reminder remider2box" v-if="listServices && listServices.length > 1">
          <div class="exchange_area">
            <ul class="exchange_items" >
              <li :key="service" class="exchange_item" v-for="service in listServices">
                <img :src="service.appImageUrl" :alt="service.serviceName">
                <span>{{ service.serviceName }}</span>
              </li>
            </ul>
          </div>
        </section>
        <div class="btn_area login_btn">
          <button type="submit" class="btn_l btn_blue" id="form-submit">OK</button>
          <button type="button" class="btn_l btn_neblue" ><a id="resetPasswordLink" @click="$router.go(-1)">キャンセル</a></button>
        </div>
      </main>
    </Form>
    <app-footer :isLoginFooter="false"></app-footer>
  </div>
</template>
<script>
import Config from '@/conf/Config'
import { Field, Form, ErrorMessage } from "vee-validate";
import Auth from "@/module/Auth";
import Loading from "vue-loading-overlay";
import {PASSWORD_MESSAGE} from "@/conf/Message";
import {translateApi} from "@/module/Api";
import {MUTATION_TYPES} from "@/store";
import {API_ERROR_CODE} from "@/constants/constant.api";
import {MESSAGE} from "@/constants/constant.message";
import Logger from '@/module/Logger'

export default {
  name: "ConfirmPassword",
  components: {
    Field,
    Form,
    ErrorMessage,
    Loading,
  },
  created() {
    document.cookie = Config.FUNCTION_ID + this.screenId
    this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
    this.listServices = this.$store.state.listServices;
  },
  data() {
    return {
      inquiryName:null,
      visible:false,
      form : {
        confirmationCode: '',
        password:'',
        confirmPassword: ''
      },
      listServices :[],
    };
  },
  methods: {
    async onSubmit(values) {
      this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
      try {
        const cognitoUser = this.$store.state.cognitoUser;
         await Auth.confirmPassword(cognitoUser,values.confirmationCode, values.password)
            .then(async () => {
              await this.$_confirm.dialogOpen(PASSWORD_MESSAGE.RESET_PASSWORD_SUCCESS);
              this.$router.go(-2)
            })
            .catch(async err => {
              let errMess;
              if(err.code === API_ERROR_CODE.VERIFY_CODE_MISMATCH){
                errMess = MESSAGE.VERIFY_CODE_MISMATCH;
              } else {
                errMess = await translateApi.translateEnglish(err.message)
              }
              this.$_confirm.dialogOpen(errMess).then();
            })
      } catch (e){
        Logger.info('new password()', e)
      } finally {
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
      }
    },
  },
  computed: {
      screenId: () => "CONFIRM_PASSWORD", // <--- 個別に定義
  }
};
</script>
<style scoped>
.err_msg {
  max-width: 480px;
  margin: 5px auto 0;
}
.passwordInput{
  position: relative;
}
.passwordInput i{
  position: absolute;
  right: 9px;
  top: 50%;
  transform: translateY(-50%);
}
</style>