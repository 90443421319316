<template>
  <div class="vehicle reserve_boxs">
    <ul class="reserved">
      <li>
        <div class="booking-cont-main-child-images">
          <div class="booking-cont-main-child-images-child">
            <div class="newcar_flag roboto" v-show="vehicle.isNewCar">NEW CAR</div>
            <img :src="vehicle.vehicleImageUrl" class="" />
          </div>
          <div class="booking-cont-main-child-price" v-if="isShowTimeCharge">
            <span v-if="isUsageOpen">
              <b>{{ vehicle.timeCharge }}</b>
              <span class="scale8">円</span>/15
              <span class="scale8">分</span>
            </span>
            <b>{{ vehicle.seatingCapacity }}</b
            >人乗り
          </div>

          <div
            class="manual_btn"
            v-if="vehicle.useDriveUrl && statusReservation && statusReservation < 4"
          >
            <a :href="vehicle.useDriveUrl" target="blank"><img src="img/icon_manual.svg" />利用開始方法</a>
          </div>
          <div
            class="manual_btn"
            v-if="
              vehicle.vehicleManualUrl &&
              ((statusReservation && statusReservation >= 4) || !statusReservation)
            "
          >
            <a :href="vehicle.vehicleManualUrl" target="blank"><img src="img/icon_manual.svg" />車両マニュアル</a>
          </div>
          <div class="car-keytype type-p" v-if="vehicle.onboardUnitInstalled === 0">
            物理キーで解錠/施錠
          </div>
          <div class="car-keytype type-a" v-if="!isUsageOpen && vehicle.onboardUnitInstalled === 1">
            アプリで解錠/施錠
          </div>
        </div>
        <div class="booking-cont-main-child-option">
          <div class="blockttl">
            <span><img src="img/title_car.svg" /></span>
            <p>車両情報</p>
          </div>
          <div class="booking-cont-main-child-ttl">
            <h4 class="booking-cont-main-child-name">
              {{ vehicle.vehicleName }}
            </h4>
            <span v-if="vehicle.ecocarType == 1" class="label-ev">EV</span>
            <span v-if="vehicle.ecocarType == 2" class="label-normal">PHEV</span>
            <span v-if="vehicle.ecocarType == 3" class="label-normal">ガソリン車</span>
          </div>
          <div class="booking-cont-main-child-option-child2">
            <p>車種：{{ vehicle.vehicleTypeName }}</p>
            <p>
              {{ vehicle.vinPlace }} {{ vehicle.vinClassNumber }} {{ vehicle.vinKana }}
              {{ vehicle.vinSeriesNumber }}
            </p>
          </div>
          <div class="booking-cont-main-child-option-child2">
            <p>カラー：{{ vehicle.vehicleColor }}</p>
            <p v-if="vehicle.installedSection">駐車位置：{{ vehicle.installedSection }}</p>
            <p>クラス：{{ vehicle.vehicleTypeClassName }}</p>
          </div>
          <div class="booking-cont-main-child-option-child3">
            <p>車両タイプ：{{ vehicle.vehicleBodyTypeAlias }}</p>
            <p class="label" v-for="alias in getVehicleusageAlias(vehicle)" :key="alias">
              {{ alias }}
            </p>
          </div>
          <div class="booking-cont-main-child-option-child4">
            <div class="option-icon">
              <img
                :src="
                  isEquipment(vehicle.equipmentList, '1')
                    ? 'img/icon_eq_navi.svg'
                    : 'img/icon_eq_navi_off.svg'
                "
              />
            </div>
            <div class="option-icon">
              <img
                :src="
                  isEquipment(vehicle.equipmentList, '2')
                    ? 'img/icon_eq_smapho.svg'
                    : 'img/icon_eq_smapho_off.svg'
                "
              />
            </div>
            <div class="option-icon">
              <img
                :src="
                  isEquipment(vehicle.equipmentList, '3')
                    ? 'img/icon_eq_parking.svg'
                    : 'img/icon_eq_parking_off.svg'
                "
              />
            </div>
            <div class="option-icon">
              <img
                :src="
                  isEquipment(vehicle.equipmentList, '4')
                    ? 'img/icon_eq_jrseat.svg'
                    : 'img/icon_eq_jrseat_off.svg'
                "
              />
            </div>
            <div class="option-icon">
              <img
                :src="
                  isEquipment(vehicle.equipmentList, '5')
                    ? 'img/icon_eq_childseat.svg'
                    : 'img/icon_eq_childseat_off.svg'
                "
              />
            </div>
            <div class="option-icon">
              <img
                :src="
                  isEquipment(vehicle.equipmentList, '6')
                    ? 'img/icon_eq_autobrake.svg'
                    : 'img/icon_eq_autobrake_off.svg'
                "
              />
            </div>
            <div class="option-icon">
              <img
                :src="
                  isEquipment(vehicle.equipmentList, '7')
                    ? 'img/icon_eq_4wd.svg'
                    : 'img/icon_eq_4wd_off.svg'
                "
              />
            </div>
            <div class="option-icon">
              <img
                :src="
                  isEquipment(vehicle.equipmentList, '8')
                    ? 'img/icon_eq_studless.svg'
                    : 'img/icon_eq_studless_off.svg'
                "
              />
            </div>
            <div class="option-icon">
              <img
                :src="
                  isEquipment(vehicle.equipmentList, '9')
                    ? 'img/icon_eq_rec.svg'
                    : 'img/icon_eq_rec_off.svg'
                "
              />
            </div>
            <div class="option-icon">
              <img
                :src="
                  isEquipment(vehicle.equipmentList, '10')
                    ? 'img/icon_eq_lanekeep.svg'
                    : 'img/icon_eq_lanekeep_off.svg'
                "
              />
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import { isEmptyObject } from '@/helpers/validators'
  export default {
    name: 'VehicleInfo',
    props: {
      vehicle: {},
      statusReservation: {
        type: Number,
        default: () => null,
      },
      isShowTimeCharge: {
        type: Boolean,
        default: () => true,
      },
    },
    components: {},
    data() {
      return {}
    },
    created() {},
    mounted() {},
    methods: {
      getVehicleusageAlias(vehicle) {
        return vehicle.usageAliasList ? vehicle.usageAliasList.split(',') : []
      },
      isEquipment(equipmentList, equipmentCode) {
        return isEmptyObject(equipmentList)
          ? false
          : equipmentList.split(',').includes(equipmentCode)
      },
    },
    computed: {
      /** 利用目的が一般向けシェアの場合true */
      isUsageOpen() {
        return this.vehicle.usagePattern === 1
      },
    },
    watch: {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
