<template>
  <h2>登録完了</h2>
</template>
<script>
  import Config from '@/conf/Config'
  export default {
    components: {},
    created() {
      console.log('予約完了')
      this.go(Config.URL.MYPAGE)
    },
    async mounted() {},
    data() {
      return {}
    },
    methods: {},
  }
</script>
<style scoped></style>
