<template>
  <div class="password">
    <h1 class="page_name">パスワード再設定</h1>
    <Loading
        :active="processing"
        :loader="'dots'"
        :color="'#007ecb'"
        :can-cancel="true"
        :is-full-page="true"
    />
    <Form @submit="handlerSubmit">
      <main class="cf password1">
        <section class="common_section reminder">
          <h2>パスワードを忘れた方へ</h2>
          <div class="nobox">
            <p v-if="corpParamContents.COMM_005" class="body_text">１. メールアドレス入力欄に登録済みのメールアドレスを入力し、「送信」ボタンをクリックしてください。<br><br>
              ２. 入力したメールアドレス宛に、認証コードが記載されたメールが届きます。<br><br>
              ※メールアドレスが分からない場合は{{ corpParamContents.COMM_005.article.InquiryName }}までご連絡ください。</p>
            <div class="login_input">
              <Field
                  placeholder="メールアドレス"
                  id="email"
                  type="text" name="email"
                  label="メールアドレス"
                  v-model="form.email"
                  rules="required|email|max:128"
                  class="maxw login_email " />
              <ErrorMessage name="email" class="err_msg" id="email_err_msg" />
            </div>
            <div class="btn_area login_btn">
              <button type="submit" class="btn_l btn_blue" id="form-submit">OK</button>
              <button type="button" class="btn_l btn_neblue" id="cancelButton" @click="$router.go(-1)"><a >キャンセル</a></button>
            </div>
          </div>
        </section>
      </main>
    </Form>
    <app-footer :isLoginFooter="false"></app-footer>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from "vee-validate";
import Auth from "@/module/Auth";
import Loading from "vue-loading-overlay";
import {commonApi, translateApi} from "@/module/Api";
import {MUTATION_TYPES} from "@/store";
import Config from "@/conf/Config";
import {EXCLUSION_FLAG} from "@/constants/constant";
import {API_ERROR_CODE} from "@/constants/constant.api";
import {MESSAGE} from "@/constants/constant.message";
import Logger from "@/module/Logger";

export default {
  name: "ResetPassword",
  components: {
    Field,
    Form,
    ErrorMessage,
    Loading,
  },
  created() {
    document.cookie = Config.FUNCTION_ID + this.screenId
    this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
  },
  data() {
    return {
      inquiryName:null,
      visible:false,
      cognitoUser: null,
      form : {
        email: ''
      }
    };
  },
  methods: {
     async handlerSubmit(values){
      this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
      try {
        this.form.email = values.email;
        await Auth.resetPassword(values.email)
             .then(async cognitoUser => {
               if (cognitoUser){
                 this.cognitoUser = cognitoUser.username;
                 this.$store.commit(MUTATION_TYPES.SET_COGNITO_USER, this.cognitoUser)
               }
               await this.getListService()
               this.$router.push(Config.URL.CONFIRM_PASSWORD)
             })
             .catch( async err => {
               let errMess;
               if (err.code === API_ERROR_CODE.EMAIL_NOT_REGISTERED){
                 errMess = MESSAGE.EMAIL_NOT_REGISTERED;
               } else {
                 errMess = await translateApi.translateEnglish(err.message)
               }
               this.$_confirm.dialogOpen(errMess).then();
             });
      } catch (e) {
        Logger.info('resetPassword()', e)
      } finally {
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
      }
    },
    async getListService(){
        const data = {
          email: this.form.email,
          exclusionFlg : EXCLUSION_FLAG.include
        }
        const response = await commonApi.getListService(data)
        this.$store.commit(MUTATION_TYPES.SET_LIST_SERVICES, response.data)
    },
  },
  computed: {
    screenId: () => "RESET_PASSWORD", // <--- 個別に定義
  }
};
</script>
<style scoped>
.err_msg {
  max-width: 480px;
  margin: 20px auto 0;
}
</style>