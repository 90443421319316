<template>
  <div class="reserve_right">
    <div id="playing">
      <Form @submit="onSubmit" :validation-schema="alcoholSchema" v-slot="{ errors }">
        <div class="playing-ttl rese_status" :class="statusClass">
          <div class="status_icon"></div>
          {{ statusMessage }}
        </div>
        <ul>
          <li>
            <div class="terms2">
              <h4>ご利用時間</h4>
              <div class="rese_date roboto">
                <div class="start_date">
                  {{ $filters.formatYYYYMMDD(reservationInfo.reservationStartDatetime) }}
                  <span class="dow">{{ startDayOfWeek }}</span
                  ><span class="oclock">{{
                    $filters.formatDateHHmm(reservationInfo.reservationStartDatetime)
                  }}</span>
                </div>
                <div class="term_dash"></div>
                <div class="end_date">
                  {{ $filters.formatYYYYMMDD(reservationInfo.reservationEndDatetime)
                  }}<span class="dow">{{ endDayOfWeek }}</span
                  ><span class="oclock">{{
                    $filters.formatDateHHmm(reservationInfo.reservationEndDatetime)
                  }}</span>
                </div>
              </div>
            </div>
            <a class="googlecr" @click="openCalendarUrl" id="openCalendar"
              ><img src="img/gg-cr.svg" />Googleカレンダーに追加する</a
            >
          </li>
          <div
            class="alcohol_check"
            v-if="
              [2, 3, 4].includes(statusReservation) &&
              reservationInfo.usagePattern === 2 &&
              reservationInfo.alcoholChecked === 1
            "
          >
            <h3>アルコールチェック</h3>
            <h4>酒気の有無</h4>
            <div>
              <label
                v-for="(al, index) in alcoholCheckIntoxicatedList"
                :key="index"
                :for="`alkey-${al.key}`"
              >
                <Field
                  :key="index"
                  placeholder="酒気の有無"
                  type="radio"
                  name="intoxicated"
                  v-model="form.alcoholCheckContainer.intoxicated"
                  :class="[
                    'intoxicated',
                    {
                      err: errors.intoxicated,
                    },
                  ]"
                  :value="al.key"
                  label="酒気の有無"
                  :id="`alkey-${al.key}`"
                >
                </Field>
                {{ al.value }}
              </label>

              <ErrorMessage name="intoxicated" class="err_msg"/>
            </div>
            <div class="side-minicont">
              <h4>確認者名</h4>
              <Field
                placeholder="確認者名"
                type="text"
                name="confirmor"
                v-model="form.alcoholCheckContainer.confirmor"
                :class="[
                  'confirmor',
                  {
                    err: errors.confirmor,
                  },
                ]"
                label="確認者名"
                max-length="100"
                id="confirmor"
              />
              <ErrorMessage name="confirmor" class="err_msg"/>
            </div>
            <div class="side-minicont">
              <h4>確認方法</h4>
              <Field
                as="select"
                name="confirmationMethod"
                v-model="form.alcoholCheckContainer.confirmationMethod"
                :class="[
                  'confirmationMethod',
                  {
                    err: errors.confirmationMethod,
                  },
                ]"
                label="確認方法"
                id="confirmationMethod"
              >
                <option v-for="t in alcoholCheckConfirmationMethodList" :value="t.key" :key="t.key">
                  {{ t.value }}
                </option>
              </Field>
              <ErrorMessage name="confirmationMethod" class="err_msg"  />
            </div>
            <div class="side-minicont">
              <h4>確認方法(その他)</h4>
              <Field
                type="text"
                name="confirmationMethodDetail"
                v-model="form.alcoholCheckContainer.confirmationMethodDetail"
                :class="[
                  'confirmationMethodDetail maxw2',
                  {
                    err: errors.confirmationMethodDetail,
                  },
                ]"
                label="確認方法(その他)"
                max-length="1000"
                :disabled="form.alcoholCheckContainer.confirmationMethod !== '99'"
                id="confirmationMethodDetail"
              />
              <ErrorMessage
                name="confirmationMethodDetail"
                class="err_msg"
              />
            </div>
            <div class="side-minicont">
              <h4>指示事項等</h4>
              <Field
                as="textarea"
                name="indicatedItems"
                v-model="form.alcoholCheckContainer.indicatedItems"
                :class="[
                  'indicatedItems',
                  {
                    err: errors.indicatedItems,
                  },
                ]"
                label="指示事項等"
                max-length="1000"
                id="indicatedItems"
              />
              <ErrorMessage name="indicatedItems" class="err_msg"/>
            </div>
          </div>

          <li class="btnarea">
            <p
              class="btnarea_atention"
              v-for="(attention, index) in attentionList"
              :key="index"
              v-html="attention"
            />
            <button
              type="button"
              class="submit time-upload"
              v-if="
                statusReservation === 4 &&
                !noExtend &&
                reservationInfo.delayUsingStartDatetime == null
              "
              @click="openModalSelectTimeExtend"
              id="extendTimeButton"
            >
              <img src="img/time-upload.svg" />予約時間を延長する
            </button>
            <button
              type="button"
              class="submit edit"
              :disabled="statusReservation > 2"
              @click="gotoEditReservation"
              v-if="statusReservation < 4"
              id="editButton"
            >
              変更する
            </button>
            <button
              type="button"
              class="submit derete"
              @click="clickCancelReservation"
              v-if="statusReservation < 4"
              id="cancelButton"
            >
              取り消す
            </button>
            <button
              type="button"
              class="btn_l btn_blue btn_unlock"
              disabled
              v-if="statusReservation == 1"
              id="unlockBeforeTenMinute"
            >
              <template v-if="reservationInfo.onboardUnitInstalled == 1"
                >※{{ startableTime }}分前から解錠できます</template
              >
              <template v-else>※{{ startableTime }}分前から利用開始できます</template>
            </button>
            <button
              type="submit"
              class="submit unlock btnbl"
              :disabled="reservationInfo.inactiveUnlockButton"
              v-if="statusReservation == 2 || statusReservation == 3"
              id="startUsingButton"
            >
              <template v-if="reservationInfo.onboardUnitInstalled == 1">利用開始（解錠）</template>
              <template v-else>利用開始</template>
            </button>
            <button
              type="submit"
              class="submit lock btnbl"
              v-if="statusReservation == 4 || statusReservation == 6"
              id="stopButton"
            >
              <template v-if="reservationInfo.onboardUnitInstalled == 1">返却手続き（施錠）</template>
              <template v-else>返却手続き</template>
            </button>
            <button
              type="submit"
              class="submit unlock btnbl"
              v-if="statusReservation == 5"
              :disabled="isDisableButtonUnlockForgetItem"
              id="checkLostItemButton"
            >
              <template v-if="reservationInfo.onboardUnitInstalled == 1"
                >忘れ物確認（解錠）</template
              >
              <template v-else>忘れ物確認</template>
            </button>
          </li>
        </ul>
      </Form>
    </div>
    <div class="additional_time">
      <div class="additional_time_body">
        <div class="additional_close" @click="closeModalSelectTimeExtend">
          <img src="img/additional_close.svg" />
        </div>
        <div class="additional_ttl">ご利用時間の延長</div>
        <div class="extend_action">
          <strong
            >最大{{ (maxExtendTime - (maxExtendTime % 60)) / 60 }}時間{{
              maxExtendTime % 60
            }}分</strong
          >延長できます。
        </div>
        <div class="extend_data">
          <div class="margin-x-auto w-fit-content">
            <el-date-picker
              v-model="extendDate"
              type="date"
              placeholder="日時を選択"
              :format="'MM/DD'"
              value-format="YYYY-MM-DD"
              :clearable="false"
              :editable="false"
              :disabled-date="disableStartDate"
              :class="'date_picker'"
              @change="changeExtendTime"
            />
            <el-time-select
              v-model="extendMinute"
              placeholder="End time"
              start="00:00"
              step="00:15"
              end="23:45"
              :max-time="maxExtendMinute"
              :min-time="minExtendMinute"
              :editable="false"
              :clearable="false"
              :format="'HH:mm'"
              @change="changeExtendTime"
            />
          </div>
        </div>
        <div class="btn_area_ex">
          <button class="submit time-upload" @click="clickExtendButton">
            <img src="img/time-upload_wh.svg" />延長する
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { STATUS_RESERVATION } from './Mypage'
  import { CALENDAR_URL, OnboardUnitInstalled, WEEK_DAYS, UsagePattern } from '@/constants/constant'
  import moment from 'moment'
  import { MUTATION_TYPES, GETTER_TYPES } from '@/store'
  import { Utils } from '@/helpers/Utils'
  import {
    MENU002_CANCEL_RESERVE_CONFIRM,
    MENU002_EXTEND_CONFIRM,
    MENU002_EXTEND_SUCCESS,
    MENU002_LOCK_FAIL,
    MENU002_LOCK_FORGOT_CONFIRM,
    MENU002_LOCK_FORGOT_SUCCESS,
    MENU002_UNLOCK_CONFIRM,
    MENU002_UNLOCK_FAIL,
    MENU002_UNLOCK_FORGOT_CONFIRM,
    MENU002_UNLOCK_FORGOT_ITEMS_FAIL,
    MENU002_UNLOCK_FORGOT_ITEMS_SUCCESS,
    MENU002_UNLOCK_SUCCESS,
    RESERVATION_CANCEL_SUCCESS,
  } from '@/constants/constant.dialog'
  import {
    questionnaireApi,
    reservationCancelApi,
    reservationExtentionTimeApi,
    reservationStartApi,
    reservationStopApi,
    reservationApi,
    codeApi,
  } from '@/module/Api'
  import Config from '@/conf/Config'
  import { API_ERROR_CODE } from '@/constants/constant.api'
  import { API_ERROR_MESSAGE } from '@/conf/Message'
  import { DATE_TIME_FORMAT } from '@/constants/constant'
  import { isEmptyObject } from '@/helpers/validators'
  import { extractCodebyCodeKey } from '@/helpers'
  import _ from 'lodash'
  import { Field, Form, ErrorMessage } from 'vee-validate'

  export const VAL_10_MINUTES = 10
  export const VAL_15_MINUTES = 15
  export const VAL_30_MINUTES = 30
  export const VAL_60_MINUTES = 60
  export const VAL_72_HOURS = 72

  export class ExtendTimeBody {
    extentionEndDatetime = null
    constructor(extendDateTime) {
      this.extentionEndDatetime = moment(extendDateTime).format('YYYY-MM-DD HH:mm:ss')
    }
  }

  export default {
    name: 'ReserveRight',
    components: {
      Field,
      Form,
      ErrorMessage,
    },
    props: {
      statusReservation: { type: Number, default: null },
      reservationInfo: {},
    },
    data() {
      const alcoholSchema = {
        confirmor: 'required|max:100',
        confirmationMethod: 'required',
        confirmationMethodDetail: 'relationRequired:確認方法(その他),@confirmationMethod|max:1000',
        intoxicated: 'required',
        indicatedItems: 'max:1000',
      }
      return {
        statusMessage: null,
        statusClass: null,
        maxExtendTime: null,
        maxExtendDateTime: null,
        maxExtendDate: null,
        maxExtendMinute: null,
        minExtendDateTime: null,
        minExtendDate: null,
        minExtendMinute: null,
        extendDate: null,
        extendMinute: null,
        extendTime: null,
        noExtend: null,
        alcoholSchema,
        form: {
          alcoholCheckContainer: {
            confirmor: '',
            confirmationMethod: '',
            confirmationMethodDetail: '',
            intoxicated: '',
            indicatedItems: '',
          },
        },
        alcoholCheckConfirmationMethodList: [],
        alcoholCheckIntoxicatedList: [],
      }
    },
    computed: {
      startDayOfWeek() {
        return WEEK_DAYS[moment(this.reservationInfo.reservationStartDatetime).toDate().getDay()]
      },
      endDayOfWeek() {
        return WEEK_DAYS[moment(this.reservationInfo.reservationEndDatetime).toDate().getDay()]
      },
      /**
       * Check condition to inactive button unlock-vehicle in the case forget items
       */
      isDisableButtonUnlockForgetItem() {
        return (
          this.reservationInfo.reservationReturnDelayCnt > 0 ||
          moment() > moment(this.reservationInfo.usingEndDatetime).add(VAL_30_MINUTES, 'minutes') ||
          this.existNearReservation
        )
      },
      existNearReservation() {
        if (this.reservationInfo.otherNextReservationStartDatetime == null) {
          return false
        }
        return (
          moment(this.reservationInfo.usingEndDatetime).add(VAL_60_MINUTES, 'minutes') >
          moment(this.reservationInfo.otherNextReservationStartDatetime)
        )
      },
      attentionList() {
        const attentions = []
        if (
          this.statusReservation < STATUS_RESERVATION.STATUS_3 &&
          this.reservationInfo.returnDelayImpactStartDatetime &&
          this.reservationInfo.reservationStatus == 1 &&
          this.reservationInfo.vehicleStatus != 2
        ) {
          attentions.push(
            '前の利用者の方が遅延しているため、お客様の予約開始時間が短縮されています。'
          )
        }
        if (this.statusReservation === STATUS_RESERVATION.STATUS_3) {
          attentions.push('開始時間を過ぎているため変更はできません')
        }
        if (
          this.statusReservation === STATUS_RESERVATION.STATUS_4 &&
          this.reservationInfo.delayUsingStartDatetime
        ) {
          attentions.push('返却予定時間を超過したため延長できません。')
        }
        if (
          this.statusReservation === STATUS_RESERVATION.STATUS_4 &&
          this.noExtend &&
          this.reservationInfo.delayUsingStartDatetime == null &&
          this.isMaxTimeReservation
        ) {
          attentions.push('予約時間が72時間を超えるため延長できません。')
        }
        if (
          this.statusReservation === STATUS_RESERVATION.STATUS_4 &&
          this.noExtend &&
          this.reservationInfo.delayUsingStartDatetime == null &&
          !this.isMaxTimeReservation
        ) {
          attentions.push('次の予約が入っているため延長できません。')
        }
        if (
          this.statusReservation === STATUS_RESERVATION.STATUS_4 &&
          this.reservationInfo.reservationReturnDelayCnt > 0 &&
          this.reservationInfo.delayUsingStartDatetime == null
        ) {
          attentions.push(
            '帰着予想時間が返却予定時間を超過しています。予定時間までの帰着が困難な場合は延長してください。'
          )
        }
        if (
          (this.statusReservation === STATUS_RESERVATION.STATUS_4 &&
            this.reservationInfo.delayUsingStartDatetime) ||
          (this.statusReservation === STATUS_RESERVATION.STATUS_6 &&
            this.reservationInfo.vehicleStatus == 3 &&
            this.reservationInfo.leftBehindUnlockStartDatetime != null &&
            this.reservationInfo.leftBehindUnlockEndDatetime == null &&
            this.reservationInfo.reservationReturnDelayCnt > 0)
        ) {
          attentions.push('返却予定時間を超過しました。<br>速やかにご返却をお願いいたします。')
        }
        if (this.statusReservation === STATUS_RESERVATION.STATUS_6) {
          if (this.reservationInfo.onboardUnitInstalled === 1) {
            attentions.push('※解錠後、１０分以内に施錠してください。')
          } else {
            attentions.push('※確認後、１０分以内に利用終了してください。')
          }
        }
        if (
          this.statusReservation === STATUS_RESERVATION.STATUS_5 &&
          this.reservationInfo.reservationReturnDelayCnt > 0
        ) {
          if (this.reservationInfo.onboardUnitInstalled === 1) {
            attentions.push('帰着遅延後の忘れ物解錠はできません。')
          } else {
            attentions.push('帰着遅延後の忘れ物確認はできません。')
          }
        }
        if (this.statusReservation === STATUS_RESERVATION.STATUS_5 && this.existNearReservation) {
          if (this.reservationInfo.onboardUnitInstalled === 1) {
            attentions.push('直後に予約が入っているため忘れ物解錠はできません。')
          } else {
            attentions.push('直後に予約が入っているため忘れ物確認はできません。')
          }
        }
        return attentions
      },
      isMaxTimeReservation() {
        return (
          moment(this.reservationInfo.reservationEndDatetime).diff(
            moment(this.reservationInfo.reservationStartDatetime),
            'hours'
          ) === VAL_72_HOURS
        )
      },
      unionExtendDateTime() {
        return isEmptyObject(this.extendDate) || isEmptyObject(this.extendMinute)
          ? moment()
          : moment(this.extendDate + ' ' + this.extendMinute)
      },
      startableTime() {
        return this.reservationInfo.usagePattern === UsagePattern.JOINT_USE
          ? VAL_15_MINUTES
          : VAL_10_MINUTES
      },
    },
    watch: {
      statusReservation: {
        immediate: true,
        handler(statusReservation) {
          if (statusReservation === STATUS_RESERVATION.STATUS_1) {
            this.statusMessage = `予約時間の${this.startableTime}分前からご利用いただけます`
            this.statusClass = 'status_1'
          }
          if (
            statusReservation === STATUS_RESERVATION.STATUS_2 ||
            statusReservation === STATUS_RESERVATION.STATUS_3
          ) {
            this.statusMessage = '現在ご利用いただけます'
            this.statusClass = 'status_2'
          }
          if (statusReservation === STATUS_RESERVATION.STATUS_4) {
            this.statusMessage = '利用中です'
            this.statusClass = 'status_3'
          }
          if (statusReservation === STATUS_RESERVATION.STATUS_6) {
            this.statusMessage = '忘れ物確認中です'
            this.statusClass = 'status_5'
          }
          if (
            statusReservation === STATUS_RESERVATION.STATUS_5 ||
            statusReservation === STATUS_RESERVATION.STATUS_7
          ) {
            this.statusMessage = '利用終了しています'
            this.statusClass = 'status_4'
          }
        },
      },
      reservationInfo: {
        immediate: true,
        deep: true,
        async handler() {
          if (this.statusReservation === STATUS_RESERVATION.STATUS_4) {
            await this.calculateExtendTime()
          }
        },
      },
    },
    methods: {
      disableStartDate(value) {
        return !moment(value).isBetween(this.minExtendDate, this.maxExtendDate, null, '[]')
      },
      async calculateExtendTime() {
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
        try {
          const result = await reservationExtentionTimeApi.findMaxExtendableTime(
            this.reservationInfo.reservationId
          )
          this.maxExtendTime = result.data[0].extendableTime
          this.maxExtendDateTime = moment(result.data[0].extendableDateTime).format(
            DATE_TIME_FORMAT.YYYY_MM_DDHHmmss
          )
          this.maxExtendDate = moment(result.data[0].extendableDateTime).format(
            DATE_TIME_FORMAT.YYYYMMDD
          )

          if (this.unionExtendDateTime.isSame(this.maxExtendDate, 'day')) {
            this.maxExtendMinute = moment(result.data[0].extendableDateTime)
              .add(VAL_15_MINUTES, 'minutes')
              .format(DATE_TIME_FORMAT.HHMM)
          } else {
            this.maxExtendMinute = null
          }

          if (this.reservationInfo.delayUsingStartDatetime != null) {
            this.maxExtendTime = 0 // if return delay, can not extend reservation
          }
          if (this.maxExtendTime > 0) {
            // default extend 15 minutes
            this.extendDate = moment(this.reservationInfo.reservationEndDatetime)
              .add(VAL_15_MINUTES, 'minutes')
              .format(DATE_TIME_FORMAT.YYYYMMDD)
            this.extendMinute = moment(this.reservationInfo.reservationEndDatetime)
              .add(VAL_15_MINUTES, 'minutes')
              .format(DATE_TIME_FORMAT.HHMM)

            this.minExtendDateTime = moment(this.reservationInfo.reservationEndDatetime)
              .add(VAL_15_MINUTES, 'minutes')
              .format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss)
            this.minExtendDate = moment(this.reservationInfo.reservationEndDatetime)
              .add(VAL_15_MINUTES, 'minutes')
              .format(DATE_TIME_FORMAT.YYYYMMDD)

            if (this.unionExtendDateTime.isSame(this.minExtendDate, 'day')) {
              this.minExtendMinute = moment(this.reservationInfo.reservationEndDatetime).format(
                DATE_TIME_FORMAT.HHMM
              )
            } else {
              this.minExtendMinute = null
            }

            this.extendTime = VAL_15_MINUTES
          }
          this.noExtend = this.maxExtendTime <= 0
        } catch (e) {
          await this.validationErrorHook(e)
        } finally {
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
        }
      },
      openCalendarUrl() {
        const location = this.reservationInfo.prefecturesName.concat(
          this.reservationInfo.city,
          this.reservationInfo.town,
          this.reservationInfo.otherAddress ? this.reservationInfo.otherAddress : ''
        )
        const title = `${this.corpParam.serviceName}(${this.reservationInfo.evstationName})`
        const date =
          moment(this.reservationInfo.reservationStartDatetime).format('YYYYMMDDTHHmmss') +
          '/' +
          moment(this.reservationInfo.reservationEndDatetime).format('YYYYMMDDTHHmmss')
        let url = CALENDAR_URL
        url = url
          .concat('action=TEMPLATE')
          .concat(`&text=${encodeURIComponent(title)}`)
          .concat(`&details=${encodeURIComponent('')}`)
          .concat(`&location=${encodeURIComponent(location)}`)
          .concat(`&dates=${encodeURIComponent(date)}`)
          .concat('&trp=false')
        window.open(url, '_blank')
      },
      /**
       * Transit to edit reservation page
       *
       */
      async gotoEditReservation() {
        await this.$router.push({
          path: Config.URL.ROOT,
          query: { reservationId: this.reservationInfo.reservationId },
        })
      },
      /**
       * Cancel reservation
       */
      async clickCancelReservation() {
        const message =
          this.reservationInfo.usagePattern === UsagePattern.JOINT_USE
            ? MENU002_CANCEL_RESERVE_CONFIRM.message3
            : this.statusReservation < STATUS_RESERVATION.STATUS_3
            ? MENU002_CANCEL_RESERVE_CONFIRM.message1
            : MENU002_CANCEL_RESERVE_CONFIRM.message2

        await this.$_confirm
          .confirmOpen(message, MENU002_CANCEL_RESERVE_CONFIRM.header)
          .then(() => {
            this.cancelReservation()
          })
      },
      /**
       * Call API PUT_014 to cancel
       */
      async cancelReservation() {
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
        try {
          await reservationCancelApi.reservationCancel(this.reservationInfo.reservationId)
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
          await this.$_confirm
            .dialogOpen(RESERVATION_CANCEL_SUCCESS.message, RESERVATION_CANCEL_SUCCESS.header)
            .then(() => {
              this.emitReservationChange()
            })
        } catch (e) {
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
          await this.handlePutError(e)
        }
      },

      async onSubmit() {
        if (
          this.statusReservation == STATUS_RESERVATION.STATUS_2 ||
          this.statusReservation == STATUS_RESERVATION.STATUS_3 ||
          this.statusReservation == STATUS_RESERVATION.STATUS_5
        ) {
          this.clickStartReservation()
        } else if (
          this.statusReservation == STATUS_RESERVATION.STATUS_4 ||
          this.statusReservation == STATUS_RESERVATION.STATUS_6
        ) {
          this.stopReservation()
        }
      },
      /**
       * Event click unlock vehicle
       */
      async clickStartReservation() {
        if (this.statusReservation < STATUS_RESERVATION.STATUS_4) {
          // アルコールチェックバリデーション

          const dialogMessage =
            this.reservationInfo.onboardUnitInstalled === OnboardUnitInstalled.YES
              ? MENU002_UNLOCK_CONFIRM.messageOnboardUnitInstalled
              : MENU002_UNLOCK_CONFIRM.messageNoOnboardUnitInstalled
          await this.$_confirm
            .confirmOpen(dialogMessage, MENU002_UNLOCK_CONFIRM.header)
            .then(async () => {
              try {
                await this.updateAlcoholCheckResult(
                  this.reservationInfo.reservationId,
                  'before',
                  this.form.alcoholCheckContainer
                )
                this.startReservation()
              } catch (e) {
                await this.validationErrorHook(e)
              }
            })
        } else {
          await this.$_confirm
            .confirmOpen(
              MENU002_UNLOCK_FORGOT_CONFIRM.message,
              MENU002_UNLOCK_FORGOT_CONFIRM.header
            )
            .then(() => {
              this.startReservation(true)
            })
        }
      },
      /**
       * Call api to start reservation / get forgot items
       */
      async startReservation(isForgotItem = false) {
        try {
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
          if (isForgotItem) {
            this.$emit('changeLoadingMessage', '忘れ物確認開始中…')
          } else {
            this.$emit('changeLoadingMessage', '利用開始手続き中…')
          }
          await reservationStartApi.usingStart(this.reservationInfo.reservationId)
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
          const message = isForgotItem
            ? MENU002_UNLOCK_FORGOT_ITEMS_SUCCESS.message
            : MENU002_UNLOCK_SUCCESS.message
          const header = isForgotItem
            ? MENU002_UNLOCK_FORGOT_ITEMS_SUCCESS.header
            : MENU002_UNLOCK_SUCCESS.header
          await this.$_confirm.dialogOpen(message, header).then(() => {
            this.emitReservationChange()
          })
        } catch (e) {
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
          if (e.status === API_ERROR_CODE.SERVICE_UNAVAILABLE) {
            // メンテナンスモード
            this.go('maintenance')
          } else if (e.status === API_ERROR_CODE.UNKNOWN_ERROR) {
            await this.$_confirm.dialogOpen(API_ERROR_MESSAGE.TIMEOUT_ERROR, null).then(() => {
              this.emitReservationChange()
            })
          } else {
            if (isForgotItem) {
              await this.$_confirm
                .dialogOpen(
                  MENU002_UNLOCK_FORGOT_ITEMS_FAIL.message + (await this.getErrorMessage(e)),
                  MENU002_UNLOCK_FORGOT_ITEMS_FAIL.header
                )
                .then(() => {
                  this.emitReservationChange()
                })
            } else {
              await this.$_confirm
                .dialogOpen(
                  MENU002_UNLOCK_FAIL.message + (await this.getErrorMessage(e)),
                  MENU002_UNLOCK_FAIL.header
                )
                .then(() => {
                  this.emitReservationChange()
                })
            }
          }
        } finally {
          this.$emit('changeLoadingMessage', null)
        }
      },
      /**
       * Stop reservation:
       *      - submit usage questionnaire
       *      - call api to stop reservation
       */
      async stopReservation() {
        if (this.statusReservation === STATUS_RESERVATION.STATUS_4) {
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
          try {
            await this.updateAlcoholCheckResult(
              this.reservationInfo.reservationId,
              'after',
              this.form.alcoholCheckContainer
            )

            const result = await questionnaireApi.findById(this.reservationInfo.reservationId)
            this.questionnaire = result.data[0]

            this.$store.commit(MUTATION_TYPES.SET_RESERVATION_DATA, this.reservationInfo)
            this.$store.commit(MUTATION_TYPES.SET_QUESTIONNAIRE_DATA, this.questionnaire)

            await this.$router.push({
              path: Config.URL.QUESTIONNAIRE,
            })
          } catch (e) {
            await this.validationErrorHook(e)
          } finally {
            this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
          }
        } else if (this.statusReservation === STATUS_RESERVATION.STATUS_6) {
          await this.$_confirm
            .confirmOpen(MENU002_LOCK_FORGOT_CONFIRM.message, MENU002_LOCK_FORGOT_CONFIRM.header)
            .then(() => {
              this.callApiStopReservation()
            })
        }
      },
      /**
       * Stop reservation in forgot item case
       */
      async callApiStopReservation() {
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
        this.$emit('changeLoadingMessage', '返却手続き中…')
        try {
          await reservationStopApi.usingStop(this.reservationInfo.reservationId)
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
          await this.$_confirm
            .dialogOpen(MENU002_LOCK_FORGOT_SUCCESS.message, MENU002_LOCK_FORGOT_SUCCESS.header)
            .then(() => {
              this.emitReservationChange()
            })
        } catch (e) {
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
          this.$emit('changeLoadingMessage', null)
          await this.handlePutError(e, MENU002_LOCK_FAIL.header)
        }
      },
      /**
       * Handle event click extend button
       */
      async clickExtendButton() {
        const args = [this.unionExtendDateTime.format('YYYY/MM/DD HH:mm'), this.extendTime]
        const message = Utils.sprintf(MENU002_EXTEND_CONFIRM.message, args)

        await this.$_confirm.confirmOpen(message, MENU002_EXTEND_CONFIRM.header).then(() => {
          this.extendTimeReservation()
          this.closeModalSelectTimeExtend()
        })
      },
      /**
       * handle even change time to extend
       */
      changeExtendTime() {
        this.extendTime = this.unionExtendDateTime.diff(
          moment(this.reservationInfo.reservationEndDatetime),
          'minutes'
        )

        if (this.unionExtendDateTime.isSame(this.maxExtendDate, 'day')) {
          this.maxExtendMinute = moment(this.maxExtendDateTime)
            .add(VAL_15_MINUTES, 'minutes')
            .format(DATE_TIME_FORMAT.HHMM)
          if (this.unionExtendDateTime.isAfter(this.maxExtendDateTime)) {
            this.extendMinute = moment(this.maxExtendDateTime).format(DATE_TIME_FORMAT.HHMM)
          }
        } else {
          this.maxExtendMinute = null
        }

        if (this.unionExtendDateTime.isSame(this.minExtendDate, 'day')) {
          this.minExtendMinute = moment(this.minExtendDateTime)
            .subtract(VAL_15_MINUTES, 'minutes')
            .format(DATE_TIME_FORMAT.HHMM)
          if (this.unionExtendDateTime.isBefore(this.minExtendDateTime)) {
            this.extendMinute = moment(this.minExtendDateTime).format(DATE_TIME_FORMAT.HHMM)
          }
        } else {
          this.minExtendMinute = null
        }
      },
      openModalSelectTimeExtend() {
        this.$jquery('.additional_time').fadeIn(500)
      },
      closeModalSelectTimeExtend() {
        this.$jquery('.additional_time').fadeOut(500)
      },
      /**
       * extend reservation time
       */
      async extendTimeReservation() {
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
        try {
          await reservationExtentionTimeApi.extend(
            this.reservationInfo.reservationId,
            new ExtendTimeBody(this.unionExtendDateTime)
          )
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
          const message = Utils.sprintf(MENU002_EXTEND_SUCCESS.message, [
            moment(this.unionExtendDateTime).format('YYYY/MM/DD HH:mm'),
          ])

          await this.$_confirm.dialogOpen(message, MENU002_EXTEND_SUCCESS.header).then(() => {
            this.emitReservationChange()
          })
        } catch (e) {
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
          await this.handlePutError(e)
        }
      },
      /**
       * Emit event reservation change to init data again
       */
      emitReservationChange() {
        this.$emit('emitChangeData')
      },
      async handlePutError(e, title = null) {
        if (e.status === API_ERROR_CODE.SERVICE_UNAVAILABLE) {
          // メンテナンスモード
          this.go('maintenance')
        } else if (e.status === API_ERROR_CODE.UNKNOWN_ERROR) {
          await this.$_confirm.dialogOpen(API_ERROR_MESSAGE.TIMEOUT_ERROR, null).then(() => {
            this.emitReservationChange()
          })
        } else {
          await this.$_confirm.dialogOpen(await this.getErrorMessage(e), title).then(() => {
            this.emitReservationChange()
          })
        }
      },
      /** アルコールチェック結果登録 */
      async updateAlcoholCheckResult(reservationId, prefix, alcoholCheckContainer) {
        // 共同利用かつステーションのアルコールチェック有りの場合
        if (this.reservationInfo.usagePattern === 2 && this.reservationInfo.alcoholChecked === 1) {
          // localstorageに確認者名をセット
          this.$store.commit(
            MUTATION_TYPES.SET_ALCOHOL_CONFIRMOR,
            this.form.alcoholCheckContainer.confirmor
          )
          // アルコールチェック結果更新
          await reservationApi.updateAlcoholCheckBefore(
            reservationId,
            prefix,
            alcoholCheckContainer
          )
        }
      },
      async getCode() {
        const params = {
          codeKeys: ['alcohol_check_confirmation_method', 'alcohol_check_intoxicated'],
        }
        const ret = await codeApi.findAll(params)
        this.alcoholCheckConfirmationMethodList = await extractCodebyCodeKey(
          ret.data,
          'alcohol_check_confirmation_method'
        )
        this.alcoholCheckIntoxicatedList = await extractCodebyCodeKey(
          ret.data,
          'alcohol_check_intoxicated'
        )
      },
      clearValidRule() {
        this.alcoholSchema = {}
      },
    },
    async created() {
      await this.getCode()
    },
    async mounted() {
      if (
        this.reservationInfo.usagePattern === UsagePattern.JOINT_USE &&
        this.reservationInfo.alcoholChecked === 1
      ) {
        if (
          this.statusReservation === STATUS_RESERVATION.STATUS_2 ||
          this.statusReservation === STATUS_RESERVATION.STATUS_3
        ) {
          if (!isEmptyObject(this.reservationInfo.beforeUseContainer))
            this.form.alcoholCheckContainer = _.cloneDeep(this.reservationInfo.beforeUseContainer)
        } else if (this.statusReservation === STATUS_RESERVATION.STATUS_4) {
          if (!isEmptyObject(this.reservationInfo.afterUseContainer))
            this.form.alcoholCheckContainer = _.cloneDeep(this.reservationInfo.afterUseContainer)
        } else {
          // 忘れ物解錠・終了時はバリデーションルールを削除
          this.clearValidRule()
        }
      } else {
        this.clearValidRule()
      }

      // 初期値セット
      if (isEmptyObject(this.form.alcoholCheckContainer.confirmor) && !isEmptyObject(this.$store.getters[GETTER_TYPES.GET_ALCOHOL_CONFIRMOR])) {
        this.form.alcoholCheckContainer.confirmor =
          this.$store.getters[GETTER_TYPES.GET_ALCOHOL_CONFIRMOR]
      }
      if (isEmptyObject(this.form.alcoholCheckContainer.confirmationMethod)) {
        this.form.alcoholCheckContainer.confirmationMethod = '1'
      }
      if (isEmptyObject(this.form.alcoholCheckContainer.intoxicated)) {
        this.form.alcoholCheckContainer.intoxicated = '0'
      }
    },
  }
</script>

<style scoped></style>
