<template>
  <div class="carousel" :class="{ row: viewIcon }">
    <transition-group
      tag="div"
      class="dateselect"
      :name="style"
      :style="reverse ? 'flex-direction: row-reverse;' : ''"
      v-if="dateList"
    >
      <img src="img/prev-arrow2.svg" class="slide-arrow prev-arrow" @click="prev" key="prev" />
      <div
        v-for="(day, index) in dateList"
        :key="index"
        v-show="index === displayImg"
        class="rese_status"
      >
        <span class="callender_icon" v-if="viewIcon"><img src="img/callender.svg" /></span>
        <span class="day">{{ $filters.formatMDdd(day) }}</span>
      </div>
      <img src="img/next-arrow2.svg" class="slide-arrow next-arrow" @click="next" key="next" />
    </transition-group>
  </div>
</template>

<script>
  export default {
    name: 'DateSlider',
    props: {
      dateList: Array,
      viewIcon: { type: Boolean, default: false },
    },
    components: {},
    data() {
      return {
        displayImg: 0,
        style: '',
        reverse: false, // 最初or最後の表示切替でアニメーションが崩れるため
      }
    },
    created() {},
    mounted() {},
    methods: {
      next() {
        if (this.displayImg >= this.dateList.length - 1) {
          this.displayImg = 0
          // 最初に戻る
          this.reverse = true
        } else {
          this.displayImg++
          this.reverse = false
        }
        this.style = 'slide-right'
        this.change()
      },
      prev() {
        if (this.displayImg == 0) {
          this.displayImg = this.dateList.length - 1
          // 最後に進む
          this.reverse = true
        } else {
          this.displayImg--
          this.reverse = false
        }
        this.style = 'slide-left'
        this.change()
      },
      change() {
        this.$emit('changeDate', this.displayImg)
      },
    },
    computed: {},
    watch: {},
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .main {
    width: 80%;
    margin: 0 auto;
  }

  .carousel {
    position: relative;
    width: 100%;
    max-width: 300px;
  }

  .row {
    margin: 0 auto 15px;
  }

  .btn_left {
    position: absolute;
    top: 60px;
    left: 5px;
    z-index: 1;
    width: 20px;
  }

  .btn_right {
    position: absolute;
    top: 60px;
    right: 5px;
    z-index: 1;
    width: 20px;
  }

  .carousel button {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    font-size: 2em;
  }

  .center {
    width: 100%;
  }

  .point {
    margin: 0.5em;
    cursor: pointer;
    color: white;
  }

  .point.active {
    color: #007ecb;
  }

  /* トランジション */
  .slide-right-leave-active,
  .slide-right-enter-active,
  .slide-left-leave-active,
  .slide-left-enter-active {
    transition: 0.2s ease-in-out;
  }

  .slide-right-enter-from,
  .slide-left-leave-to {
    transform: translateX(100%);
  }

  .slide-right-leave-to,
  .slide-left-enter-from {
    transform: translateX(-100%);
  }

  .day {
    width: 100%;
  }

  .dateselect {
    display: flex;
    justify-content: center;
  }

  .rese_status {
    position: relative;
  }
</style>
