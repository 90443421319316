<template>
  <p>callback</p>
</template>

<script>
  import Auth from '@/module/Auth'
  import Config from '@/conf/Config'

  export default {
    created() {
      this.$logger.info('Logged out', { id: this.session.username })
      Auth.signout()
      this.$router.push(Config.URL.LOGIN_PATH)
    },
  }
</script>
