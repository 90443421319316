export class ChartUtils {
  /**
   * Draw Energy Chart
   * Convert code js to ts
   *
   */
  static drawEnergyChart(necessaryEnergy, possibleEnergy, idGraph) {
    idGraph = idGraph ? idGraph : ''
    let nrgBarsElement = document.getElementById(`energyBars${idGraph}`)
    if (nrgBarsElement) {
      nrgBarsElement.innerHTML = '' // Clean chart before draw
      let nrgBars = ''
      if (necessaryEnergy <= possibleEnergy) {
        for (let i = 0; i < 20; i++) {
          if (i < necessaryEnergy / 5) {
            nrgBars += '<div class="g"></div>'
          } else if (i < possibleEnergy / 5) {
            nrgBars += '<div class="b"></div>'
          } else {
            nrgBars += '<div class="d"></div>'
          }
        }
      } else {
        for (let i = 0; i < 20; i++) {
          if (i < possibleEnergy / 5) {
            nrgBars += '<div class="g"></div>'
          } else if (i < necessaryEnergy / 5) {
            nrgBars += '<div class="y"></div>'
          } else {
            nrgBars += '<div class="d"></div>'
          }
        }
      }
      nrgBarsElement.insertAdjacentHTML('beforeend', nrgBars)
      const goal = document.getElementById(`goal${idGraph}`)
      goal.style.left = `${necessaryEnergy}%`
      const curpos = document.getElementById(`curpos${idGraph}`)
      curpos.style.left = `${possibleEnergy}%`
    }
  }
}
