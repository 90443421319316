<template>
  <div class="modalbody vehiclemodal">
    <div class="modalbody_wrap">
      <div class="reserve_box">
        <ReserveLeft
            :status-reservation="statusReservation"
            :reservation-info="reservationInfo"
        />
        <ReserveRight
            v-bind="$attrs"
            :status-reservation="statusReservation"
            :reservation-info="reservationInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ReserveLeft from './ReserveLeft'
  import ReserveRight from './ReserveRight'
  export default {
    name: 'ReservationInfo',
    components: { ReserveRight, ReserveLeft },
    props: {
      statusReservation: null,
      reservationInfo: {},
    },
  }
</script>

<style scoped></style>
