<template>
  <div id="history" class="modal js-modal" style="display: block; opacity: 1">
    <div class="modal__content">
      <Loading
        :active="processing"
        :can-cancel="true"
        :color="'#007ecb'"
        :is-full-page="true"
        :loader="'dots'"
      />
      <!--モーダルヘッダー-->
      <div class="modal_header">
        <a class="js-modal-close2" href="" @click.stop.prevent="closeModal()">戻る</a>
        <div class="modal_ttl">利用履歴</div>
      </div>
      <div class="modalbody">
        <div class="modalbody_wrap">
          <!--車両情報-->
          <div id="vehicle-information" class="use_tbl">
            <table>
              <tbody>
                <tr>
                  <th>車両情報</th>
                </tr>
                <tr class="tr">
                  <td>{{ reservationInfo.vehicleName }}</td>
                  <td>{{ reservationInfo.vehicleTypeClassName }}</td>
                  <td>{{ reservationInfo.evstationName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--利用内容-->
          <div id="usage-details" class="use_tbl">
            <table>
              <tbody>
                <tr>
                  <th>利用内容</th>
                </tr>
                <tr class="use_cont">
                  <td class="noblock datetime">
                    <div class="tbl_box">
                      <div class="tbl_ttl">予約時間</div>
                      <div class="tbl_ttl_body">
                        {{ reservationInfo.reservationStartDatetime }} ～<br />
                        {{ reservationInfo.reservationEndDatetime }} ({{
                          reservationInfo.reservationTime
                        }})
                      </div>
                    </div>
                  </td>
                  <td v-if="isDisplayTimeUsing" class="noblock timeusing">
                    <div class="tbl_box">
                      <div class="tbl_ttl">利用時間</div>
                      <div class="tbl_ttl_body">
                        {{ reservationInfo.usingStartDatetime }} ~<br />
                        {{ reservationInfo.usingEndDatetime }}({{ reservationInfo.usingTime }})
                      </div>
                    </div>
                  </td>
                  <td class="noblock mileage">
                    <div class="tbl_box">
                      <div class="tbl_ttl">走行距離</div>
                      <div class="tbl_ttl_body">
                        <strong>{{ $filters.formatNumber(reservationInfo.mileage) }}km</strong>
                      </div>
                    </div>
                  </td>
                  <td class="noblock edited_timerange" v-if="reservationInfo.usagePattern === 2 && reservationInfo.reservationStatus === 2">
                    <div class="tbl_box">
                      <div class="tbl_ttl">修正後<br />利用時間</div>
                      <div class="tbl_ttl_body">
                        <div class="">
                          <el-date-picker
                            v-model="time.startDate"
                            type="date"
                            :placeholder="'利用開始日'"
                            :format="'YYYY/MM/DD'"
                            value-format="YYYY-MM-DD"
                            :disabled-date="disableStartDate"
                            :class="'date_picker daterange_select'"
                            @change="changeDate()"
                          />
                          <input
                            type="time"
                            class="timerange_select"
                            v-model="time.startTime"
                            :min="startDateCondition.minTime"
                            max="23:59"
                          />
                          <span>～</span>
                        </div>
                        <div class="">
                          <el-date-picker
                            v-model="time.endDate"
                            type="date"
                            :placeholder="'利用終了日'"
                            :format="'YYYY/MM/DD'"
                            value-format="YYYY-MM-DD"
                            :disabled-date="disableEndDate"
                            :class="'date_picker daterange_select'"
                            @change="changeDate()"
                          />
                          <input
                            type="time"
                            class="timerange_select"
                            v-model="time.endTime"
                            :min="endDateCondition.minTime"
                            max="23:59"
                          />
                          <button type="submit" class="submit" @click="updateFixedDateTime()">
                            更新
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--料金明細-->
          <div class="use_tbl">
            <ul>
              <li id="price-detail">
                <table>
                  <tbody v-if="reservationInfo.usagePattern === 1">
                    <tr>
                      <th>料金明細</th>
                    </tr>
                    <tr>
                      <td>
                        <div class="tbl_box2 pdtp0">
                          <div class="tbl_ttl2">
                            ショート（{{
                              $filters.formatNumber(reservationInfo.timeCharge)
                            }}
                            円/15分）<br />
                            {{ reservationInfo.paymentTargetTotalTime }}
                          </div>
                          <div class="tbl_ttl_body">
                            <strong
                              >{{
                                $filters.formatNumber(reservationInfo.confirmedTimeChargeTotal)
                              }}
                              円</strong
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="tbl_box2">
                          <div class="tbl_ttl2">
                            {{ reservationInfo.nightPackName }}料金<br />
                            {{ reservationInfo.nightpackAppliedAlias }}
                          </div>
                          <div class="tbl_ttl_body">
                            <strong>{{
                              $filters.formatNumber(reservationInfo.confirmedNightpackCharge)
                            }}</strong>
                            <strong>円</strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="tbl_box2">
                          <div class="tbl_ttl2">
                            超過料金（{{
                              $filters.formatNumber(reservationInfo.returnDelayTimeCharge)
                            }}円/15分）<br />
                            {{ reservationInfo.returnDelayPaymentTargetTotalTime }}
                          </div>
                          <div class="tbl_ttl_body">
                            <strong
                              >{{
                                $filters.formatNumber(
                                  reservationInfo.confirmedReturnDelayChargeTotal
                                )
                              }}円</strong
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="tbl_box2">
                          <div class="tbl_ttl2">
                            {{ reservationInfo.insurancePlanName }}料金<br />
                            {{ reservationInfo.insuranceSubscribedAlias }}
                          </div>
                          <div class="tbl_ttl_body">
                            <strong
                              >{{
                                $filters.formatNumber(reservationInfo.confirmedInsuranceCharge)
                              }}円</strong
                            >
                          </div>
                        </div>
                      </td>
                      <td class="promotion">
                        <div class="tbl_box2" v-if="reservationInfo.promotionName">
                          <div class="tbl_ttl2">
                            プロモーション<br />
                            {{ reservationInfo.promotionName }}
                          </div>
                          <div class="tbl_ttl_body">
                            <strong>-{{ reservationInfo.confirmedDiscount }}</strong>
                          </div>
                        </div>
                      </td>
                      <td class="settlementStatus">
                        <div class="tbl_box3">
                          <div class="tbl_box3_price">
                            <div v-if="reservationInfo.settlementStatus === 3" class="kessai_flag">
                              決済NG
                            </div>
                            <p class="tbl_ttl_body">
                              <strong>合計</strong>
                              <span class="tbl_price roboto">{{
                                $filters.formatNumber(reservationInfo.totalCharge)
                              }}</span>
                              <span>円</span>
                            </p>
                          </div>
                          <p class="tbl_attentioin">※月額基本料金は利用料金に含まれません。</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <!--その他利用情報-->
              <li id="information">
                <table>
                  <tbody>
                    <tr>
                      <th>その他利用情報</th>
                    </tr>
                    <tr>
                      <td class="usedPower" v-if="reservationInfo.onboardUnitInstalled === 1">
                        <div class="tbl_box2 pdtp0">
                          <div class="tbl_ttl2">利用電力</div>
                          <div class="tbl_ttl_body">
                            <strong
                              >{{ $filters.formatNumber(reservationInfo.usedPower, 1) }}kwh</strong
                            >
                          </div>
                        </div>
                      </td>
                      <td
                        class="powerConsumption"
                        v-if="reservationInfo.onboardUnitInstalled === 1"
                      >
                        <div class="tbl_box2">
                          <div class="tbl_ttl2">電費</div>
                          <div class="tbl_ttl_body">
                            <strong
                              >{{ $filters.formatNumber(reservationInfo.powerConsumption) }}km /
                              kwh</strong
                            >
                          </div>
                        </div>
                      </td>
                      <td class="charge">
                        <div
                          v-if="
                            reservationInfo.ecocarType === 1 && reservationInfo.usagePattern === 2
                          "
                          class="tbl_box2"
                        >
                          <div class="tbl_ttl2">ご利用中の充電</div>
                          <div class="tbl_ttl_body">
                            <strong>{{ reservationInfo.alightingChargedAlias }}</strong>
                          </div>
                        </div>
                      </td>
                      <td class="washConfirmationAlias">
                        <div class="tbl_box2">
                          <div class="tbl_ttl2">ご利用中の洗車</div>
                          <div class="tbl_ttl_body">
                            <strong id="car-wash">{{
                              reservationInfo.washConfirmationAlias
                            }}</strong>
                          </div>
                        </div>
                      </td>
                      <td
                        v-if="
                          reservationInfo.passengerEmailList &&
                          reservationInfo.passengerEmailList.length > 0 &&
                          reservationInfo.usagePattern === 1
                        "
                        class="passengerEmailList"
                      >
                        <div class="tbl_box2">
                          <div class="tbl_ttl2">追加運転者</div>
                          <div class="tbl_ttl_body">
                            <p
                              v-for="(email, index) in reservationInfo.passengerEmailList"
                              :key="index"
                            >
                              <strong>{{ email }}</strong>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loading from 'vue-loading-overlay'
  import Logger from '@/module/Logger'
  import moment from 'moment'
  import { usageHistoryApi, reservationApi } from '@/module/Api'
  import { DateUtils } from '@/helpers/DateUtils'
  import { MUTATION_TYPES } from '@/store'
  import { DATE_TIME_FORMAT } from '@/constants/constant'
  import { isEmptyObject } from '@/helpers/validators'
  import { USAGE_HISTORY_FIXEX_DATETIME_CONF } from '@/constants/constant.dialog'

  export default {
    name: 'UsageHistoryDetail',
    props: {
      reservationId: { type: Number, default: null },
    },
    components: {
      Loading,
      // DateTimePickerRangeFixed,
    },
    data() {
      return {
        time: {
          startDate: null,
          endDate: null,
          startTime: null,
          endTime: null,
        },
        startDateCondition: {
          min: null,
          max: null,
          minDate: null,
          maxDate: null,
          minTime: null,
          maxTime: null,
        },
        endDateCondition: {
          min: null,
          max: null,
          minDate: null,
          maxDate: null,
          minTime: null,
          maxTime: null,
        },
        reservationInfo: {},
        isDisplayTimeUsing: false,
      }
    },
    created() {
      this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
    },
    async mounted() {
      await this.getReservationDetail()
      this.setDateCondition()
    },
    methods: {
      closeModal() {
        this.$emit('closeModal')
      },
      disableStartDate(value) {
        return !moment(value).isSameOrAfter(this.startDateCondition.minDate)
      },
      disableEndDate(value) {
        return !moment(value).isSameOrAfter(this.endDateCondition.minDate)
      },
      async changeDate() {
        if (!isEmptyObject(this.time.startDate) && isEmptyObject(this.time.startTime)) {
          this.time.startTime = '00:00'
        }
        if (!isEmptyObject(this.time.endDate) && isEmptyObject(this.time.endTime)) {
          this.time.endTime = '00:00'
        }
      },
      async setDateCondition() {
        // 日付のMinを制御 予約開始日
        this.startDateCondition.minDate = moment(this.reservationInfo.reservationStartDatetime)
          .subtract(15, 'm')
          .format(DATE_TIME_FORMAT.YYYYMMDD)
        this.endDateCondition.minDate = moment(this.reservationInfo.reservationStartDatetime).format(
          DATE_TIME_FORMAT.YYYYMMDD
        )
      },
      updateFixedDateTime() {
        let errMsg = ''
        if (isEmptyObject(this.unionStartDateTime) && isEmptyObject(this.unionEndDateTime)) {
          // 空で更新
        } else if (
          isEmptyObject(this.unionStartDateTime) &&
          !isEmptyObject(this.unionEndDateTime)
        ) {
          errMsg = '修正後利用開始日時を入力してください。'
        } else if (
          !isEmptyObject(this.unionStartDateTime) &&
          isEmptyObject(this.unionEndDateTime)
        ) {
          errMsg = '修正後利用終了日時を入力してください。'
        } else if (
          moment(this.reservationInfo.reservationStartDatetime)
            .subtract(15, 'm')
            .isAfter(this.unionStartDateTime)
        ) {
          errMsg = '修正後利用開始日時は予約開始日時の15分前以降を入力してください。'
        } else if (this.unionStartDateTime.isSameOrAfter(this.unionEndDateTime)) {
          errMsg = '修正後利用終了日時は修正後利用開始日時より未来の日時を入力してください。'
        }
        if (errMsg) {
          this.$_confirm.dialogOpen(errMsg)
          return
        }

        this.$_confirm
          .confirmOpen(
            USAGE_HISTORY_FIXEX_DATETIME_CONF.message,
            USAGE_HISTORY_FIXEX_DATETIME_CONF.header
          )
          .then(async () => {
            this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
            const startDateTime = this.unionStartDateTime
              ? this.unionStartDateTime.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmm00)
              : null
            const endDateTime = this.unionEndDateTime
              ? this.unionEndDateTime.format(DATE_TIME_FORMAT.YYYY_MM_DDHHmm00)
              : null
            await reservationApi
              .updateFixedUsingDatetime(this.reservationId, {
                fixedUsingStartDatetime: startDateTime,
                fixedUsingEndDatetime: endDateTime,
                version: this.reservationInfo.version,
              })
              .then(() => {
                this.$_confirm.dialogOpen('更新処理が完了しました。').then(async () => {
                  await this.getReservationDetail()
                  this.setDateCondition()
                })
              })
              .catch(this.validationErrorHook)
              .finally(() => this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false))
          })
      },
      async getReservationDetail() {
        this.$store.commit(MUTATION_TYPES.SET_PROCESSING, true)
        try {
          if (this.reservationId) {
            const response = await usageHistoryApi.findOne(this.reservationId)
            if (response.data.length > 0) {
              this.reservationInfo = response.data[0]
              if (
                this.reservationInfo.usingEndDatetime &&
                this.reservationInfo.usingStartDatetime
              ) {
                this.isDisplayTimeUsing = true
              }
              this.reservationInfo.reservationStartDatetime = DateUtils.localDateTimeToDayJP(
                this.reservationInfo.reservationStartDatetime
              )
              this.reservationInfo.reservationEndDatetime = DateUtils.localDateTimeToDayJP(
                this.reservationInfo.reservationEndDatetime
              )
              this.reservationInfo.usingStartDatetime = DateUtils.localDateTimeToDayJP(
                this.reservationInfo.usingStartDatetime
              )
              this.reservationInfo.usingEndDatetime = DateUtils.localDateTimeToDayJP(
                this.reservationInfo.usingEndDatetime
              )
              if (!this.reservationInfo.alightingChargedAlias) {
                this.reservationInfo.alightingChargedAlias = '未実施'
              }
              if (!this.reservationInfo.washConfirmationAlias) {
                this.reservationInfo.washConfirmationAlias = '未実施'
              }

              if (!isEmptyObject(this.reservationInfo.fixedUsingStartDatetime)) {
                this.time.startDate = moment(this.reservationInfo.fixedUsingStartDatetime).format(
                  DATE_TIME_FORMAT.YYYYMMDD
                )
                this.time.startTime = moment(this.reservationInfo.fixedUsingStartDatetime).format(
                  DATE_TIME_FORMAT.HHMM
                )
              } else {
                this.time.startDate = null
                this.time.startTime = null
              }
              if (!isEmptyObject(this.reservationInfo.fixedUsingEndDatetime)) {
                this.time.endDate = moment(this.reservationInfo.fixedUsingEndDatetime).format(
                  DATE_TIME_FORMAT.YYYYMMDD
                )
                this.time.endTime = moment(this.reservationInfo.fixedUsingEndDatetime).format(
                  DATE_TIME_FORMAT.HHMM
                )
              } else {
                this.time.endDate = null
                this.time.endTime = null
              }
            }
          }
        } catch (e) {
          Logger.info('error', e)
        } finally {
          this.$store.commit(MUTATION_TYPES.SET_PROCESSING, false)
        }
      },
    },
    computed: {
      unionStartDateTime() {
        return isEmptyObject(this.time.startDate) || isEmptyObject(this.time.startTime)
          ? null
          : moment(this.time.startDate + ' ' + this.time.startTime)
      },
      unionEndDateTime() {
        return isEmptyObject(this.time.endDate) || isEmptyObject(this.time.endTime)
          ? null
          : moment(this.time.endDate + ' ' + this.time.endTime)
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a {
    cursor: pointer;
  }

  .tbl_box3_price {
    height: 26px;
  }
</style>
