import { MapMarkType, RenewableEnergyRatioType } from '@/conf/MapConfig'
import { Utils } from './Utils'
import moment from 'moment'
import { DATE_TIME_FORMAT } from '@/constants/constant'
import { Reservation } from '@/objects/Reservation'
import store from '@/store'
import { SESSION_GETTER_TYPES } from '@/store/modules/session'

export function checkTypeEvStation(isVehicleCanReserve, renewableEnergyRatio, powerPlantId) {
  const renewableEnergyRatioType =
    renewableEnergyRatio < 10
      ? RenewableEnergyRatioType.low
      : renewableEnergyRatio < 17
      ? RenewableEnergyRatioType.medium
      : RenewableEnergyRatioType.high
  if (isVehicleCanReserve) {
    if (powerPlantId) {
      if (renewableEnergyRatioType === RenewableEnergyRatioType.low) {
        return MapMarkType.typeOne
      } else if (renewableEnergyRatioType === RenewableEnergyRatioType.medium) {
        return MapMarkType.typeTwo
      } else if (renewableEnergyRatioType === RenewableEnergyRatioType.high) {
        return MapMarkType.typeThree
      }
    } else {
      if (renewableEnergyRatioType === RenewableEnergyRatioType.low) {
        return MapMarkType.typeFour
      } else if (renewableEnergyRatioType === RenewableEnergyRatioType.medium) {
        return MapMarkType.typeFive
      } else if (renewableEnergyRatioType === RenewableEnergyRatioType.high) {
        return MapMarkType.typeSix
      }
    }
  } else {
    if (powerPlantId) {
      if (renewableEnergyRatioType === RenewableEnergyRatioType.low) {
        return MapMarkType.typeSeven
      } else if (renewableEnergyRatioType === RenewableEnergyRatioType.medium) {
        return MapMarkType.typeEight
      } else if (renewableEnergyRatioType === RenewableEnergyRatioType.high) {
        return MapMarkType.typeNine
      }
    } else {
      if (renewableEnergyRatioType === RenewableEnergyRatioType.low) {
        return MapMarkType.typeTen
      } else if (renewableEnergyRatioType === RenewableEnergyRatioType.medium) {
        return MapMarkType.typeEleven
      } else if (renewableEnergyRatioType === RenewableEnergyRatioType.high) {
        return MapMarkType.typeTwelve
      }
    }
  }
}

export function getRenewableEnergyRatio(listEnergyRatio, startTime) {
  const energyRatio = listEnergyRatio.find((item) => timeCompareThirtyMinutes(item.date, startTime))
  if (energyRatio) {
    return Utils.calculateRenewEnergyPercent(energyRatio.renewableEnergyRatio)
  } else {
    return 0
  }
}

export function timeCompareThirtyMinutes(timeFrameString, startTimeString) {
  const diffTime = moment(startTimeString).diff(moment(timeFrameString), 'minutes')
  return compareByMinutes(diffTime, 30)
}

export function compareByMinutes(diffTime, minutes) {
  return 0 <= diffTime && diffTime < minutes
}

export function checkTimeReserveDuplicate(listReserve, startTimeString, endTimeString) {
  const reserveDuplicate = listReserve.find((reserve) =>
    compareTimeReservation(reserve, startTimeString, endTimeString)
  )
  if (reserveDuplicate) {
    return {
      conflict: true,
      reserveAfter: null,
    }
  } else {
    const newReserve = new Reservation(startTimeString, endTimeString)
    listReserve.push(newReserve)
    listReserve.sort((a, b) =>
      moment(a.reservationStartDatetime) > moment(b.reservationStartDatetime) ? 1 : -1
    )
    const index = listReserve.indexOf(newReserve)
    const reserveAfter = listReserve[index + 1]
    return {
      conflict: false,
      reserveAfter,
    }
  }
}

function compareTimeReservation(reserve, startTimeString, endTimeString) {
  const reserveStartTime = moment(reserve.reservationStartDatetime).format(
    DATE_TIME_FORMAT.YYYY_MM_DDHHmmss
  )
  const reserveEndTime = moment(reserve.reservationEndDatetime).format(
    DATE_TIME_FORMAT.YYYY_MM_DDHHmmss
  )
  const startTime = moment(startTimeString).format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss)
  const endTime = moment(endTimeString).format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss)
  return (
    (reserveStartTime >= startTime && reserveStartTime < endTime) ||
    (reserveEndTime > startTime && reserveEndTime <= endTime) ||
    (reserveStartTime < startTime && reserveEndTime > startTime)
  )
}

/**
 * Description: Check time reserve exist in list corp for member and user.
 * If is member, check conflict time, return true if conflict
 * If is user, check time reserve in frame user'corp, return false is correct
 * If is cleaning or maintenance corp, return false.
 */
export function checkTimeReserveExistInListCorp(
  listCorp,
  startTimeString,
  endTimeString,
  isCorpId,
  memberId
) {
  if (isCorpId) {
    const isCleaning = store.getters[SESSION_GETTER_TYPES.VALUES].cleaningCorp
    const isMaintenance = store.getters[SESSION_GETTER_TYPES.VALUES].maintenanceCorp
    if (isCleaning || isMaintenance) {
      return false
    }
    const memberCorpId = store.getters[SESSION_GETTER_TYPES.VALUES].corpId
    const corpDuplicate = listCorp.find((corpFrame) =>
      compareTimeCorporationByUser(
        corpFrame,
        startTimeString,
        endTimeString,
        memberCorpId,
        memberId
      )
    )
    return !!corpDuplicate
  } else {
    const corpDuplicate = listCorp.find((corpFrame) =>
      compareTimeCorporationByMember(corpFrame, startTimeString, endTimeString)
    )
    return !!corpDuplicate
  }
}

/**
 * Description: compare time reserve by user of corporation with time frame by user'corporation.
 * Return true if time reserve by user in time frame corp ( reserved )
 * @param corporationFrame: reserve info by Corp
 * @param startTimeString: time reserve start by user
 * @param endTimeString: time reserve end by user
 * @param memberCorpId: corp id of user
 * @param memberId: member id of user
 */
// tslint:disable-next-line:max-line-length
function compareTimeCorporationByUser(
  corporationFrame,
  startTimeString,
  endTimeString,
  memberCorpId,
  memberId
) {
  const corpStartTime = moment(corporationFrame.startDatetime).format(
    DATE_TIME_FORMAT.YYYY_MM_DDHHmmss
  )
  const corpEndTime = moment(corporationFrame.endDatetime).format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss)
  const startTime = moment(startTimeString).local().format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss)
  const endTime = moment(endTimeString).local().format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss)
  const corpIdList = corporationFrame.corpIds.split(",")

  return (
    (corpStartTime >= startTime &&
      corpStartTime < endTime &&
      !corpIdList.includes(String(memberCorpId))) ||
    (corpEndTime > startTime &&
      corpEndTime <= endTime &&
      !corpIdList.includes(String(memberCorpId))) ||
    (corpStartTime < startTime &&
      corpEndTime > startTime &&
      !corpIdList.includes(String(memberCorpId))) ||
    (corpStartTime >= startTime &&
      corpStartTime < endTime &&
      corpIdList.includes(String(memberCorpId)) &&
      compareTimeCorporationByIndividualFrame(corporationFrame.memberIds, memberId)) ||
    (corpEndTime > startTime &&
      corpEndTime <= endTime &&
      corpIdList.includes(String(memberCorpId)) &&
      compareTimeCorporationByIndividualFrame(corporationFrame.memberIds, memberId)) ||
    (corpStartTime < startTime &&
      corpEndTime > startTime &&
      corpIdList.includes(String(memberCorpId)) &&
      compareTimeCorporationByIndividualFrame(corporationFrame.memberIds, memberId))
  )
}

/**
 * Description: compare time reserve by member with time frame by corporation. return true if conflict time
 * @param corporationFrame: reserve info by Corp
 * @param startTimeString: time reserve start by member
 * @param endTimeString: time reserve end by member
 */
function compareTimeCorporationByMember(corporationFrame, startTimeString, endTimeString) {
  const corpStartTime = moment(corporationFrame.startDatetime).format(
    DATE_TIME_FORMAT.YYYY_MM_DDHHmmss
  )
  const corpEndTime = moment(corporationFrame.endDatetime).format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss)
  const startTime = moment(startTimeString).local().format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss)
  const endTime = moment(endTimeString).local().format(DATE_TIME_FORMAT.YYYY_MM_DDHHmmss)
  return (
    (corpStartTime >= startTime && corpStartTime < endTime) ||
    (corpEndTime > startTime && corpEndTime <= endTime) ||
    (corpStartTime < startTime && corpEndTime > startTime)
  )
}

/**
 * Description: compare time reserve by individual member frame time.
 * Return false if current frame is not individual member frame or is individual member frame but member reserve has memberId match frame memberId
 * @param frameMemberId: string of corp member can reserve within current corp frame
 * @param memberId: current member id
 */
function compareTimeCorporationByIndividualFrame(frameMemberId, memberId) {
  if (!frameMemberId) {
    return false
  }
  const listValidMemberId = frameMemberId.split(',')

  return !listValidMemberId.find((validMemberId) => Number(validMemberId) === Number(memberId))
}

export function getDrivingRange(vehicle, startTimeString) {
  const socPredictionStart = vehicle.socPredictionContainer.find((soc) =>
    timeCompareFifteenMinutes(soc.date, startTimeString)
  )
  if (socPredictionStart) {
    return socPredictionStart.drivingRange
  } else {
    return 0
  }
}

function timeCompareFifteenMinutes(timeFrameString, startTimeString) {
  const diffTime = moment(startTimeString).diff(moment(timeFrameString), 'minutes')
  return compareByMinutes(diffTime, 15)
}

export function checkTimeEvStationOpen(evStation, startDateTimeString, endDateTimeString) {
  if (evStation.allDayReservable === 1) {
    // 24時間貸出可能
    return true
  } else {
    const startDateTime = moment(startDateTimeString)
    const endDateTime = moment(endDateTimeString)
    const startTime = startDateTime.format('HH:mm:ss').toString()
    const endTime = endDateTime.format('HH:mm:ss').toString()
    if (
      moment(evStation.reservableStartTime, 'HH:mm:ss').isBefore(
        moment(evStation.reservableEndTime, 'HH:mm:ss')
      ) &&
      moment(startTime, 'HH:mm:ss').isBetween(
        moment(evStation.reservableStartTime, 'HH:mm:ss'),
        moment(evStation.reservableEndTime, 'HH:mm:ss'),
        null,
        '[]'
      ) &&
      moment(endTime, 'HH:mm:ss').isBetween(
        moment(evStation.reservableStartTime, 'HH:mm:ss'),
        moment(evStation.reservableEndTime, 'HH:mm:ss'),
        null,
        '[]'
      )
    ) {
      return true
    } else if (
      moment(evStation.reservableStartTime, 'HH:mm:ss').isAfter(
        moment(evStation.reservableEndTime, 'HH:mm:ss')
      ) &&
      (moment(startTime, 'HH:mm:ss').isBetween(
        moment(evStation.reservableStartTime, 'HH:mm:ss'),
        moment('24:00:00', 'HH:mm:ss'),
        null,
        '[]'
      ) ||
        moment(startTime, 'HH:mm:ss').isBetween(
          moment('0:00:00', 'HH:mm:ss'),
          moment(evStation.reservableEndTime, 'HH:mm:ss'),
          null,
          '[]'
        )) &&
      (moment(endTime, 'HH:mm:ss').isBetween(
        moment(evStation.reservableStartTime, 'HH:mm:ss'),
        moment('24:00:00', 'HH:mm:ss'),
        null,
        '[]'
      ) ||
        moment(endTime, 'HH:mm:ss').isBetween(
          moment('0:00:00', 'HH:mm:ss'),
          moment(evStation.reservableEndTime, 'HH:mm:ss'),
          null,
          '[]'
        ))
    ) {
      return true
    } else {
      return false
    }
  }
}
