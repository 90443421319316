export const DATE_TIME_FORMAT = {
  YYYYMMDD: 'YYYY-MM-DD',
  HHMMSS: 'HH:mm:ss',
  YYYY_MM_DDHHmmss: 'YYYY-MM-DD HH:mm:ss',
  YYYY_MM_DDHHmm00: 'YYYY-MM-DD HH:mm:00',
  HHMM: 'HH:mm',
  DDDD: 'dddd', // Day name of week
  YYYY_MM_DDHHmm: 'YYYY-MM-DD HH:mm',
  MM_DD: 'MM/DD',
  YYYY_MM: 'YYYY/MM',
  MMDD_HHmm: 'MM/DD HH:mm',
}

export const WEEK_DAYS = ['（日）', '（月）', '（火）', '（水）', '（木）', '（金）', '（土）']

export const MONTHS = [
  { key: '選択', value: '00' },
  { key: '01', value: '01' },
  { key: '02', value: '02' },
  { key: '03', value: '03' },
  { key: '04', value: '04' },
  { key: '05', value: '05' },
  { key: '06', value: '06' },
  { key: '07', value: '07' },
  { key: '08', value: '08' },
  { key: '09', value: '09' },
  { key: '10', value: '10' },
  { key: '11', value: '11' },
  { key: '12', value: '12' },
]

export const DEFAULT = {
  picker: '選択',
}

export const BLANK_IMAGE = {
  text: '選択されていません',
}

export const EXCLUSION_FLAG = {
  exclude: 1,
  include: 0,
}

export const JUDGEMENT_FLAG = {
  true: '1',
  false: '0',
}

export const PAYMENT_METHOD = {
  invoice: '1',
  credit: '2',
}

export const TIME_AM = [
  { time: 0, cn: '' },
  { time: 1, cn: '' },
  { time: 2, cn: '' },
  { time: 3, cn: '' },
  { time: 4, cn: '' },
  { time: 5, cn: '' },
  { time: 6, cn: '' },
  { time: 7, cn: '' },
  { time: 8, cn: '' },
  { time: 9, cn: '' },
  { time: 10, cn: '' },
  { time: 11, cn: '' },
  { time: 12, cn: '' },
]
export const TIME_PM = [
  { time: 12, cn: '' },
  { time: 13, cn: '' },
  { time: 14, cn: '' },
  { time: 15, cn: '' },
  { time: 16, cn: '' },
  { time: 17, cn: '' },
  { time: 18, cn: '' },
  { time: 19, cn: '' },
  { time: 20, cn: '' },
  { time: 21, cn: '' },
  { time: 22, cn: '' },
  { time: 23, cn: '' },
  { time: 24, cn: '' },
]

export const ERROR_MESSAGE = {
  E0142: '追加運転者様のメールアドレスが重複しています。入力内容をご確認ください。',
  E0143: '予約者様自身が追加運転者に指定されています。入力内容をご確認ください。',
  E0144: '(.*?) 様の会員情報が見つかりません。入力内容をご確認ください。',
  E0145: '(.*?) 様の会員情報の確認が完了していないため、追加運転者に指定できません。',
  E0146: '予約開始時間が (.*?) 様のカーシェア利用開始日時前のため、追加運転者に指定できません。',
  E0147: '予約終了時間が (.*?) 様の免許証有効期限後のため、追加運転者に指定できません。',
}

export const PASSENGER_ERROR = [
  ERROR_MESSAGE.E0142,
  ERROR_MESSAGE.E0143,
  ERROR_MESSAGE.E0144,
  ERROR_MESSAGE.E0145,
  ERROR_MESSAGE.E0146,
  ERROR_MESSAGE.E0147,
]
export const USE_PURPOSE_OTHER = {
  key: 'その他',
  value: '7',
}

export const OTHER_QUESTION_KEY = '99'

export const QUESTION_INPUT_TYPE = {
  CHECK_BOX: 'checkbox',
  TEXT: 'text',
  PULL_DOWN: 'pulldown',
  RADIO: 'radio',
  TEXT_AREA: 'textarea',
}

export const OnboardUnitInstalled = {
  YES: 1, // あり
  NO: 0, // なし
}

export const EcocarType = {
  EV: 1, // EV
  PHEV: 2, // PHEV
  GAS_VEHICLE: 3, // ガソリン車
}

export const UsagePattern = {
  GENERAL: 1, // 一般カーシェア
  JOINT_USE: 2, // 共同利用
}

export const ManufacturerApiAvailable = {
  OFF: 0, // 車両メーカーAPI利用なし
  ON: 1, //  車両メーカーAPI利用あり
}

export const CALENDAR_URL = 'http://www.google.com/calendar/event?'
